import React from "react";


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import "../Layout.css";

const MAX_COMMENT_CHARS = 255;

class TaskFlag extends React.Component {

    state = {
        commentText: "",
        task: {taskID:0, name:""},
        show: false,
        isLoading: false,
        
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.task) {
            this.setState({ task: this.props.task || { taskid: 0, name: "" } });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.task !== this.props.task) {
            this.setState({ task: this.props.task || { taskid: 0, name: "" } });       
        }
    }

    flagTask() {
        if (this.state.commentText === "") {
            this.setState({ validate: true });
            this.setState({ isLoading: false });
            return;
        }
        this.setState({ isLoading: true });

        var commentType = 2;
        if (this.state.task.isFlagged) {
            commentType = 3;
        }

        const comment = { taskID: this.state.task.taskID, type: commentType, comment: this.state.commentText };

        fetch(`api/WorkTask/ToggleFlag/`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(comment) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                const task = data.find(task => task.taskID === this.state.task.taskID);
                //this.setState({ task: data.find(task=>task.taskID===this.state.task.taskID) });

                this.setState({ modalMode: "" });
                this.setState({ isLoading: false });
                this.setState({ commentText: "" });

                if (this.props.onClose) this.props.onClose(task);
            })
            .catch(error => {
                this.setState({ isLoading: false });
            })
    }
    
    render() {
        if (!this.props.task ) {
            return (<div></div>);
        } else {
            return (
                <Modal show={this.props.show} style={{ zIndex: '4077' }} centered onHide={() => { if (this.props.onCancel && !this.state.isLoading) this.props.onCancel() }}>
                    <Modal.Header closeButton><b>{this.state.task.isFlagged ? "Remove flag for" : "Flag risk for"} {this.state.task.name} </b></Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={this.state.validate}>
                            <Form.Group>
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="3"
                                    maxLength={MAX_COMMENT_CHARS}
                                    placeholder="Enter Comment"
                                    disabled={this.state.isLoading}
                                    value={this.state.commentText}
                                    onChange={e => this.setState({ commentText: e.target.value })}
                                    required
                                />
                                <Form.Text className="text-muted">
                                    Characters Left: {MAX_COMMENT_CHARS - this.state.commentText.length}
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { this.flagTask() }} disabled={this.state.isLoading}>
                            {this.state.isLoading ? <Spinner animation="border"
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true" /> : ''}
                            {' '}{this.state.task.isFlagged ? "Remove flag" : "Set flag"}{' '}
                        </Button>{' '}
                        <Button variant="secondary" onClick={() => { if (this.props.onCancel) this.props.onCancel() }} disabled={this.state.isLoading}>Cancel</Button>
                    </Modal.Footer> 
                </Modal>
            );
        }
    }
}
export default TaskFlag; 