import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';

import Login from "./components/Login";
import PasswordReset from "./components/PasswordReset";
import Register from "./components/Register";
import PrivateRoute from "./components/PrivateRoute";

import { UserProvider } from './context/UserContext';
  
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";                 
import "primeicons/primeicons.css";    
import 'primeflex/primeflex.css'; 

import "./primereactCustom.css";
import './custom.css';

function Homepage() {
    window.location.replace('https://www.zymology.se/#worknodes');
}

export default class App extends Component {
  static displayName = App.name;

    render() {
      return (
          <React.Fragment>
            <UserProvider>
                  <Routes>
                      <Route path="/" element={<Homepage />} /> 
                      <Route path="/Login" element={<Login />} /> 
                      <Route path="/Logout" element={<Login action="logout" />} />
                      <Route path="/Register/:id" element={<Register />} /> 
                      <Route path="/PasswordReset/:id?" element={<PasswordReset />} />
                      <Route
                          path="*"
                          element={
                              <PrivateRoute>
                                  <Layout />
                              </PrivateRoute>
                          }
                      />
                </Routes>
            </UserProvider>
        </React.Fragment>

       
    );
  }
}
