import React from "react";

import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Spinner from 'react-bootstrap/Spinner';

class UserSettings extends React.Component {

    state = {
        show: false,
        loading: false,
        mailDeadline: false,
        mailNewTasks: false,
        mailOwnerBrief: false,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({ show: this.props.show });
            if (this.props.show === true) {
                this.loadSettings();
            }
        }
    }


    loadSettings() {
        this.setState({ loading: true });
        fetch(`api/UserSettings`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ mailDeadline: data.mailDeadline });
                this.setState({ mailNewTasks: data.mailNewTasks });
                this.setState({ mailOwnerBrief: data.mailOwnerBrief });
                this.setState({ loading: false });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            })
    }

    saveSettings() {
        this.setState({ loading: true });
        fetch(`api/UserSettings`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ mailDeadline: this.state.mailDeadline, mailNewTasks: this.state.mailNewTasks, mailOwnerBrief: this.state.mailOwnerBrief }) })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ loading: false });
                    if (this.props.handleClose) {
                        this.props.handleClose();
                    }
                } else {
                    this.setState({ loading: false });
                    if (this.props.handleClose) {
                        this.props.handleClose();
                    }
                }
            })
    }

    render() {
        return (
             <Offcanvas show={this.state.show} placement='end' onHide={() => this.saveSettings()} backdrop="static">
                <Offcanvas.Header closeButton={ !this.state.loading }>
                    <Offcanvas.Title>UserSettings {this.state.loading ? <Spinner animation="border" size="sm"/> : null}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Accordion defaultActiveKey={['0']} alwaysOpen disabled={this.state.loading}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Email notification settings</Accordion.Header>
                            <Accordion.Body>
                                <Form.Check
                                    type='checkbox'
                                    label='Recieve emails when I have new tasks available'
                                    disabled={this.state.loading}
                                    checked={this.state.mailNewTasks}
                                    onChange={(e) => this.setState({ mailNewTasks: e.target.checked})}
                                />
                                <Form.Check
                                    type='checkbox'
                                    label='Recieve emails when my tasks have missed a deadline'
                                    disabled={this.state.loading}
                                    checked={this.state.mailDeadline}
                                    onChange={(e) => this.setState({ mailDeadline: e.target.checked })}
                                />
                                <Form.Check
                                    type='checkbox'
                                    label='Recieve emails with status updates for all tasks in workflows I own'
                                    disabled={this.state.loading}
                                    checked={this.state.mailOwnerBrief}
                                    onChange={(e) => this.setState({ mailOwnerBrief: e.target.checked })}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Offcanvas.Body>
            </Offcanvas>

        );
    }
}


export default (UserSettings);