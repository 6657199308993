import React from "react";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import update from 'immutability-helper';

import "../Layout.css";

const MAX_COMMENT_CHARS = 255;

class TaskComment extends React.Component {

    state = {
        commentText: "",
        task: { taskID: 0, name: "", comments:[] },
        show: false,
        isLoading: false,
        validate: false,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.task) {
            this.setState({ task: this.props.task || { taskid: 0, name: "" } });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.task !== this.props.task) {
            this.setState({ task: this.props.task || { taskid: 0, name: "" } });
        }
    }

    addComment() {
        if (this.state.commentText === "") {
            this.setState({ validate: true });
            this.setState({ isLoading: false });
            return;
        }
        this.setState({ isLoading: true });

        const comment = { taskID: this.state.task.taskID, type: 1, comment: this.state.commentText };
       
        fetch(`api/WorkTask/Comments/`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(comment) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                //const newTask = update(this.state.task,  { comments: { $set: data } } );
               
                //this.setState({ task: newTask });

                this.setState({ validate: false });
                this.setState({ commentText: "" });
                this.setState({ isLoading: false });

                if (this.props.onClose) this.props.onClose({ taskID: this.state.task.taskID, comments: data });
            })
            .catch(error => {
                this.setState({ isModalLoading: false });
            })
    }

    render() {
        if (!this.props.task) {
            return (<div></div>);
        } else {
            return (
                <Modal scrollable={true} show={this.props.show} style={{ zIndex: '4077' }} size="lg" centered onHide={() => { if (this.props.onCancel && !this.props.isLoading) this.props.onCancel() }}>
                    <Modal.Header closeButton><b>Add comment to {this.props.task.name}</b></Modal.Header>
                    <Modal.Body>
                        { this.state.task.comments.map(value => {

                            return (
                                <Card bg='light' className='mb-3' key={'cmt' + value.commentID}>
                                    <Card.Header>{value.poster} - {(new Date(value.time)).toLocaleString('sv-SE')}</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            {value.comment}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='commentFooter'>
                            <Form noValidate validated={this.state.validate}>
                                <Form.Group>
                                    <Form.Control
                                        as="textarea"
                                        rows="2"
                                        maxLength={MAX_COMMENT_CHARS}
                                        placeholder="Enter Comment"
                                        disabled={this.props.isLoading}
                                        value={this.state.commentText}
                                        onChange={e => this.setState({ commentText: e.target.value })}
                                        required
                                    />
                                    <Form.Text className="text-muted">
                                        Characters Left: {MAX_COMMENT_CHARS - this.state.commentText.length}
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                            <div style={{ float: 'right' }}>
                                <Button variant="primary" onClick={() => { this.addComment() }} disabled={this.props.isLoading}>
                                    {this.props.isLoading ? <Spinner animation="border"
                                        as="span"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" /> : ''}
                                    {' '}Add comment{' '}
                                </Button>{' '}
                                <Button variant="secondary" onClick={() => { if (this.props.onCancel && !this.props.isLoading) this.props.onCancel() }} disabled={this.props.isLoading}>Close</Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal> 
            );
        }
    }
}
export default TaskComment; 