import React from "react";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';


import "../Layout.css";

const MAX_COMMENT_CHARS = 255;

class GanttComment extends React.Component {

    state = {
        commentText: "",
        task: { taskID: 0, name: "", comments: [] },
        show: false,
        isLoading: false,
        validate: false,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.task) {
            this.setState({ task: this.props.task || { taskid: 0, name: "" } });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.task !== this.props.task) {
            this.setState({ task: this.props.task || { taskid: 0, name: "" } });
        }
    }

    addComment() {
        if (this.state.commentText === "") {
            this.setState({ validate: true });
            this.setState({ isLoading: false });
            return;
        }
        this.setState({ isLoading: true });

        const comment = { taskID: this.state.task.taskID, type: 1, comment: this.state.commentText };

        fetch(`api/WorkTask/Comments/`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(comment) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                //const newTask = update(this.state.task,  { comments: { $set: data } } );

                //this.setState({ task: newTask });

                this.setState({ validate: false });
                this.setState({ commentText: "" });
                this.setState({ isLoading: false });
                if (this.props.onAddComment) this.props.onAddComment({ taskID: this.state.task.taskID, comments: data });
            })
            .catch(error => {
                this.setState({ isModalLoading: false });
            })
    }

    render() {
        if (!this.props.task) {
            return (<div></div>);
        } else {
            return (
                <div style={{ paddingTop:'0.5rem'} }>   
                    {this.state.task.comments.map(value => {
                        return (
                            <Card bg='light' className='mb-2' key={'cmt' + value.commentID}>
                                <Card.Header>{value.poster} - {(new Date(value.time)).toLocaleString('sv-SE')}</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {value.comment}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )
                    })}

        
                        <Form noValidate validated={this.state.validate}>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    rows="2"
                                    maxLength={MAX_COMMENT_CHARS}
                                    placeholder="Enter Comment"
                                    disabled={this.props.isLoading}
                                    value={this.state.commentText}
                                    onChange={e => this.setState({ commentText: e.target.value })}
                                    required
                                />
                                <Form.Text className="text-muted">
                                    Characters Left: {MAX_COMMENT_CHARS - this.state.commentText.length}
                                </Form.Text>
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={() => { this.addComment() }} disabled={this.props.isLoading}>
                                    {this.props.isLoading ? <Spinner animation="border"
                                        as="span"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" /> : ''}
                                    {' '}Add comment{' '}
                                </Button>
                            </div>
                        </Form>
 
                </div>
            );
        }
    }
}
export default GanttComment; 