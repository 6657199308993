import React, { Component } from 'react';
import { Navigate, Route, Routes, Link } from 'react-router-dom';
import { ProSidebarProvider, Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { WhiteLogoLarge, WhiteLogoSmall } from "./components/Logo";

import { withRouter } from "./components/react/WithRouter";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { Editor } from 'primereact/editor';

import SideMenu from "./components/SideMenu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faAnglesLeft, faBuilding, faBell, faUser} from "@fortawesome/free-solid-svg-icons";

import UserContext from './context/UserContext';

import FlowTemplateList from "./components/FlowTemplateList";
import FlowTemplateView from "./components/FlowTemplateView";
import WorkFlowList from "./components/WorkFlowList";
import TaskList from "./components/TaskList";
import WorkFlowGantt from "./components/WorkFlowGantt";
import OrganisationRoles from "./components/OrganisationRoles"
import OrganisationUsers from "./components/OrganisationUsers"
import InternalControl from "./components/InternalControl"
//import FlowCalendar from "./FlowCalendar";
//import Dashboard from "./Dashboard";
import Risk from "./components/Risk";
import PersonalGantt from "./components/PersonalGantt";

import UserSettings from "./components/UserSettings";

import "./Layout.css";

const menuItems = [
    { id: 1, text: 'Dashboard', icon: 5, link: '/Dashboard' },
    { id: 2, text: 'My WorkTasks', icon: 2, link: '/TaskList' },
    { id: 3, text: 'Active WorkFlows', icon: 0, link: '/WorkFlowList' },
    { id: 4, text: 'WorkFlow Calendar', icon: 4, link: '/FlowCalendar' },
    { id: 5, text: 'FlowTemplates', icon: 1, link: '/FlowTemplateList' },
    { id: 7, text: 'Risk', icon: 7, link: '/Risk' },
    { id: 6, text: 'IC Test', icon: 6, link: '/InternalControl' },
];

class Layout extends Component {
    static contextType = UserContext;

    state = {
        menuCollapse: false,
        modal: '',
        menu: [],
        versionRead: false,
    };

    constructor(props) {
        super(props);

        //this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.setHeartbeat();
    }

    componentDidMount() {
        this.previousContext = this.context;

        this.setUpMenu();

        const menuCollapse = localStorage.getItem('menuCollapse');

        if (menuCollapse) {
            this.setState({ menuCollapse: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.previousContext.user.organisation !== this.context.user.organisation) {
            this.props.navigate("/TaskList");
        }
        if (this.previousContext.user.permissions !== this.context.user.permissions) {
            this.setUpMenu();
        }

        this.previousContext = this.context;
    }

    setUpMenu() {
        const permissions = this.context.user.permissions;
        const personalMenu = menuItems.filter(item => permissions.some(perm => perm.functionID === item.id));
        this.setState({ menu: personalMenu });
    }

    setHeartbeat() {
        setTimeout(this.heartbeat.bind(this), 10 * 60 * 1000); // every 10 min (session timeout is default 20min)
    }

    async heartbeat() {
        let response = await fetch(`api/HeartBeat`, { method: 'GET' });
        if (response.ok) {
            console.log("hearbeat ok");
            this.setHeartbeat();
        } else {
            console.log("hearbeat not ok");
            this.props.navigate("/Logout");
        }
    }

    menuIconClick() {
        //condition checking to change state from true to false and vice versa
        const menuCollapse = this.state.menuCollapse;
        menuCollapse ? this.setState({ menuCollapse: false }) : this.setState({ menuCollapse: true });

        if (!menuCollapse) {
            localStorage.setItem('menuCollapse', 'true');
        } else {
            localStorage.removeItem('menuCollapse');
        }
    };

    closeUSerSettings() {
        this.setState({ modal: '' });
    }

    organisationList() {
        const user = this.context.user;
        return (
            <NavDropdown title={user.organisation} size="lg" id="nav-dropdown-organisation">
                {user.orgList.map((item, index) => {
                    return <NavDropdown.Item onClick={() => this.context.changeOrg(item.orgID)} key={index} disabled={this.context.user.orgID === item.orgID ? true : false}>{item.name}</NavDropdown.Item>
                })}
            </NavDropdown>
        );
    }
    
    topBar() {
        const user = this.context.user;

        const messagePopover = (
            <Popover id="popover-company">
                <Popover.Body>
                    No new messages
                </Popover.Body>
            </Popover>
        );

        return (
            <Navbar className="shadow-sm topBar" bg="light" expand="lg">
                <Navbar.Brand>
                    {this.state.menuCollapse ? (
                        <FontAwesomeIcon icon={faAnglesRight} size="sm" className="sideBarExpander" onClick={this.menuIconClick.bind(this)} />
                    ) : (
                        <FontAwesomeIcon icon={faAnglesLeft} size="sm" className="sideBarExpander" onClick={this.menuIconClick.bind(this)} />
                    )}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="mx-auto">
                        {user.orgList.length <= 1 ?
                            user.organisation :
                            this.organisationList()
                        }
                    </Nav>

                    <Nav className="ms-auto">
                        {user.isAdmin ?
                            <NavDropdown className="dropdown-icon" title={<FontAwesomeIcon icon={faBuilding} size="lg" />} id="nav-dropdown-orgInfo">
                                <NavDropdown.Header><b>{user.organisation}</b></NavDropdown.Header>
                                <NavDropdown.Divider />
                                <NavDropdown.Item>Profile</NavDropdown.Item>
                                <NavDropdown.Item>Settings</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/OrganisationUsers">Users</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/OrganisationRoles">Roles</NavDropdown.Item>
                            </NavDropdown>
                            : ''
                        }
                        <OverlayTrigger trigger="click" rootClose placement="right" overlay={messagePopover} placement="bottom">
                            <Nav.Link ><FontAwesomeIcon icon={faBell} size="lg" /></Nav.Link>
                        </OverlayTrigger>

                        <NavDropdown className="dropdown-icon" title={<FontAwesomeIcon icon={faUser} size="lg" />} id="nav-dropdown-user">
                            <NavDropdown.Header><b>{user.fullName}</b></NavDropdown.Header>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>Profile</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => this.setState({ modal: 'UserSettings' })}>Settings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/Logout">Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        );
    }

    render() {
        const user = this.context.user;
        return (
            <ProSidebarProvider >
                <div className="Layout">
                    <Modal
                        scrollable={true}
                        size="lg"
                        show={user.versionDescription.length > 0 && !this.state.versionRead}
                        onHide={() => this.setState({ versionRead: true })}
                    >
                        <Modal.Header closeButton>
                        <Modal.Title> 
                            New version
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div
                                dangerouslySetInnerHTML={{ __html: user.versionDescription }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.setState({ versionRead: true })}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <SideMenu menuCollapse={this.state.menuCollapse} menuItems={this.state.menu} />
                    {/*this.sideMenu()*/}
                    <UserSettings show={this.state.modal === 'UserSettings'} handleClose={() => this.closeUSerSettings()} />
                    <main className="main">
                        {this.topBar()}
                        
                        
                        <div className="content">
                            <Routes>
                                <Route path="/OrganisationRoles" element={<OrganisationRoles />} />
                                <Route path="/OrganisationUsers" element={<OrganisationUsers />} />
                                <Route path="/TaskList" element={<TaskList />} /> 
                                <Route path="/WorkFlowView/:flowID" element={<WorkFlowGantt />} />
                                <Route path="/WorkFlowList" element={<WorkFlowList />} />
                                <Route path="/*" element={<Navigate to="/Logout" />} />
                                <Route path="/FlowTemplateView/:flowTemplateID" element={<FlowTemplateView />} />
                                <Route path="/FlowTemplateList" element={<FlowTemplateList />} />
                                <Route path="/InternalControl" element={<InternalControl />} />  
                                <Route path="/Risk" element={<Risk />} />
                                <Route path="/PersonalGantt" element={<PersonalGantt />} />  
                                {/*
                               
                                <Route exact path="/FlowCalendar">
                                <FlowCalendar />
                            </Route>
                            <Route exact path="/Dashboard">
                                <Dashboard />
                            </Route>
                           
                                */}
                            </Routes>
                        </div> 
                    </main>
                </div>
            </ProSidebarProvider >
        );
    }
}
export default withRouter(Layout);