import React from "react";
import DataTable from 'react-data-table-component';
import update from 'immutability-helper';

import Form from "react-bootstrap/Form";
import FormControl from 'react-bootstrap/FormControl';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Spinner from 'react-bootstrap/Spinner';

import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button as PrimeButton } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect'; 

import { faEllipsisVertical, faPencil, faEnvelope, faPlusSquare, faCircleXmark, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typeahead, Highlighter, Token } from 'react-bootstrap-typeahead';

import UserContext from '../context/UserContext';

import "../Layout.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';


class OrganisationUsers extends React.Component {

	static contextType = UserContext;
	state = {
		selectedUser: { userID: 0, email: '', fullName: '', admin: false, status: '', roles: []},
		users:[],
		filterText: '',
		isLoadingData: false,
		modalWindow: '',
		newUserName: '',
		newUserNameIsValid: true,
		newUserEmail: '',
		newUserEmailIsValid: true,
	};

	componentDidMount() {
		this.loadAllUsers();
		this.loadRoles();
    }

	loadAllUsers() {
		fetch('api/User/GetOrgUsers/', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ users: data });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}


	fetchRegister(newUserEmail, newUserName ) {
		//const { newUserEmail, newUserName } = this.state;
		this.setState({ isLoading: true });
		fetch(`api/Register/Invite`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ userName:newUserEmail, fullName:newUserName }) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ isLoading: false });
				if (data.isSuccess) {
					this.setState({ modalWindow: 'confirmInvite' });
					this.setState({ newUserName: '' });
					this.setState({ newUserNameIsValid: true });
					this.setState({ newUserEmail: '' });
					this.setState({ newUserEmailIsValid: true });
					this.loadAllUsers()			
				} else {
					console.log(data.responseText);
				}
			})
			.catch(error => {
				this.setState({ message: error });
			})
	}


	loadSelectedUser(userID) {
		fetch('api/User/GetOrgUserDetails/' + userID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ selectedUser: data });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}

	loadRoles() {
		fetch('api/UserRoles/GetListofRoles', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ roles: data });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}

	saveUser() {
		fetch('api/User/SaveUser', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.selectedUser) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.loadAllUsers();
				this.setState({ modalWindow: '' });
				this.setState({ isModalLoading: false });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}


	deleteUser(userID) {
		fetch('api/User/RemoveUser/' + userID, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.loadAllUsers();
				this.setState({ modalWindow: '' });
				this.setState({ isModalLoading: false });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}


	emailValidation() {
		const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!this.state.email || regex.test(this.state.email) === false) {
			this.setState({
				error: "Email is not valid"
			});
			return false;
		}
		return true;
	}

	HandleEditUser(id) {
		this.setState({ selectedRoleID: id });
		this.setState({ modalWindow: 'edit' });
		this.loadSelectedUser(id);
	}

	HandleNewNameChange(e) {
		this.setState({ newUserName: e.target.value });
	}

	HandleNewEmailChange(e) {
		this.setState({ newUserEmail: e.target.value });
    }

	HandleCancelNewUser() {
		this.setState({ newUserName: '' });
		this.setState({ newUserEmail: '' });
		this.setState({ newUserNameIsValid: true });
		this.setState({ newUserEmailIsValid: true });
		this.setState({ modalWindow: '' })
    }

	handleAssignmentChange(e) {
		const selectedUser = update(this.state.selectedUser, { roles: { $set: e } });
		this.setState({ selectedUser: selectedUser });
		
	}

	handleAdminChange(e) {
		const selectedUser = update(this.state.selectedUser, { admin: { $set: e.target.checked } });
		this.setState({ selectedUser: selectedUser });
	}


	handleRoleChange(e) {
		const roles = this.state.selectedUser.roles.map(role => { role.isAssigned = e.value.some(assigned => assigned.roleID === role.roleID); return role; });
		const selectedUser = update(this.state.selectedUser, { roles: { $set: roles } });
		this.setState({ selectedUser: selectedUser });
	}

	handleFunctionChange(e) {
		const permissions = this.state.selectedUser.permissions.map(func => { func.hasAccess = e.value.some(perm => perm.functionID === func.functionID); return func; });
		const selectedUser = update(this.state.selectedUser, { permissions: { $set: permissions } });
		this.setState({ selectedUser: selectedUser });
	}

	modalEdit() {
		const user = this.context.user;
		var selectedPermissions, selectedRoles;
		if (this.state.selectedUser.permissions) {
			selectedPermissions = this.state.selectedUser.permissions.filter(perm => perm.hasAccess);
		} 
		if (this.state.selectedUser.roles) {
			selectedRoles = this.state.selectedUser.roles.filter(perm => perm.isAssigned);
		} 

		const canRemoveUser = !this.state.selectedUser.roles.some(role => role.isAssigned) && user.userID != this.state.selectedUser.userID;

		const footerContent = (
			<div>
				<Button className='mr-auto' variant="secondary" onClick={() => { this.setState({ isModalLoading: true }); this.deleteUser(this.state.selectedUser.userID); }} disabled={this.state.isModalLoading || !canRemoveUser}>
					{this.state.isModalLoading ? <Spinner animation="border"
						as="span"
						size="sm"
						role="status"
						aria-hidden="true" /> : ''}
					{' '}Remove{' '}
				</Button>{ ' '}
				<Button variant="primary" onClick={() => { this.setState({ isModalLoading: true }); this.saveUser(); }} disabled={this.state.isModalLoading}>
					{this.state.isModalLoading ? <Spinner animation="border"
						as="span"
						size="sm"
						role="status"
						aria-hidden="true" /> : ''}
					{' '}Save{' '}
				</Button>{ ' ' }
				<Button variant="secondary" onClick={() => { this.setState({ modalWindow: '' }) }} disabled={this.state.isModalLoading}>Cancel</Button>
			</div>
		);

		return (
			<Dialog header="Edit User" position="top"  visible={this.state.modalWindow === 'edit'} style={{ width: '40rem' }} onHide={() => this.setState({ modalWindow: '' })} closable={false} footer={footerContent}>
				<div className="p-fluid">
					<div className="p-field">
						<label className="p-field-label">Name</label>
						<InputText value={this.state.selectedUser.fullName} disabled />
					</div>					

					<div className="p-field">
						<label className="p-field-label">Email</label>
						<InputText value={this.state.selectedUser.email} disabled />
					</div>					
					
					<div className="p-field">
						<label className="p-field-label">Role assignments</label>
						<MultiSelect value={selectedRoles} options={this.state.selectedUser.roles} optionLabel="name"
							placeholder="Choose a role..." className="w-full md:w-20rem" display="chip" onChange={(e) => this.handleRoleChange(e)} disabled={this.state.isModalLoading}/>
					</div>

					<div className="p-field">
						<label className="p-field-label">Functions</label>
						<MultiSelect value={selectedPermissions} options={this.state.selectedUser.permissions} optionLabel="functionName"
							placeholder="Select functions..." className="w-full md:w-20rem" display="chip" onChange={(e) => this.handleFunctionChange(e)} disabled={this.state.isModalLoading}/>
					</div>

					<div className="flex align-items-center">
						<Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={this.handleAdminChange.bind(this)} checked={this.state.selectedUser.admin} disabled={user.userID === this.state.selectedUser.userID ? true : false} />
						<label htmlFor="ingredient1" className="ml-2"> Admin</label>
					</div>

				</div>
			</Dialog>
		);

	}


	modalInvite() {
		const footerContent = (
			<div>
				<Button variant="primary" onClick={() => { this.setState({ isLoading: true }); this.fetchRegister(this.state.newUserEmail, this.state.newUserName); }} disabled={this.state.isModalLoading}>
						{this.state.isModalLoading ? <Spinner animation="border"
							as="span"
							size="sm"
							role="status"
							aria-hidden="true" /> : ''}
						{' '}Invite{' '}
					</Button>{ ' '}
				<Button variant="secondary" onClick={() => { this.HandleCancelNewUser() }} disabled={this.state.isModalLoading}>Cancel</Button>
			</div>
		);
		return (
			<Dialog header="Invite new user" position="top" visible={this.state.modalWindow === 'invite'} style={{ width: '50vw' }} onHide={() => this.setState({ modalWindow: '' })} closable={false} footer={footerContent}>
				<p>An invitation email will be sent to the specified address. The user must press the confirmation link in the email within 24 hours to confirm the invitation.</p>
				<div className="p-fluid">
					<div className="p-field">
						<label className="p-field-label">Full name</label>
						<InputText value={this.state.newUserName} onChange={this.HandleNewNameChange.bind(this)} maxLength="150" placeholder="My Name" disabled={this.state.isModalLoading} />
					</div>
					<div className="p-field">
						<label className="p-field-label">Email</label>
						<InputText value={this.state.newUserEmail} onChange={this.HandleNewEmailChange.bind(this)} maxLength="150" placeholder="my_email@email.com" disabled={this.state.isModalLoading}/>
					</div>	
				</div>
			</Dialog>
		);
	}


	modalConfirmInvite() {
		const footerContent = (
			<div>
				<Button variant="primary" onClick={() => { this.setState({ modalWindow: '' }) }} > OK </Button>
			</div>
		);

		return (
			<Dialog header="Invite new user" position="top" visible={this.state.modalWindow === 'confirmInvite'} style={{ width: '50vw' }} onHide={() => this.setState({ modalWindow: '' })} closable={false} footer={footerContent}>
				<p>An invitation email has be sent. The user must press the confirmation link in the email within 24 hours to confirm the invitation.</p>
			</Dialog>
		);
	}

	onFilter(e) {
		this.setState({ filterText: e.target.value });
	}

	menuPopup(row) {
		return (
			<Popover id="popover-basic" className="popup-menu shadow ">
				<Popover.Body>
					<ListGroup>
						<ListGroup.Item onClick={() => { document.body.click(); this.HandleEditUser(row.userID); }} >
							<FontAwesomeIcon icon={faPencil} size="sm" /> Edit
						</ListGroup.Item>
						{row.status === 'invited' ? <ListGroup.Item onClick={() => { document.body.click(); this.fetchRegister(row.email, row.fullName); }} >
							<FontAwesomeIcon icon={faEnvelope} size="sm" /> Reinvite
						</ListGroup.Item>: null}
					</ListGroup>
				</Popover.Body>
			</Popover>
		);
	}

	customLoader() {
		return (
			<div style={{ padding: '24px' }}>
				<Spinner animation="border" />
				<div>Loading...</div>
			</div>
		);
	}

	render() {
		const filteredData = this.state.users.filter(
			item => item.fullName && item.fullName.toLowerCase().includes(this.state.filterText.toLowerCase()),
		);

		const customStyles = {
			headRow: {
				style: {
					border: 'none',
				},
			},
			headCells: {
				style: {
					color: '#202124',
					fontSize: '14px',
				},
			},
			rows: {
				highlightOnHoverStyle: {
					backgroundColor: 'AliceBlue',
					borderBottomColor: '#FFFFFF',
					borderRadius: '25px',
					outline: '1px solid #FFFFFF',
				},
			},
			pagination: {
				style: {
					border: 'none',
				},
			},
		};

		const columns = [
			{
				cell: () => <FontAwesomeIcon icon={faUser} />,
				width: '1.5rem', // custom width for icon button
				style: {
					borderBottom: '1px solid #FFFFFF',
					marginBottom: '-1px',
				},
			},
			{
				name: 'Name',
				selector: row => row.fullName,
				sortable: true,
				grow: 2,
				style: {
					color: '#202124',
					fontSize: '14px',
					fontWeight: 500,
				},
			},
			{
				name: 'Status',
				selector: (row) => row.status,
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				cell: row => <OverlayTrigger trigger="click" placement="right" rootClose placement='left' overlay={this.menuPopup(row)}>
					<Button variant="light" className="btn-circle"><FontAwesomeIcon icon={faEllipsisVertical} /></Button>
				</OverlayTrigger>,
				allowOverflow: true,
				button: true,
				width: '3rem',
			},
		];

        return (
			<Card className="workspace-card">
				{this.modalEdit()}
				{this.modalInvite()}
				{this.modalConfirmInvite()}
				{/*this.state.modalWindow === 'edit' ? this.modalEdit() : this.state.modalWindow === 'invite' ? this.modalInvite() : this.state.modalWindow === 'confirmInvite' ? this.modalConfirmInvite():null*/}
				<Card.Header>
					<div style={{ float: 'left' }}>
						<Row>
							<Col md="auto">
								<b>Users</b>
							</Col>
							<Col md="auto">
								<InputGroup>
									<Form.Control
										style={{ minWidth: '20rem' }}
										placeholder="Filter by name"
										size="sm"
										type="text"
										value={this.state.filterText}
										onChange={this.onFilter.bind(this)}
									/>
									<Button size='sm' onClick={() => { this.setState({ filterText: "" }) }} variant="secondary"><FontAwesomeIcon icon={faCircleXmark} /></Button>
								</InputGroup>
							</Col>
						</Row>
					</div>
					<div style={{ float: 'right' }}>
						<Row>
							<Col md="auto">
								<Button size='sm' onClick={() => this.setState({ modalWindow: 'invite' }) } id={"btn_add"} variant="primary"><FontAwesomeIcon icon={faPlusSquare} /> Invite new user </Button>
							</Col>
						</Row>


					</div>
				</Card.Header>
				<Card.Body>
					<DataTable
						className="data-table"
						columns={columns}
						data={filteredData}
						customStyles={customStyles}
						highlightOnHover
						pointerOnHover
						noHeader={true}
						progressPending={this.state.isLoadingData}
						progressComponent={this.customLoader()}
						onRowClicked={(row, event) => this.HandleEditUser(row.userID)}
					/>
				</Card.Body>
			</Card >
        );
    }
}

export default (OrganisationUsers);