import * as React from 'react';

import { withRouter } from "./react/WithRouter";

import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';

import { faPencilAlt, faPaperPlane, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

import GanttProperties from './GanttProperties';
import CloseTaskDialog from './CloseTaskDialog'

import "./Gantt.css";

import { GojsIcons } from './GojsIcons';

const progressIcons = ["progressUnefined", "progressEmpty", "progressQuarter", "progressHalf", "progressThreeQuarter", "progressFull"]
const nodeIcons = ["user", "envelope", "calendar", "file", "bookReader", "glasses", "hourglass", "clock", "tasks"];


class WorkFlowGantt extends React.Component {

    state = {
        modelData: {},
        activeNode: { nodeID: 0, nodeName: '', nodeDescr: '', responsibleName: '' },
        dates: { startDate: "2020-01-01T00:00:00", endDate: "2020-01-01T00:00:00" },
        isEditing: false,
        isEditable: false,
        editModal: false,
        modified: false,
        saving: false,
        layoutComplete: false,
        loaded: false,
        datesLoaded: false,
        scale: 30,
    };


    constructor(props) {
        super(props);
        this.ganttDiagramRef = React.createRef();
        this.rowHeadersRef = React.createRef();
        this.columnHeadersRef = React.createRef();

        this.todayMarker = null;
        this.dateLines = [];
        this.blockedDays = [];
        this.gradScaleMonth = null;
        this.gradScaleDate = null;
        this.startDate = Date.parse("2020-01-01T00:00:00");
        this.endDate = Date.parse("2020-01-01T00:00:00");
        this.blockedDays = [];
        this.cellHeight = 30;
        this.cellMargin = 2;
        this.dayLength = 30;
    }

    componentDidMount() {
        this.fetchFlowData();
    }

    fetchDates(fromDate, toDate) {
        var start = this.addDays(fromDate, -60).toISOString().substring(0, 10);
        var end = this.addDays(toDate, 60).toISOString().substring(0, 10);


        fetch('api/Calendar/BlockedDates?from=' + start + '&to=' + end, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.blockedDays = data;
                this.addBlockedDay();
                this.setState({ datesLoaded: true });
            })
            .catch(error => {
                console.log(error);
                //Gör något om fel
            })
    }

    fetchFlowData() {
        if (!this.ganttDiagramRef.current) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();

        if (!this.rowHeadersRef.current) return;
        const rowDiagram = this.rowHeadersRef.current.getDiagram();

        const flowID = this.props.params.flowID;
       

        fetch(`api/WorkFlow/GetWorkFlow/` + flowID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ modelData: data.modelData });

                data.class = "GraphLinksModel";
                data.nodeKeyProperty = "nodeID";
                data.linkKeyProperty = "linkID";
                data.linkFromPortIdProperty = "fromPort";
                data.linkToPortIdProperty = "toPort";

                diagram.model = go.Model.fromJSON(data);
                rowDiagram.model = go.Model.fromJSON(data);

                this.setDates(data.modelData.startDate, data.modelData.deadline);

                //diagram.isModified = false;
                this.setState({ modified: false });
                this.setState({ loaded: true });

                if (data.modelData.editorID > 0 && data.modelData.editable)
                    this.setEditing();
            })
            .catch(error => {
                console.log(error);
                //Gör något om fel
            })
    }

    fetchStartEdit() {
        fetch(`api/WorkFlow/EditWorkFlow/` + this.props.params.flowID, { method: 'PUT', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                if (data > 0)
                    this.setEditing();
            })
            .catch(error => {
                //this.setState({ message: error });
            })
    }

    fetchPublish(flowID) {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        };
        fetch(`api/WorkFlow/PublishWorkFlow/` + this.props.params.flowID, { method: 'PUT', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                if (data > 0)
                    this.setPublish();
            })
            .catch(error => {
                //this.setState({ message: error });
            })
    }

    fetchUpdate() {
        if (!this.ganttDiagramRef.current) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();

        if (!this.rowHeadersRef.current) return;
        const rowDiagram = this.rowHeadersRef.current.getDiagram();

        const flowID = this.props.params.flowID;


        fetch(`api/WorkFlow/GetWorkFlow/` + flowID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }

                diagram.model.commit(m => {
                    m.mergeNodeDataArray(data.nodeDataArray);
                }, null);

                rowDiagram.model.commit(m => {
                    m.mergeNodeDataArray(data.nodeDataArray);
                }, null);

                rowDiagram.rebuildParts()
                

                this.setState({ modelData: data.modelData });

                //if (data.modelData.editorID > 0 && data.modelData.editable)
                //    this.setEditing();
            })
            .catch(error => {
                console.log(error);
                //Gör något om fel
            })
    }

    saveGantt() {
        if (!this.ganttDiagramRef.current) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();

        this.setState({ saving: true });

        fetch(`api/WorkFlow/SaveGantt`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: diagram.model.toJson() })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                diagram.isModified = false;
                this.setState({ saving: false });
                this.setState({ modified: false });
            })
            .catch(error => {
                alert("Failed to save. Try again or contact support")
                this.setState({ Saving: false });
            })
    }

    manualSave() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
        this.saveGantt();
    }

    autoSave() {
        if (this.state.layoutComplete && this.state.isEditing) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId)
            };
            this.timeoutId = setTimeout(() => {
                this.saveGantt();
            }, 3000);
        }
    }

    setEditing() {
        if (this.ganttDiagramRef.current) {
            const diagram = this.ganttDiagramRef.current.getDiagram();

            diagram.clearSelection();
            diagram.isReadOnly = false;

            this.setState({ isEditing: true });
            this.setState({ editModal: true });
            this.setState({ modified: false });
        }
    }

    setPublish() {
        if (this.ganttDiagramRef.current) {
            const diagram = this.ganttDiagramRef.current.getDiagram();

            diagram.clearSelection();
            diagram.isReadOnly = true;
        }

        this.setState({ modified: false });
        this.setState({ isEditing: false });

        this.setState({ datesLoaded: true });
        this.setState({ loaded: true });
        this.setState({ layoutComplete: false });

        this.fetchFlowData();
    }

    setDates(startDate, endDate) {
        if (this.ganttDiagramRef.current) {
            const diagram = this.ganttDiagramRef.current.getDiagram();
            const data = diagram.model.modelData;
            
            this.startDate = startDate;
            this.endDate = endDate;

            this.addTodayMarker();

            this.fetchDates(startDate, endDate);

            //Set initial position to render date headers correctly
            diagram.position = new go.Point(0, 0);
        }
    }

  

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    GetProgressIcon(iconNum) {
        if (iconNum >= progressIcons.length) iconNum = 0;
        var iconString = GojsIcons[progressIcons[iconNum]];
        var icon;
        if (typeof iconString === "string") {
            icon = go.Geometry.parse(iconString, true);
        }
        return icon;
    }

    GetIcon(iconNum) {
        //iconNum = 0;
        if (iconNum > nodeIcons.length - 1) iconNum = 0;
        var iconString = GojsIcons[nodeIcons[iconNum]];
        var icon;
        if (typeof iconString === "string") {
            icon = go.Geometry.parse(iconString, true);
        }
        return icon;
    }

    addBlockedDay() {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();
        const $ = go.GraphObject.make;

        this.dateLines.forEach(part => {
            diagram.remove(part);
        });

        this.dateLines = [];

        var startDate = new Date(this.startDate);

        this.blockedDays.forEach(date => {
            var blockDate = new Date(date.date);
            blockDate.setHours(0, 0, 0, 0);
            var diff = Math.round((blockDate - startDate) / (1000 * 60 * 60 * 24));
            var blockPos = diff * this.dayLength;

            var blockMarker = $(go.Part, "Horizontal", { layerName: "Background", location: new go.Point(blockPos, 0), pickable: false, name: "DATELINES" },
                $(go.Shape, "rectangle", { fill: 'gray', opacity: 0.1, strokeWidth: 0, width: this.dayLength, height: Math.max(diagram.viewportBounds.height, diagram.model.nodeDataArray.length * this.cellHeight) }),
            );

            this.dateLines.push(blockMarker)
            diagram.add(blockMarker);
        });
        diagram.scrollMode = go.Diagram.DocumentScroll;
    }

    addTodayMarker() {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();
        const $ = go.GraphObject.make;

        if (this.todayMarker != null) {
            diagram.remove(this.todayMarker);
            this.todayMarker = null;
        }

        var startDate = new Date(this.startDate);

        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var diff = Math.round((today - startDate) / (1000 * 60 * 60 * 24));
        var todayPos = diff * this.dayLength;
        this.todayMarker = $(go.Part, "Horizontal", { layerName: "Foreground", location: new go.Point(todayPos, 0), pickable: false },
            $(go.Shape, "rectangle", { fill: 'orange', opacity: 0.2, strokeWidth: 0, width: this.dayLength, height: Math.max(diagram.viewportBounds.height, diagram.model.nodeDataArray.length * this.cellHeight) })
        );

        diagram.add(this.todayMarker);
    }

    mouseEnterNode(e, obj) {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();

        if (obj instanceof go.Node) {
            diagram.links.each((link) => { link.path.strokeWidth = 0.5; var arw = link.findObject("ARROW"); arw.opacity = 0.0; });
            diagram.nodes.each((node) => { var shp = node.findObject("SHAPE"); shp.stroke = 'gray'; shp.opacity = 0.4; var txt = node.findObject("TEXT"); txt.opacity = 0.4; });

            var shp = obj.findObject("SHAPE");
            shp.stroke = 'black';
            shp.opacity = 1.0;
            shp.strokeWidth = 2;
            var txt = obj.findObject("TEXT");
            txt.opacity = 1.0;


            obj.linksConnected.each((link) => { link.path.stroke = 'black'; link.path.strokeWidth = 2; var arw = link.findObject("ARROW"); arw.opacity = 1.0; });
            obj.findNodesConnected().each((node) => { var shp = node.findObject("SHAPE"); shp.stroke = 'black'; shp.strokeWidth = 2; shp.opacity = 1.0; var txt = node.findObject("TEXT"); txt.opacity = 1.0; });
        }
    }

    mouseLeaveNode(e, obj) {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();

        diagram.links.each((link) => { link.path.strokeWidth = 1; var arw = link.findObject("ARROW"); arw.opacity = 1.0; });
        diagram.nodes.each((node) => { var shp = node.findObject("SHAPE"); shp.stroke = 'black'; shp.strokeWidth = 1; shp.opacity = 1.0; var txt = node.findObject("TEXT"); txt.opacity = 1.0; });
    }

    CalculateToPort(linkData) {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();

        var fromNode = diagram.findNodeForKey(linkData.from);
        var toNode = diagram.findNodeForKey(linkData.to);

        return toNode.data.ganttStart - (fromNode.data.ganttStart + fromNode.data.ganttEstimate) >= 0 ? "ToPort" : "zeroToPort";
    }

    CalculateFromPort(linkData) {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();

        var fromNode = diagram.findNodeForKey(linkData.from);
        var toNode = diagram.findNodeForKey(linkData.to);

        return toNode.data.ganttStart - (fromNode.data.ganttStart + fromNode.data.ganttEstimate) >= 0 ? "FromPort" : "zeroFromPort";
    }

    PartResized(e) {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();
        const model = diagram.model;
        const node = e.subject;

        var start = new Date(this.startDate);
        start.setDate(start.getDate() + node.data.ganttStart);
        model.setDataProperty(node.data, "scheduledDate", start.toLocaleString('sv').replace(' ', 'T'));

        var newDeadline = new Date(node.data.scheduledDate);
        newDeadline.setDate(newDeadline.getDate() + Math.max(node.data.ganttEstimate - 1, 0));
        model.setDataProperty(node.data, "deadline", newDeadline.toLocaleString('sv').replace(' ', 'T'));

        if (node.data.ganttEstimate === 0) {
            model.setDataProperty(node.data, "ganttEstimate", 1);
            model.setDataProperty(node.data, "nodeEstimate", 0);
            model.setDataProperty(node.data, "isZeroDayTask", true);
        } else {
            var newEstimate = node.data.ganttEstimate - this.blockedDays.reduce((accumulator, currentValue) => { var calenderDate = new Date(currentValue.date); return (calenderDate >= start && calenderDate <= newDeadline ? ++accumulator : accumulator); }, 0);
            model.setDataProperty(node.data, "nodeEstimate", newEstimate);
            model.setDataProperty(node.data, "isZeroDayTask", false);
        }

        node.findLinksOutOf().each((link) => {
            const otherNode = link.getOtherNode(node);
            if (node.data.ganttStart + node.data.ganttEstimate - 1 >= otherNode.data.ganttStart) {
                model.setDataProperty(link.data, 'toPort', 'zeroToPort');
                model.setDataProperty(link.data, 'fromPort', 'zeroFromPort');
            } else {
                model.setDataProperty(link.data, 'toPort', 'ToPort');
                model.setDataProperty(link.data, 'fromPort', 'FromPort');
            }
        });

        node.findLinksInto().each((link) => {
            const otherNode = link.getOtherNode(node);
            if (otherNode.data.ganttStart + otherNode.data.ganttEstimate - 1 >= node.data.ganttStart) {
                model.setDataProperty(link.data, 'toPort', 'zeroToPort');
                model.setDataProperty(link.data, 'fromPort', 'zeroFromPort');
            } else {
                model.setDataProperty(link.data, 'toPort', 'ToPort');
                model.setDataProperty(link.data, 'fromPort', 'FromPort');
            }
        });
    }

    SelectionMoved(e) {
        if (!this.ganttDiagramRef.current || !this.state.loaded) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();
        const model = diagram.model;
        const node = e.diagram.selection.first();

        var start = new Date(this.startDate);
        start.setDate(start.getDate() + node.data.ganttStart);
        model.setDataProperty(node.data, "scheduledDate", start.toLocaleString('sv').replace(' ', 'T'));

        var newDeadline = new Date(node.data.scheduledDate);
        newDeadline.setDate(newDeadline.getDate() + Math.max(node.data.ganttEstimate - 1, 0));
        model.setDataProperty(node.data, "deadline", newDeadline.toLocaleString('sv').replace(' ', 'T'));

        if (!node.data.isZeroDayTask) {
            var newEstimate = node.data.ganttEstimate - this.blockedDays.reduce((accumulator, currentValue) => { var calenderDate = new Date(currentValue.date); return (calenderDate >= start && calenderDate <= newDeadline ? ++accumulator : accumulator); }, 0);
            model.setDataProperty(node.data, "nodeEstimate", newEstimate);
        }

        node.findLinksOutOf().each((link) => {
            const otherNode = link.getOtherNode(node);
            if (node.data.ganttStart + node.data.ganttEstimate - 1 >= otherNode.data.ganttStart) {
                model.setDataProperty(link.data, 'toPort', 'zeroToPort');
                model.setDataProperty(link.data, 'fromPort', 'zeroFromPort');
            } else {
                model.setDataProperty(link.data, 'toPort', 'ToPort');
                model.setDataProperty(link.data, 'fromPort', 'FromPort');
            }
        });

        node.findLinksInto().each((link) => {
            const otherNode = link.getOtherNode(node);
            if (otherNode.data.ganttStart + otherNode.data.ganttEstimate - 1 >= node.data.ganttStart) {
                model.setDataProperty(link.data, 'toPort', 'zeroToPort');
                model.setDataProperty(link.data, 'fromPort', 'zeroFromPort');
            } else {
                model.setDataProperty(link.data, 'toPort', 'ToPort');
                model.setDataProperty(link.data, 'fromPort', 'FromPort');
            }
        });
    }

    nodeSelectionChanged(node) {
        if (node.isSelected) {
            this.setState({
                activeNode: {
                    nodeID: node.key,
                    nodeName: node.data.nodeName,
                    nodeDescr: node.data.nodeDescr,
                    responsibleName: node.data.responsibleName,
                }
            });
        } else {
            this.setState({
                activeNode: {
                    nodeID: 0,
                    nodeName: '',
                    nodeDescr: '',
                    responsibleName: '',
                }
            });
        }
    }

    initGanttDiagram() {
        const daySize = 30;
        const cellHeight = 30;
        const heightMargin = 5;
        var that = this;


        const $ = go.GraphObject.make;

        go.Diagram.licenseKey = "73f944e0b16331b700ca0d2b113f69ed1bb37a669e821ff55e5941a1ef0068432b99ec7903d78e92d4ff5cec1c2990d1ddcc3a79c34b076be335dadb46e58ef1b63575e214584286a70576c19dfe7df4ff7976a7cabc25f0db78dff1efa0d18c5abda3d248985eba3b680530557eb04ab6e4da78";

        const diagram = $(go.Diagram,
            {
                'undoManager.isEnabled': true,
                'undoManager.maxHistoryLength': 10,
                "animationManager.isEnabled": false,
                maxSelectionCount: 1,
                initialContentAlignment: go.Spot.TopLeft,

                isReadOnly: true,

                allowDelete: false,
                allowCopy: false,

                grid:
                    $(go.Panel, "Grid",
                        { gridCellSize: new go.Size(that.dayLength, that.cellHeight) },
                        $(go.Shape, "BarH", { fill: 'GhostWhite', interval: 2, height: this.cellHeight }),
                        $(go.Shape, "LineV", { stroke: 'Gainsboro' })
                    ),

                "draggingTool.isGridSnapEnabled": true,
                "resizingTool.isGridSnapEnabled": true,
                scrollMode: go.Diagram.InfiniteScroll,
                hasHorizontalScrollbar: false,
                hasVerticalScrollbar: false,
                //scrollMode: go.Diagram.DocumentScroll,

                positionComputation: function (diag, pos) {  // but not too far vertically, or at all if fits in viewport
                    var y = pos.y;
                    if (diag.documentBounds.height < diag.viewportBounds.height) {
                        y = 0;
                    } else {
                        y = Math.min(y, diag.documentBounds.bottom - diag.viewportBounds.height);
                        if (y < 0) y = 0;  // don't show negative Y coordinates
                    }
                    return new go.Point(pos.x, y);
                },

                model: $(go.GraphLinksModel, {
                    linkKeyProperty: 'linkID',
                    nodeKeyProperty: 'nodeID',
                    linkFromPortIdProperty: 'fromPort',
                    linkToPortIdProperty: 'toPort',
                }),
                "draggingTool.doDragOver": function (pt, obj) {
                    this.diagram.currentCursor = 'grabbing';
                },
            });

            this.NodeSimpleGantt = $(go.Node, "Auto",
            {
                isShadowed: true,
                shadowOffset: new go.Point(3, 3),
                shadowColor: "#C5C1AA",
                maxSize: new go.Size(NaN, that.cellHeight),

                mouseEnter: this.mouseEnterNode.bind(this),
                mouseLeave: this.mouseLeaveNode.bind(this),

                fromSpot: go.Spot.RightCenter,
                height: that.cellHeight,

                dragComputation: function (part, pt, gridpt) { //Restriction of vertical dragging. Code for further restrictions go here
                    return new go.Point(gridpt.x, that.cellHeight * (part.data.ganttOrder - 1));
                },

                resizable: true,
                cursor: "grab",

                resizeAdornmentTemplate:
                    $(go.Adornment, "Spot",
                        $(go.Placeholder),

                        $(go.Shape, "RoundedRectangle",
                            {
                                alignment: go.Spot.Right, cursor: "col-resize",
                                desiredSize: new go.Size(8, 20), fill: "lightblue", stroke: "dodgerblue"
                            }),

                        $(go.Shape, "RoundedRectangle",
                            {
                                alignment: go.Spot.Left, cursor: "col-resize",
                                desiredSize: new go.Size(8, 20), fill: "lightblue", stroke: "dodgerblue"
                            }),
                    ),
                toolTip:
                    $("ToolTip",
                        $(go.TextBlock, { margin: 4 },
                            new go.Binding("text", "", function (data) { return data.nodeName + "\n" + data.statusText + "\nEst: " + data.nodeEstimate + " working days"; }))
                    ),
                selectionAdornmentTemplate:
                    $(go.Adornment, "Spot",
                        $(go.Panel, "Auto",
                            $(go.Shape, "RoundedRectangle", { stroke: "RoyalBlue", strokeWidth: 2, fill: null, margin: new go.Margin(this.cellMargin + 2, 2, this.cellMargin + 2, 2) }),
                            $(go.Placeholder)
                        ),
                    ),
                selectionChanged: this.nodeSelectionChanged.bind(this),
                click: function (e, node) {
                    var diagram = node.diagram;
                    diagram.clearHighlighteds();
                    node.findLinksOutOf().each(function (l) { l.isHighlighted = true; });
                    node.findNodesOutOf().each(function (n) { n.isHighlighted = true; });
                }
            },

            new go.Binding("location", "", (data) => { return new go.Point(data.ganttStart * that.dayLength, that.cellHeight * (data.ganttOrder - 1)) })
                .makeTwoWay((pos, data) => { data.ganttStart = Math.floor(pos.x / that.dayLength); }),


            new go.Binding("width", "ganttEstimate", function (est) { return that.dayLength * est })
                .makeTwoWay((width, data) => { return Math.floor(width / that.dayLength); }),


            $(go.Panel, "Auto",
                { stretch: go.GraphObject.Fill },
                $(go.Shape,
                    "RoundedRectangle",
                    {
                        name: "SHAPE",
                        strokeWidth: 1,
                        fill: "white",
                        margin: new go.Margin(this.cellMargin, 0, this.cellMargin, 0),
                    },
                    new go.Binding("fill", "statusColor"),
                    new go.Binding("strokeDashArray", "isZeroDayTask", (val) => { return val > 0 ? [3, 3] : [] }),
                ),

                $(go.TextBlock,
                    {
                        name: "TEXT",
                        editable: false,
                        font: "bold 9pt sans-serif",
                        editable: false,
                        isMultiline: false,
                        overflow: go.TextBlock.OverflowEllipsis,
                        margin: 0,
                        wrap: go.TextBlock.None,
                        textAlign: "center",
                    },
                    new go.Binding("text", "", (data) => { return data.isZeroDayTask > 0 ? "" : data.nodeName })
                ),
            ),

            $(go.Shape,
                "Rectangle",
                {
                    alignment: go.Spot.Left,
                    margin: this.dayLength / 2,
                    height: this.cellHeight - this.cellMargin * 2,
                    width: 0,
                    strokeWidth: 0,
                    fill: "transparent",
                    portId: "ToPort",
                    toSpot: go.Spot.TopBottomSides,
                },
            ),

            $(go.Shape,
                "Rectangle",
                {
                    alignment: go.Spot.Left,
                    margin: this.dayLength / 2,
                    height: 5,
                    width: 0,
                    strokeWidth: 0,
                    fill: "transparent",
                    portId: "zeroToPort",
                    toSpot: go.Spot.TopBottomSides,
                },
            ),

            $(go.Shape,
                "Rectangle",
                {
                    alignment: go.Spot.Right,
                    margin: this.dayLength / 2 - 0.5, //Since zero width messes up alignment we add half of the width here
                    height: 5,
                    width: 1,
                    strokeWidth: 0,
                    fill: "transparent",
                    portId: "zeroFromPort",
                    toSpot: go.Spot.TopBottomSides,
                },
            ),

        );

        diagram.linkTemplate =
            $(go.Link, {
                routing: go.Link.Orthogonal,
                toShortLength: 2,
                corner: 15,
                selectable: false,
                relinkableFrom: false,
                relinkableTo: false,
                pickable: false,
            },
                $(go.Shape),
                $(go.Shape, { name: "ARROW", toArrow: "kite" }),
            );

        var templmap = new go.Map();
        templmap.add("simpleNode", this.NodeSimpleGantt);

        diagram.nodeTemplateMap = templmap;

        //diagram.addDiagramListener("ChangedSelection", (e) => { this.updateHighlights(e); });
        diagram.addDiagramListener("ViewportBoundsChanged", (e) => { this.GanttViewPortChanged(e); });
        diagram.addDiagramListener("DocumentBoundsChanged", (e) => { this.GanttDocumentBoundsChanged(e); });
        diagram.addDiagramListener("PartResized", (e) => { this.PartResized(e); });
        diagram.addDiagramListener("SelectionMoved", (e) => { this.SelectionMoved(e); });

        diagram.addDiagramListener('InitialLayoutCompleted', (e) => {
            this.setState({ layoutComplete: "true" });
            diagram.isModified = false;
            this.setState({ modified: false });
            diagram.position = new go.Point(0, 0);
        });

        diagram.addModelChangedListener((e) => { this.handleModelChange(e); });

        return diagram;
    }

    initColumnHeaders() {

        const that = this;
        const $ = go.GraphObject.make;

        go.Diagram.licenseKey = "73f944e0b16331b700ca0d2b113f69ed1bb37a669e821ff55e5941a1ef0068432b99ec7903d78e92d4ff5cec1c2990d1ddcc3a79c34b076be335dadb46e58ef1b63575e214584286a70576c19dfe7df4ff7976a7cabc25f0db78dff1efa0d18c5abda3d248985eba3b680530557eb04ab6e4da78";

        const diagram = $(go.Diagram,
            {
                initialPosition: new go.Point(0, 0),
                isReadOnly: true,
                "animationManager.isEnabled": false,
                initialContentAlignment: go.Spot.TopLeft,
                allowHorizontalScroll: false,
                allowVerticalScroll: false,
                allowZoom: false,
                padding: 0,
            }
        );

        this.gradScaleMonth =
            $(go.Part, "Graduated",
                { layerName: "Grid", position: new go.Point(0, 0), graduatedTickUnit: this.dayLength },
                $(go.Shape, { stroke: "white", geometryString: "M0 0 H" + this.dayLength }),
                $(go.Shape, {
                    stroke: "WhiteSmoke",
                    geometryString: "M0 0 V20",
                    graduatedSkip: function (v) {
                        var d = new Date(Date.parse(that.startDate));
                        d.setDate(d.getDate() + v / that.dayLength);

                        return d.getDate() == 1 ? null : true;
                    }
                }),
                $(go.TextBlock,
                    {
                        font: "10pt sans-serif",
                        stroke: "gray",
                        alignmentFocus: new go.Spot(0, 0, -4, -4),

                        graduatedSkip: function (v) {
                           
                            var d = new Date(Date.parse(that.startDate));
                            if (v == 0 && d.getDate() < 25) {
                                return null;
                            }
                            d.setDate(d.getDate() + v / that.dayLength);

                            return d.getDate() == 1 ? null : true;
                        },

                        graduatedFunction: function (v) {
                            var d = new Date(Date.parse(that.startDate));
                            d.setDate(d.getDate() + v / that.dayLength);

                            var options = { month: "short", year: "numeric" };
                            return d.toLocaleDateString("en-US", options);
                        }
                    })
            );

        this.gradScaleDate =
            $(go.Part, "Graduated",
                { layerName: "Grid", position: new go.Point(0, 20), graduatedTickUnit: this.dayLength },
                $(go.Shape, { stroke: "WhiteSmoke", geometryString: "M0 0 H" + this.dayLength }),
                $(go.Shape, {
                    stroke: "WhiteSmoke",
                    geometryString: "M0 0 V20",

                }),
                $(go.TextBlock,
                    {
                        font: "8pt sans-serif",
                        alignmentFocus: new go.Spot(0, 0, -2, 0),
                        textAlign: "center",
                        stroke: "gray",
                        segmentOffset: new go.Point(7, 7),
                        graduatedFunction: function (v) {
                            var d = new Date(Date.parse(that.startDate));
                            d.setDate(d.getDate() + v / that.dayLength);
                            return d.getDate();
                        }
                    }
                )
            );

        diagram.add(this.gradScaleMonth);
        diagram.add(this.gradScaleDate);

        return diagram;
    }

    initRowHeaders() {
        const $ = go.GraphObject.make;
        const that = this;
        go.Diagram.licenseKey = "73f944e0b16331b700ca0d2b113f69ed1bb37a669e821ff55e5941a1ef0068432b99ec7903d78e92d4ff5cec1c2990d1ddcc3a79c34b076be335dadb46e58ef1b63575e214584286a70576c19dfe7df4ff7976a7cabc25f0db78dff1efa0d18c5abda3d248985eba3b680530557eb04ab6e4da78";

        const diagram = $(go.Diagram,
            {
                isReadOnly: true,
                "animationManager.isEnabled": false,
                allowHorizontalScroll: false,
                hasVerticalScrollbar: false,
                contentAlignment: go.Spot.TopLeft,
                padding: 0,
                grid:
                    $(go.Panel, "Grid",
                        { gridCellSize: new go.Size(that.dayLength, that.cellHeight) },
                        $(go.Shape, "BarH", { fill: 'GhostWhite', interval: 2, height: this.cellHeight }),
                    ),

                nodeTemplate:
                    $(go.Part, 'Horizontal',
                        {
                            padding: new go.Margin(this.cellMargin, 0, this.cellMargin, 0),
                            height: that.cellHeight,
                            stretch: go.GraphObject.Horizontal,
                            selectionAdorned: false,
                            click: this.rowClick.bind(this),
                        },
                        new go.Binding("location", "", function (data) { return new go.Point(0, that.cellHeight * (data.ganttOrder - 1)) }),
                        $(go.Shape,
                            {
                                geometry: go.Geometry.parse(GojsIcons["progressUnefined"], true),
                                margin: 3,
                                strokeWidth: 0,
                                alignment: go.Spot.Left,
                                width: 20,
                                height: 20,
                                geometryStretch: go.GraphObject.Uniform,
                            },
                            new go.Binding("geometry", "progressStatus", this.GetProgressIcon.bind(this)),
                            new go.Binding("fill", "statusColor"),
                        ),
                        $(go.TextBlock,
                            {
                                alignment: go.Spot.Left,
                                verticalAlignment: go.Spot.Left,
                                font: "bold 9pt sans-serif",
                                editable: false,
                                isMultiline: false,
                                margin: 5,
                                stroke: "darkGray",
                                wrap: go.TextBlock.None,
                                overflow: go.TextBlock.OverflowEllipsis,
                                textAlign: "left"
                            },
                            new go.Binding("text", "nodeName").makeTwoWay(),
                        ),
                        {
                            toolTip:  // define a tooltip for each node that displays the name
                                $("ToolTip",
                                    $(go.TextBlock, { margin: 4 },
                                        new go.Binding("text", "nodeName"))
                                )
                        },

                    )
            }
        );


        diagram.addDiagramListener("ViewportBoundsChanged", (e) => { this.RowHeadersViewPortChanged(e); });

        return diagram;
    }

    rowClick(e, obj) {
        if (!this.ganttDiagramRef.current || !this.rowHeadersRef.current) return;
        const ganttDiagram = this.ganttDiagramRef.current.getDiagram();

        var node = obj.part;
        var taskID = node.data.nodeID;
        var taskNode = ganttDiagram.findNodeForKey(taskID);

        ganttDiagram.scrollToRect(taskNode.actualBounds);
        ganttDiagram.select(taskNode);
    }

    GanttDocumentBoundsChanged(e) {
        if (!this.ganttDiagramRef.current || !this.rowHeadersRef.current) return;
        const ganttDiagram = this.ganttDiagramRef.current.getDiagram();
        const rowHeaders = this.rowHeadersRef.current.getDiagram();

        rowHeaders.scale = ganttDiagram.scale;
        rowHeaders.position = new go.Point(0, ganttDiagram.position.y);
    }

    RowHeadersViewPortChanged(e) {
        if (!this.ganttDiagramRef.current) return;
        const ganttDiagram = this.ganttDiagramRef.current.getDiagram();

        ganttDiagram.scale = e.diagram.scale;
        ganttDiagram.position = new go.Point(ganttDiagram.viewportBounds.x, e.diagram.position.y);

    }

    GanttViewPortChanged(e) {
        if (!this.ganttDiagramRef.current || !this.rowHeadersRef.current || !this.columnHeadersRef.current) return;
        const ganttDiagram = this.ganttDiagramRef.current.getDiagram();
        const columnHeaders = this.columnHeadersRef.current.getDiagram();
        const rowHeaders = this.rowHeadersRef.current.getDiagram();

        var vb = ganttDiagram.viewportBounds;

        // Update properties of horizontal scale to reflect viewport
        this.gradScaleMonth.graduatedMin = vb.x;
        this.gradScaleMonth.graduatedMax = vb.x + vb.width;
        this.gradScaleMonth.elt(0).width = columnHeaders.viewportBounds.width;

        this.gradScaleDate.graduatedMin = vb.x;
        this.gradScaleDate.graduatedMax = vb.x + vb.width;
        this.gradScaleDate.elt(0).width = columnHeaders.viewportBounds.width;

        // Update properties of vertical scale to reflect viewport
        rowHeaders.scale = ganttDiagram.scale;
        rowHeaders.position = new go.Point(0, ganttDiagram.position.y);

        this.addBlockedDay();
        this.addTodayMarker();
    }

    handleModelChange(evt) {  
        if (!this.ganttDiagramRef.current) return;
        const diagram = this.ganttDiagramRef.current.getDiagram();
      
        if (this.state.layoutComplete && this.state.loaded && this.state.datesLoaded) {
            this.setState({ modified: diagram.isModified });
            this.autoSave();
        }
        diagram.isModified = false;
    }

    handleReloadFlow() {
        this.fetchUpdate();
    }

    render() {
        return (
            <div className="ganttViewContainer">

                <Modal show={this.state.editModal} style={{ zIndex: '4077' }} centered>
                    <ModalHeader>Edit WorkFlow</ModalHeader>
                    <ModalBody>
                        Be aware that editing an ongoing workflow may change deadlines and create impossible worktask relations. During editing all worktasks in this workflow will be made unavailable. Make sure to publish the workflow when all desired changes are made.
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={() => { this.setState({ editModal: false }); }}>
                            {' '}OK{' '}
                        </Button>
                    </ModalFooter>
                </Modal>

                <Card className="ganttView" >
                    <Card.Header style={this.state.isEditing ? { background: '#FFFF9E' } : {}}><b>{this.state.modelData.flowName} - Workflow view {this.state.isEditing ? '(editing)' : ''}</b>
                        <div style={{ float: 'right' }}>
                            {!this.state.isEditing ? <Button onClick={() => { this.fetchStartEdit() }} variant="primary" color="primary" size='sm' disabled={!this.state.modelData.editable}><FontAwesomeIcon icon={faPencilAlt} /> Edit</Button> : ''}{' '}
                            {this.state.isEditing ? <Button onClick={() => { this.fetchPublish(); }} variant="primary" color="primary" size='sm' disabled={!this.state.modelData.editable || this.state.modified || this.state.saving}><FontAwesomeIcon icon={faPaperPlane} /> Publish</Button> : ''}{' '}
                            {this.state.isEditing ? <Button onClick={this.manualSave.bind(this)} variant="primary" color="primary" size='sm' disabled={!this.state.modified || this.state.saving}>{this.state.saving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faCloudUploadAlt} />}</Button> : ''}{' '}
                        </div>
                    </Card.Header>
                    <Card.Body className='ganttCardBody'>
                        <div className="dateRow">
                            <div id="emptyCorner" className="emptyCorner"></div>
                            <ReactDiagram
                                ref={this.columnHeadersRef}
                                initDiagram={this.initColumnHeaders.bind(this)}
                                divClassName='columnHeaders'
                            />
                        </div>
                        <div className="contentRow">
                            <ReactDiagram
                                ref={this.rowHeadersRef}
                                initDiagram={this.initRowHeaders.bind(this)}
                                divClassName='rowHeaders'
                            />
                            <ReactDiagram
                                ref={this.ganttDiagramRef}
                                initDiagram={this.initGanttDiagram.bind(this)}
                                divClassName='ganttDiagram'
                            />
                        </div>
                    </Card.Body>
                </Card>

                <div className='ganttViewSide'>
                    <GanttProperties
                        workFlow={this.state.modelData}
                        workTask={this.state.activeNode}
                        onReloadFlow={this.handleReloadFlow.bind(this)}
                        ganttEdit={this.state.isEditing}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(WorkFlowGantt);


 //                               onModelChange={this.handleModelChange.bind(this)}