import * as React from 'react';
import { withRouter } from "./react/WithRouter";

import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';

import update from 'immutability-helper';

import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import "./Gantt.css";

import CloseTaskDialog from './CloseTaskDialog';
import TaskDetails from './TaskDetails';
import TaskFlag from './TaskFlag';
import TaskComment from './TaskComment';

import { GojsIcons } from './GojsIcons';

const progressIcons = ["progressUnefined", "progressEmpty", "progressQuarter", "progressHalf", "progressThreeQuarter", "progressFull"]
const nodeIcons = ["user", "envelope", "calendar", "file", "bookReader", "glasses", "hourglass", "clock", "tasks"];
const INITIAL_GRID_WIDTH = 30; 
const ZOOM_IN_LIMIT = 0.4;

class MyGantt extends React.Component {

    state = {
        scale: 1,
        tasks: [],
        selectedTask: null,
        showModal: '',
        isLoading: false,
        canScaleUp:true,
    };

    constructor(props) {
        super(props);
        this.ganttRef = React.createRef();
        this.tasksRef = React.createRef();

        this.GridCellHeight = 30; // document units; cannot be changed dynamically
        this.GridCellWidth = INITIAL_GRID_WIDTH;  // document units per day; this can be modified -- see rescale()
        this.TimelineHeight = 40; // document units; cannot be changed dynamically
        this.TaskMargin = 2;

        this.HighlightDay = null;
        this.Today = 0;

        this.MsPerDay = 24 * 60 * 60 * 1000;
        this.StartDate = new Date();
        this.EndDate = new Date();
        this.totalDuration = 0;
        this.numOfTasks = 0;
        this.CalendarLength = 0;

        this.GradScaleDate = null;
        this.GradScaleMonth = null;
        this.ListHeader = null;

        this.ChangingView = false;
        this.SelectedTask = 0;
    }

    componentDidMount() {
        this.fetchTaskList();
    }

    fetchTaskList() {
        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const diagram = this.ganttRef.current.getDiagram();
        const tasksDiagram = this.tasksRef.current.getDiagram();

        fetch(`api/WorkTask/MyTasks/`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }

                this.numOfTasks = data.length;

                data.forEach(task => {
                    task.deadline = new Date(task.deadline);
                    task.scheduledDate = new Date(task.scheduledDate);
                    task.category = 'task';
                })
                
                var startDate = new Date(Math.min.apply(null, data.map(task => task.scheduledDate)));

                var d = new Date();
                d.setHours(0, 0, 0, 0);
                if (startDate < d) {
                    this.Today = Math.round((d - startDate) / this.MsPerDay);
                } else {
                    this.Today = 0;
                    startDate = d;
                }

                var order = 0;
                const newData = data.map(task => {
                    order++;
                    const startTime = Math.abs(task.scheduledDate - startDate);
                    const startDays = Math.round(startTime / this.MsPerDay);

                    const durationTime = Math.abs(task.deadline - task.scheduledDate);
                    const durationDays = Math.round(durationTime / this.MsPerDay) +1;

                    this.totalDuration = Math.max(this.totalDuration, startDays + durationDays+1);

                    return { ...task, order: order, duration: durationDays, start: startDays };
                })

                var model = { class: "GraphLinksModel", nodeKeyProperty: "taskID", nodeDataArray: newData }
                diagram.model = go.Model.fromJSON(model);
                tasksDiagram.model = go.Model.fromJSON(model);

                this.setState({ tasks: data });

                this.StartDate = startDate;
                this.EndDate = this.addDays(startDate, this.totalDuration);

                //Make sure we fill the calendar with holidays even if the duration is short
                var calendarEnd;
                if (this.totalDuration > 100) {
                    calendarEnd = this.EndDate;
                } else {
                    calendarEnd = this.addDays(startDate, 100);
                }
                
                this.fetchBlockedDates(this.StartDate, calendarEnd, );
            })
            .catch(error => {

            })
    }

    setProgressStatus(taskID, progress) {
        this.setState({ isLoading: true });
        fetch(`api/WorkTask/SetProgressStatus/` + taskID, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(progress) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }

                data.forEach(task => {
                    task.deadline = new Date(task.deadline);
                    task.scheduledDate = new Date(task.scheduledDate);
                    task.category = 'task';
                })
                this.setState({ Tasks: data });

                const changedTask = data.find(task => task.taskID === taskID);
                this.setState({ isLoading: false });
                this.handleProgress(changedTask);
                this.setState({ showModal: '' });  
            })
            .catch(error => {
                this.setState({ isLoading: false });
            })
    }

    closeTask(taskID, commentText, closeType, subtasks) {

        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const ganttDia = this.ganttRef.current.getDiagram();
        const taskDia = this.tasksRef.current.getDiagram();

        this.setState({ isLoading: true });
        const closeTaskData = { taskID: taskID, closeType: closeType, comment: commentText, outOfRoleReason:"", subTasks: subtasks };

        fetch(`api/WorkTask/CloseMyTask/`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(closeTaskData) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.fetchTaskList();

                this.setState({ isLoading: false });
                this.setState({ showModal: '' });  
            })
            .catch(error => {
                this.setState({ isLoading: false });
            })

    }

    setSubTaskStatus(taskID, subTasks) {
        this.setState({ isLoading: true });
        fetch(`api/WorkTask/SetMySubTaskStatus/` + taskID, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(subTasks) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                data.forEach(task => {
                    task.deadline = new Date(task.deadline);
                    task.scheduledDate = new Date(task.scheduledDate);
                    task.category = 'task';
                })
                this.setState({ Tasks: data });

                const changedTask = data.find(task => task.taskID === taskID);
                this.updateSubTaskStatus(changedTask);

                this.setState({ isLoading: false });
                this.setState({ showModal: '' });  
            })
            .catch(error => {
                this.setState({ isLoading: false });
            })
    }

    fetchBlockedDates(fromDate, toDate) {
        var start = this.addDays(fromDate, 0).toISOString().substring(0, 10);
        var end = this.addDays(toDate, 0).toISOString().substring(0, 10);

        this.CalendarLength = Math.round(Math.abs(toDate - fromDate) / this.MsPerDay) + 1;
        this.totalDuration = Math.max(this.totalDuration, this.CalendarLength);

        fetch('api/Calendar/BlockedDates?from=' + start + '&to=' + end, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                data.forEach(date => { 
                    date.date = new Date(date.date);
                    date.day = Math.round(Math.abs(date.date - this.StartDate) / this.MsPerDay);
                    date.category = 'dateBlock';

                });
                //this.blockedDays = data;
                this.addBlockedDay(data)
            })
            .catch(error => {
                console.log(error);
                //Gör något om fel
            })
    }

    addBlockedDay(data) {
        if (!this.ganttRef.current) return;
        const diagram = this.ganttRef.current.getDiagram();
        //const $ = go.GraphObject.make;
        const model = diagram.model;

        data.forEach(date => model.addNodeData(date));
        diagram.layoutDiagram(true)
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    GetProgressIcon(iconNum) {
        if (iconNum >= progressIcons.length) iconNum = 0;
        var iconString = GojsIcons[progressIcons[iconNum]];
        var icon;
        if (typeof iconString === "string") {
            icon = go.Geometry.parse(iconString, true);
        }
        return icon;
    }

    activateTaskButtons(obj) {
        obj.findObject("COMMENT_BUTTON").visible = true;
        obj.findObject("FLAG_BUTTON").visible = true;
        obj.findObject("PROGRESS_BUTTON").visible = true;
        obj.findObject("CLOSE_BUTTON").visible = true;

        obj.findObject("COMMENT_ICON").fill = "white";
        obj.findObject("FLAG_ICON").fill= "white";
        obj.findObject("PROGRESS_ICON").fill = "white";
        obj.findObject("CLOSE_ICON").fill = "white";

        obj.findObject("TASK_TEXT").stroke = "black";
        obj.findObject("TASK_MARKER").opacity = .1;
    }

    deactivateTaskButtons(obj) {
            obj.findObject("COMMENT_BUTTON").visible = false;
            obj.findObject("FLAG_BUTTON").visible = false;
            obj.findObject("PROGRESS_BUTTON").visible = false;
            obj.findObject("CLOSE_BUTTON").visible = false;

            obj.findObject("COMMENT_ICON").fill = "darkGray";
            obj.findObject("FLAG_ICON").fill = "darkGray";
            obj.findObject("PROGRESS_ICON").fill = "darkGray";
            obj.findObject("CLOSE_ICON").fill = "darkGray";

            obj.findObject("TASK_TEXT").stroke = "darkGray";
            obj.findObject("TASK_MARKER").opacity = 0;
    }

    initTasks() {
        const that = this;
        const $ = go.GraphObject.make;

        go.Diagram.licenseKey = "73f944e0b16331b700ca0d2b113f69ed1bb37a669e821ff55e5941a1ef0068432b99ec7903d78e92d4ff5cec1c2990d1ddcc3a79c34b076be335dadb46e58ef1b63575e214584286a70576c19dfe7df4ff7976a7cabc25f0db78dff1efa0d18c5abda3d248985eba3b680530557eb04ab6e4da78";

        const diagram = $(go.Diagram,
            {
                initialContentAlignment: go.Spot.TopLeft,
                initialPosition: new go.Point(-10, -100),
                "animationManager.isEnabled": false,
                isReadOnly: true,
                padding: new go.Margin(this.TimelineHeight, 0, this.GridCellHeight, 0),
                hasVerticalScrollbar: false,
                allowHorizontalScroll: false,
                allowMove: false,
                allowCopy: false,
                grid: $(go.Panel, "Grid",
                    { gridCellSize: new go.Size(this.GridCellWidth, this.GridCellHeight) },
                    $(go.Shape, "BarH", { fill: 'GhostWhite', interval: 2, height: this.GridCellHeight }),
                ), 
            }
        );

        const TaskListTemplate = $(go.Node, 'auto',
            {
                padding: new go.Margin(0, 0, 0, 0),
                height: that.GridCellHeight,
                stretch: go.GraphObject.Horizontal,
                selectionAdorned: false,
                //click: this.rowClick.bind(this),
                mouseEnter: (e, obj) => { if (this.SelectedTask===0) this.activateTaskButtons(obj) },
                mouseLeave: (e, obj) => { if (!obj.isSelected) this.deactivateTaskButtons(obj) },
                selectionChanged: (node) => {
                    if (!this.ganttRef.current ) return;
                    const ganttDia = this.ganttRef.current.getDiagram();

                    if (node.isSelected) {
                        this.activateTaskButtons(node);
                        this.SelectedTask = node.data.taskID;

                        var ganttNode = ganttDia.findNodeForKey(node.data.taskID);
                        if (ganttNode !== null) ganttDia.select(ganttNode);

                        ganttDia.scrollToRect(ganttNode.actualBounds);
                        ganttDia.grid = $(go.Panel, "Grid",
                            { gridCellSize: new go.Size(this.GridCellWidth, this.GridCellHeight) },
                            $(go.Shape, "BarH", { fill: 'GhostWhite', interval: 2, height: this.GridCellHeight }),
                            $(go.Shape, "LineV", { stroke: 'Gainsboro', })
                        );
                    } else {
                        this.deactivateTaskButtons(node);
                        this.SelectedTask = 0;

                        var ganttNode = ganttDia.findNodeForKey(node.data.taskID);
                        if (ganttNode !== null) ganttNode.isSelected = false;
                    }
                },               
                doubleClick: (e, node) => {
                    const task = this.state.tasks.find(task => task.taskID === node.data.taskID);
                    this.setState({ selectedTask: task });
                    this.setState({ showModal: 'details' });
                },
            },
            new go.Binding("position", "", (data) => { return new go.Point(0, that.GridCellHeight * (data.order - 1)); }),

            $(go.Shape,
                "Rectangle",
                {
                    name: "TASK_MARKER",
                    visible: true,
                    height: this.GridCellHeight,
                    width: 500,
                    position: new go.Point(0, 0),
                    strokeWidth: 0,
                    fill: "blue",
                    opacity: 0,
                },
            ),

            $(go.Panel, "Table",
                { defaultAlignment: go.Spot.Left },
                $(go.RowColumnDefinition, { column: 0, width: this.GridCellHeight + 4, minimum: this.GridCellHeight + 4 }),
                $(go.RowColumnDefinition, { column: 1, width: this.GridCellHeight + 4, minimum: this.GridCellHeight + 4 }),
                $(go.RowColumnDefinition, { column: 2, width: this.GridCellHeight + 4, minimum: this.GridCellHeight + 4 }),
                $(go.RowColumnDefinition, { column: 3, width: this.GridCellHeight + 4, minimum: this.GridCellHeight + 4 }),
                $(go.RowColumnDefinition, { column: 4, }),


                $(go.Panel, "Auto",
                    {
                        row: 0,
                        column: 0,
                        margin: 2,
                        cursor: "pointer",
                        click: (e, obj) => {
                            const task = this.state.tasks.find(task => task.taskID === obj.part.data.taskID);
                            this.setState({ selectedTask: task });
                            this.setState({ showModal: 'comment' });
                        },
                    },

                    $(go.Shape,
                        "RoundedRectangle",
                        {
                            name: "COMMENT_BUTTON",
                            visible: false,
                            height: this.GridCellHeight - 4,
                            width: this.GridCellHeight,
                            strokeWidth: 1,
                            fill: "#1B6EC2",
                            stroke: "#1861AC",
                            cursor: "pointer",
                        },

                    ),

                    $(go.Shape,
                        {
                            name: "COMMENT_ICON",
                            geometry: go.Geometry.parse(GojsIcons["comment"], true),
                            strokeWidth: 0,
                            alignment: go.Spot.Center,
                            width: 15,
                            height: 15,
                            fill: "darkGray",
                            geometryStretch: go.GraphObject.Uniform,
                            cursor: "pointer",

                        },
                        new go.Binding("geometry", "comments", (comments, obj) => { return comments.length > 0 ? go.Geometry.parse(GojsIcons["commentFilled"], true) : go.Geometry.parse(GojsIcons["comment"], true) }),
                    ),
                ),

                $(go.Panel, "Auto",
                    {
                        row: 0,
                        column: 1,
                        margin: 2,
                        cursor: "pointer",
                        click: (e, obj) => {
                            const task = this.state.tasks.find(task => task.taskID === obj.part.data.taskID);
                           
                            this.setState({ selectedTask: task });
                            this.setState({ showModal: 'flag' });
                        },
                    },
                    $(go.Shape,
                        "RoundedRectangle",
                        {
                            name: "FLAG_BUTTON",
                            visible: false,
                            height: this.GridCellHeight - 4,
                            width: this.GridCellHeight,
                            strokeWidth: 1,
                            fill: "#1B6EC2",
                            stroke: "#1861AC",
                            cursor: "pointer",
                        },
                    ),

                    $(go.Shape,
                        {
                            name: "FLAG_ICON",
                            geometry: go.Geometry.parse(GojsIcons["flag"], true),
                            strokeWidth: 0,
                            alignment: go.Spot.Center,
                            width: 15,
                            height: 15,
                            fill: "darkGray",
                            geometryStretch: go.GraphObject.Uniform,
                            cursor: "pointer",

                        },
                        new go.Binding("geometry", "isFlagged", isFlagged => { return isFlagged ? go.Geometry.parse(GojsIcons["flagFilled"], true) : go.Geometry.parse(GojsIcons["flag"], true) }),
                        //new go.Binding("fill", "statusColor"),
                    ),
                ),

                $(go.Panel, "Auto",
                    {
                        row: 0,
                        column: 2,
                        margin: 2,
                        cursor: "pointer",
                        contextClick: function (e, node) {
                            e.handled = true;
                        },
                        click: (e, obj) => {
                            const task = this.state.tasks.find(task => task.taskID === obj.part.data.taskID);
                            if (task.subTasks.length === 0) {
                                e.diagram.commandHandler.showContextMenu(obj);
                            }
                        },
                        contextMenu: $("ContextMenu",
                            $("ContextMenuButton",
                                {
                                    click: function (e, obj) {
                                        that.setProgressStatus(obj.part.data.taskID, 0);
                                    }
                                },
                                $(go.Panel, "Horizontal",
                                    {
                                        padding:3
                                    },
                                    $(go.Shape,
                                        {
                                            geometry: go.Geometry.parse(GojsIcons["progressUnefined"], true),
                                            strokeWidth: 0,
                                            alignment: go.Spot.Left,
                                            width: 20,
                                            height: 20,
                                            margin:5,
                                            geometryStretch: go.GraphObject.Uniform,
                                            cursor: "pointer",

                                        },
                                    ),
                                    $(go.TextBlock, "n/d"),
                                ),
                            ),

                            $("ContextMenuButton",
                                {
                                    click: function (e, obj) {
                                        that.setProgressStatus(obj.part.data.taskID, 1);
                                    }
                                },
                                $(go.Panel, "Horizontal",
                                    {
                                        padding: 3
                                    },
                                    $(go.Shape,
                                        {
                                            geometry: go.Geometry.parse(GojsIcons["progressEmpty"], true),
                                            strokeWidth: 0,
                                            alignment: go.Spot.Left,
                                            width: 20,
                                            height: 20,
                                            margin: 5,
                                            geometryStretch: go.GraphObject.Uniform,
                                            cursor: "pointer",

                                        },
                                    ),
                                    $(go.TextBlock, "0%"),
                                ),
                            ),

                            $("ContextMenuButton",
                                {
                                    click: function (e, obj) {
                                        that.setProgressStatus(obj.part.data.taskID, 2);
                                    }
                                },
                                $(go.Panel, "Horizontal",
                                    {
                                        padding: 3
                                    },
                                    $(go.Shape,
                                        {
                                            geometry: go.Geometry.parse(GojsIcons["progressQuarter"], true),
                                            strokeWidth: 0,
                                            alignment: go.Spot.Left,
                                            width: 20,
                                            height: 20,
                                            margin: 5,
                                            geometryStretch: go.GraphObject.Uniform,
                                            cursor: "pointer",

                                        },
                                    ),
                                    $(go.TextBlock, "25%"),
                                ),
                            ),

                            $("ContextMenuButton",
                                {
                                    click: function (e, obj) {
                                        that.setProgressStatus(obj.part.data.taskID, 3);
                                    }
                                },
                                $(go.Panel, "Horizontal",
                                    {
                                        padding: 3
                                    },
                                    $(go.Shape,
                                        {
                                            geometry: go.Geometry.parse(GojsIcons["progressHalf"], true),
                                            strokeWidth: 0,
                                            alignment: go.Spot.Left,
                                            width: 20,
                                            height: 20,
                                            margin: 5,
                                            geometryStretch: go.GraphObject.Uniform,
                                            cursor: "pointer",

                                        },
                                    ),
                                    $(go.TextBlock, "50%"),
                                ),
                            ),

                            $("ContextMenuButton",
                                {
                                    click: function (e, obj) {
                                        that.setProgressStatus(obj.part.data.taskID, 4);
                                    }
                                },
                                $(go.Panel, "Horizontal",
                                    {
                                        padding: 3
                                    },
                                    $(go.Shape,
                                        {
                                            geometry: go.Geometry.parse(GojsIcons["progressThreeQuarter"], true),
                                            strokeWidth: 0,
                                            alignment: go.Spot.Left,
                                            width: 20,
                                            height: 20,
                                            margin: 5,
                                            geometryStretch: go.GraphObject.Uniform,
                                            cursor: "pointer",

                                        },
                                    ),
                                    $(go.TextBlock, "75%"),
                                ),
                            ),

                            $("ContextMenuButton",
                                {
                                    click: function (e, obj) {
                                        that.setProgressStatus(obj.part.data.taskID, 5);
                                    }
                                },
                                $(go.Panel, "Horizontal",
                                    {
                                        padding: 3
                                    },
                                    $(go.Shape,
                                        {
                                            geometry: go.Geometry.parse(GojsIcons["progressFull"], true),
                                            strokeWidth: 0,
                                            alignment: go.Spot.Left,
                                            width: 20,
                                            height: 20,
                                            margin: 5,
                                            geometryStretch: go.GraphObject.Uniform,
                                            cursor: "pointer",

                                        },
                                    ),
                                    $(go.TextBlock, "100%"),
                                ),
                            ),
                        ),
                        //"contextMenuTool.isEnabled": false,
                    },
                    new go.Binding("cursor", "subTasks", subTasks => subTasks.length > 0 ? "default" : "pointer"),

                    $(go.Shape,
                        "RoundedRectangle",
                        {
                            name: "PROGRESS_BUTTON",
                            visible: false,
                            height: this.GridCellHeight - 4,
                            width: this.GridCellHeight,
                            strokeWidth: 1,
                            fill: "#1B6EC2",
                            stroke: "#1861AC",
                        },
                        new go.Binding("fill", "subTasks", subTasks => subTasks.length > 0 ? "#5E9DFA" : "#1B6EC2"),
                        new go.Binding("stroke", "subTasks", subTasks => subTasks.length > 0 ? "#5E9DFA" : "#1861AC"),

                    ),

                    $(go.Shape,
                        {
                            name:"PROGRESS_ICON",
                            geometry: go.Geometry.parse(GojsIcons["progressUnefined"], true),
                            strokeWidth: 0,
                            alignment: go.Spot.Center,
                            width: 15,
                            height: 15,
                            fill: "darkGray",
                            geometryStretch: go.GraphObject.Uniform,
                        },
                        new go.Binding("geometry", "progressStatus", this.GetProgressIcon.bind(this)),
                    ),

                ),

                $(go.Panel, "Auto",
                    {
                        row: 0,
                        column: 3,
                        margin: 2,
                        cursor: "pointer",
                        click: (e, obj) => {
                            const task = this.state.tasks.find(task => task.taskID === obj.part.data.taskID);
                            this.setState({ selectedTask: task });
                            this.setState({ showModal: 'close' });
                        },
                    },
                    $(go.Shape,
                        "RoundedRectangle",
                        {
                            name: "CLOSE_BUTTON",
                            visible:false,
                            height: this.GridCellHeight - 4,
                            width: this.GridCellHeight,
                            strokeWidth: 1,
                            fill: "#1B6EC2",
                            stroke: "#1861AC",
                            
                        },
                    ),
                    $(go.Shape,
                        {
                            name:"CLOSE_ICON",
                            geometry: go.Geometry.parse(GojsIcons["checkSquare"], true),
                            strokeWidth: 0,
                            alignment: go.Spot.Center,
                            width: 15,
                            height: 15,
                            fill: "darkGray",
                            geometryStretch: go.GraphObject.Uniform,
                        },
                        new go.Binding("geometry", "", task => { return task.autoClose ? go.Geometry.parse(GojsIcons["hourglass"], true) : task.subTasks.length > 0 ? go.Geometry.parse(GojsIcons["tasks"], true) : go.Geometry.parse(GojsIcons["checkSquare"], true) }),
                    ),
                ),

                $(go.TextBlock,
                    {
                        name:"TASK_TEXT",
                        row: 0,
                        column: 4,
                        alignment: go.Spot.Left,
                        verticalAlignment: go.Spot.Left,
                        font: "bold 9pt sans-serif",
                        editable: false,
                        isMultiline: false,
                        margin: 5,
                        stroke: "darkGray",
                        wrap: go.TextBlock.None,
                        overflow: go.TextBlock.OverflowEllipsis,
                        textAlign: "left"
                    },
                    new go.Binding("text", "name").makeTwoWay(),
                ),
            ),
        );
        const templmap = new go.Map();
        templmap.add("task", TaskListTemplate);
        diagram.nodeTemplateMap = templmap;



        this.ListHeader = $(go.Part,
            {
                layerName: "Adornment",
                background: "white",
                position: new go.Point(0, 0),
                width:500,
                height: 42.5
            },
            $(go.Shape, {stroke: "Gainsboro", geometryString: "M0 42 H500" }),
        );      
        diagram.add(this.ListHeader);

        diagram.addDiagramListener("ViewportBoundsChanged", e => {
            this.taskViewportChanged();
        });

        return diagram;
    }

    initGantt() {
        const that = this;
        const $ = go.GraphObject.make;

        go.Diagram.licenseKey = "73f944e0b16331b700ca0d2b113f69ed1bb37a669e821ff55e5941a1ef0068432b99ec7903d78e92d4ff5cec1c2990d1ddcc3a79c34b076be335dadb46e58ef1b63575e214584286a70576c19dfe7df4ff7976a7cabc25f0db78dff1efa0d18c5abda3d248985eba3b680530557eb04ab6e4da78";

        const diagram = $(go.Diagram,
            {
               // padding: new go.Margin(this.TimelineHeight + 4, this.GridCellWidth * 7, this.GridCellHeight, 0),  // needs to be the same vertically as for myTasks
               // scrollMargin: new go.Margin(0, 0, 0, 0),  // and allow scrolling to a week beyond that
            
                maxSelectionCount: 1,
                padding: new go.Margin(this.TimelineHeight, 0, this.GridCellHeight, 0),
                initialContentAlignment: go.Spot.TopLeft,
                initialPosition: new go.Point(-10, -100),
                isReadOnly: true,
                allowDelete: false,
                allowCopy: false,
                allowZoom: false,
                "animationManager.isEnabled": false,

                model: $(go.GraphLinksModel, {
                    nodeKeyProperty: 'taskID',
                }),
                "ViewportBoundsChanged": e => {
                    const dw = diagram.documentBounds.width;
                    const vpw = diagram.viewportBounds.width;
                 
                    if (dw < vpw) {
                        const newScale = ((this.totalDuration) * INITIAL_GRID_WIDTH) / vpw;
                        this.setScale(newScale);
                    }
                },
                "DocumentBoundsChanged": e => {
                    // the grid extends to only the area needed
                    if (!this.HighlightDay || !this.GradScaleDate) return;
                    const b = e.diagram.documentBounds;
                    e.diagram.grid = $(go.Panel, "Grid",
                        { gridCellSize: new go.Size(this.GridCellWidth, this.GridCellHeight) },
                        $(go.Shape, "BarH", { fill: 'GhostWhite', interval: 2, height: this.GridCellHeight }),
                        $(go.Shape, "LineV", { stroke: 'Gainsboro', })
                    );
                    this.HighlightDay.position = new go.Point(this.Today * this.GridCellWidth, 0);
                    this.HighlightDay.width = this.GridCellWidth;  // 1 day
                    this.HighlightDay.height = Math.max(diagram.viewportBounds.height, (this.numOfTasks + 1) * this.GridCellHeight); //diagram.viewportBounds.height;

                    var vb = e.diagram.viewportBounds;

                    // Update properties of horizontal scale to reflect viewport
                    this.GradScaleMonth.graduatedMin = 0//vb.x;
                    this.GradScaleMonth.graduatedMax = this.CalendarLength * this.GridCellWidth//vb.x + vb.width;
                    this.GradScaleMonth.elt(0).width = this.CalendarLength * this.GridCellWidth//vb.width;

                    this.GradScaleDate.graduatedMin = 0//vb.x;
                    this.GradScaleDate.graduatedMax = this.CalendarLength * this.GridCellWidth//vb.x + vb.width;
                    this.GradScaleDate.elt(0).width = this.CalendarLength * this.GridCellWidth//vb.width;

                 
                },
                grid: $(go.Panel, "Grid",
                    { gridCellSize: new go.Size(this.GridCellWidth, this.GridCellHeight) },
                    $(go.Shape, "BarH", { fill: 'GhostWhite', interval: 2, height: this.GridCellHeight }),
                    $(go.Shape, "LineV", { stroke: 'Gainsboro',  })
                ),
            },
        );

        const TaskNodeTemplate = $(go.Node, "spot",
            {
                isShadowed: true,
                shadowOffset: new go.Point(3, 3),
                shadowColor: "#C5C1AA",
                maxSize: new go.Size(NaN, that.GridCellHeight),

                height: that.cellHeight - this.TaskMargin * 2,
                minLocation: new go.Point(0, NaN),
                maxLocation: new go.Point(Infinity, NaN),
                doubleClick: (e, node) => {
                    const task = this.state.tasks.find(task => task.taskID === node.data.taskID);
                    this.setState({ selectedTask: task });
                    this.setState({ showModal: 'details' });
                },
                selectionChanged: (node) => {
                    if (!this.tasksRef.current) return;
                    const taskDia = this.tasksRef.current.getDiagram();

                    if (node.isSelected) {  
                        this.SelectedTask = node.data.taskID;

                        var taskNode = taskDia.findNodeForKey(node.data.taskID);
                        if (taskNode !== null) taskNode.isSelected = true;
                    } else {
                        this.SelectedTask = 0;

                        var taskNode = taskDia.findNodeForKey(node.data.taskID);
                        if (taskNode !== null) taskNode.isSelected = false;
                    }
                },          
                toolTip:
                    $("ToolTip", 
                        {
                            isShadowed: true,
                            shadowColor: 'rgba(0, 0, 0, .5)',
                            shadowOffset: new go.Point(0, 3),
                            shadowBlur: 10,
                            background: "lightgray",
                            "Border.fill": "white",
                            "Border.stroke": "gray" 
                        },                   
                        $(go.TextBlock, { margin: 4 },
                            new go.Binding("text", "", function (data) { return data.flowName + "\n" + data.name + "\n" + data.status + "\nDeadline: " + data.deadline.toLocaleDateString('sv-SE'); }))  
                    ),
                selectionAdornmentTemplate:
                    $(go.Adornment, "Spot",
                        $(go.Panel, "Auto",
                            //$(go.Shape, "RoundedRectangle", { stroke: "RoyalBlue", strokeWidth: 2, fill: null, margin: new go.Margin(2 + this.TaskMargin, 2, 2 + this.TaskMargin, 2) }),
                            //name: "TASK_MARKER",                           
                            $(go.Shape, "Rectangle",
                                {
                                    layer: "Background",
                                    strokeWidth: 0,
                                    fill: "blue",
                                    opacity: 0.1,
                                    height: this.GridCellHeight,
                                    width: diagram.documentBounds.width * 2,
                                    position: new go.Point(-100, 0),
                                },
                                new go.Binding("width", "", function (data) { return diagram.documentBounds.width*2 })
                            ),
                            $(go.Placeholder)
                        ),
                    ),
                //selectionChanged: this.nodeSelectionChanged.bind(this),
            },
            new go.Binding("position", "", (data) => { return new go.Point(that.GridCellWidth * data.start, that.GridCellHeight * (data.order - 1)); }),

            $(go.Panel, "Auto",
                { stretch: go.GraphObject.Fill },
                $(go.Shape,
                    "RoundedRectangle",
                    {
                        name: "SHAPE",
                        strokeWidth: 1,
                        fill: "white",
                        margin: new go.Margin(this.TaskMargin, 0, this.TaskMargin, 0),

                    },
                    new go.Binding("width", "duration", (dur)=>{ return that.GridCellWidth * Math.max(1,dur) }),
                    new go.Binding("fill", "statusColor"),
                    new go.Binding("strokeDashArray", "isZeroDayTask", (val) => { return val > 0 ? [3, 3] : [] }),
                ),

                $(go.TextBlock,
                    {
                        name: "TEXT",
                        editable: false,
                        font: "bold 9pt sans-serif",
                        editable: false,
                        isMultiline: false,
                        overflow: go.TextBlock.OverflowEllipsis,
                        margin: 0,
                        wrap: go.TextBlock.None,
                        textAlign: "center",

                    },
                    new go.Binding("text", "name")
                ),
            ),

        );

        const blockMarker = $(go.Node, "spot",
            {
                layerName: "Background",
                visible: true,
                pickable: true,
                background: "rgba(128,128,128,0.1)",
                width: this.GridCellWidth,
                height: Math.max(250, diagram.model.nodeDataArray.length * this.GridCellHeight),

                toolTip: $("ToolTip",
                    {
                        isShadowed: true,
                        shadowColor: 'rgba(0, 0, 0, .5)',
                        shadowOffset: new go.Point(0, 3),
                        shadowBlur: 10,
                        background: "lightgray",
                        "Border.fill": "white",
                        "Border.stroke": "gray"
                    },
                    $(go.TextBlock, { margin: 4 },
                        new go.Binding("text", "info", function (info) { return info.map(i=>i.name).join('\n') }))
                ),
                selectionAdornmentTemplate: $(go.Adornment, "Spot", $(go.Panel, "Auto"),),
            },
            new go.Binding("width", "day", (day) => this.GridCellWidth),
            new go.Binding("height", "day", (day) => Math.max(diagram.viewportBounds.height, (this.numOfTasks+1) * this.GridCellHeight)),
            new go.Binding("position", "day", (day) => { return new go.Point(day * this.GridCellWidth, 0)  }),
        );
        
        this.HighlightDay = $(go.Part,
            {
                layerName: "Foreground", visible: true, pickable: false, background: "rgba(255,165,0,0.2)",
                position: new go.Point(0, 0), width: this.GridCellWidth, height: this.GridCellHeight
            }
        );
        diagram.add(this.HighlightDay);

        const templmap = new go.Map();
        templmap.add("task", TaskNodeTemplate);
        templmap.add("dateBlock", blockMarker);
        diagram.nodeTemplateMap = templmap;

        this.GradScaleMonth =
            $(go.Part, "Graduated",
                {
                    layerName: "Adornment",
                    background: "white",
                    position: new go.Point(0, 0),
                    graduatedTickUnit: this.GridCellWidth,
                },
                $(go.Shape, { stroke: "WhiteSmoke", geometryString: "M0 0 H" + this.GridCellWidth }),
                $(go.Shape, {
                    stroke: "Gainsboro",
                    geometryString: "M0 0 V40",
                    graduatedSkip: function (v) {
                        var d = new Date(Date.parse(that.StartDate));
                        d.setDate(d.getDate() + v / that.GridCellWidth);
                        
                        return d.getDate() == 1 ? false : true;
                    }
                }),
                $(go.TextBlock,
                    {
                        font: "10pt sans-serif",
                        stroke: "gray",
                        alignmentFocus: new go.Spot(0, 0, -4, -4),

                        graduatedSkip: function (v) {
                            if (v == 0 && that.StartDate.getDate() < 25) {
                                return null;
                            }
                            var d = new Date(Date.parse(that.StartDate));
                            d.setDate(d.getDate() + v / that.GridCellWidth);

                           
                            return d.getDate() == 1 ? null : true;
                        },

                        graduatedFunction: function (v) {
                            var d = new Date(Date.parse(that.StartDate));
                            d.setDate(d.getDate() + v / that.GridCellWidth);
                            
                            var options = { month: "short", year: "numeric" };
                            return d.toLocaleDateString("en-US", options);
                        }
                    })
            );

        this.GradScaleDate =
            $(go.Part, "Graduated",
                {
                    layerName: "Adornment",
                    background: "white",
                    position: new go.Point(0, 20),
                    graduatedTickUnit: that.GridCellWidth
                }, 
                $(go.Shape, { name:"DATEHLINE", stroke: "Gainsboro", geometryString: "M0 0 H" + this.GridCellWidth }),
                $(go.Shape, { name: "DATEVLINES", stroke: "Gainsboro", geometryString: "M0 0 V20" }),

                $(go.TextBlock,
                    {
                        name: "DATETEXT",
                        font: "8pt sans-serif",
                        alignmentFocus: new go.Spot(0, 0, 0, 0),
                        width: this.GridCellWidth,
                        textAlign: "center",
                        stroke: "gray",
                        segmentOffset: new go.Point(0, 7),
                        graduatedFunction: function (v) {
                            var d = new Date(Date.parse(that.StartDate));
                            d.setDate(d.getDate() + v / that.GridCellWidth);
                            return d.getDate();
                        }
                    },
                )
            );

        diagram.add(this.GradScaleMonth);
        diagram.add(this.GradScaleDate);

        diagram.addDiagramListener("ViewportBoundsChanged", e => {
            this.ganttViewportChanged();
        });

        return diagram;
    }

    ganttViewportChanged(e) {
        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const diagram = this.ganttRef.current.getDiagram();
        const myTasks = this.tasksRef.current.getDiagram();

        if (this.ChangingView) return;
        this.ChangingView = true;
;
        myTasks.position = new go.Point(myTasks.position.x, diagram.position.y);
        this.ListHeader.position = new go.Point(this.ListHeader.position.x, myTasks.viewportBounds.position.y);
        diagram.position = new go.Point(diagram.position.x, myTasks.position.y);  // don't scroll more if myTasks can't scroll more

        this.HighlightDay.height = Math.max(diagram.viewportBounds.height, (this.numOfTasks + 1) * this.GridCellHeight);
        diagram.updateAllTargetBindings();
        this.GradScaleDate.position = new go.Point(this.GradScaleDate.position.x, diagram.viewportBounds.position.y + 20);
        this.GradScaleMonth.position = new go.Point(this.GradScaleMonth.position.x, diagram.viewportBounds.position.y);

        this.ChangingView = false;
    }

    taskViewportChanged(e) {
        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const diagram = this.ganttRef.current.getDiagram();
        const myTasks = this.tasksRef.current.getDiagram();

        if (this.ChangingView) return;
        this.ChangingView = true;
        this.ListHeader.position = new go.Point(this.ListHeader.position.x, myTasks.viewportBounds.position.y);
        diagram.position = new go.Point(diagram.position.x, myTasks.position.y);
        myTasks.position = new go.Point(myTasks.position.x, diagram.position.y);  // don't scroll more if gantt can't scroll more
        this.GradScaleDate.position = new go.Point(this.GradScaleDate.position.x, diagram.viewportBounds.position.y + 20);
        this.GradScaleMonth.position = new go.Point(this.GradScaleMonth.position.x, diagram.viewportBounds.position.y);

        this.ChangingView = false;
    }


    setScale(scale) {
        const $ = go.GraphObject.make;

        scale = Math.max(0.4, scale);

        if (!this.ganttRef.current) return;
        const diagram = this.ganttRef.current.getDiagram();

        const dw = diagram.documentBounds.width;
        const vpw = diagram.viewportBounds.width;
        const dwNew = Math.ceil(INITIAL_GRID_WIDTH / scale) * (this.totalDuration + 1) ;

        if (dwNew > vpw) {
            this.setState({ scale: scale });
            this.GridCellWidth = Math.round(INITIAL_GRID_WIDTH / scale);
            this.setState({ canScaleUp: true });
        } else {
            const newScale = ((this.totalDuration + 1) * INITIAL_GRID_WIDTH) / vpw;
            this.setState({ scale: newScale });
            this.GridCellWidth = Math.ceil(vpw / (this.totalDuration + 1));
            this.setState({ canScaleUp: false });
        }

        diagram.commit(diag => {

            diag.scrollMargin = new go.Margin(0, 0, 0, 0);
            diag.updateAllTargetBindings();
            diag.layout.cellHeight = this.GridCellHeight;
            diag.layoutDiagram(true);
            this.GradScaleDate.graduatedTickUnit =  this.GridCellWidth;
            this.GradScaleMonth.graduatedTickUnit = this.GridCellWidth;

            this.GradScaleDate.findObject("DATETEXT").width = this.GridCellWidth;
            if (this.GridCellWidth < 16) {            
                this.GradScaleDate.findObject("DATETEXT").visible = false;
                this.GradScaleDate.findObject("DATEVLINES").visible = false;
                this.GradScaleDate.findObject("DATEHLINE").visible = false;
            } else {
                this.GradScaleDate.findObject("DATETEXT").visible = true;
                this.GradScaleDate.findObject("DATEVLINES").visible = true;
                this.GradScaleDate.findObject("DATEHLINE").visible = true;
            }
        }, null);  // skipsUndoManager 
    } 

    updateSubTaskStatus(task) {
        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const ganttDia = this.ganttRef.current.getDiagram();
        const taskDia = this.tasksRef.current.getDiagram();

        ganttDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(task.taskID);
            diag.model.set(data, "progressStatus", task.progressStatus);
            diag.model.set(data, "subTasks", task.subTasks);
            diag.updateAllTargetBindings();
        }, null);

        taskDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(task.taskID);
            diag.model.set(data, "progressStatus", task.progressStatus);
            diag.model.set(data, "subTasks", task.subTasks);
            diag.updateAllTargetBindings();
        }, null);
    }

    handleClose(e) {
        if (e.closeType === 0) {
            this.setSubTaskStatus(e.taskID, e.subTasks);
        } else {
            this.closeTask(e.taskID, e.comment, e.closeType, e.subTasks);
        }   
    }

    handleProgress(e) {
        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const ganttDia = this.ganttRef.current.getDiagram();
        const taskDia = this.tasksRef.current.getDiagram();

        ganttDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(e.taskID);
            diag.model.set(data, "progressStatus", e.progressStatus);    
            diag.updateAllTargetBindings();
        }, null);

        taskDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(e.taskID);
            diag.model.set(data, "progressStatus", e.progressStatus);
            diag.updateAllTargetBindings();
        }, null);
    }

    handleFlag(e) {
        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const ganttDia = this.ganttRef.current.getDiagram();
        const taskDia = this.tasksRef.current.getDiagram();

        ganttDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(e.taskID);
            diag.model.set(data, "comments", e.comments);
            diag.model.set(data, "isFlagged", e.isFlagged);
            diag.model.set(data, "status", e.status);
            diag.model.set(data, "statusColor", e.statusColor); 
            diag.updateAllTargetBindings();
        }, null);

        taskDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(e.taskID);
            diag.model.set(data, "comments", e.comments);
            diag.model.set(data, "isFlagged", e.isFlagged);
            diag.model.set(data, "status", e.status);
            diag.model.set(data, "statusColor", e.statusColor);
            diag.updateAllTargetBindings();
        }, null);

        const index = this.state.tasks.findIndex(obj => { return obj.taskID === e.taskID });
        const newTasks = update(this.state.tasks, { [index]: { $set: e } });
        this.setState({ tasks: newTasks });
        this.setState({ showModal: '' });
    }

    handleComment(e) {
        if (!this.ganttRef.current || !this.tasksRef.current) return;
        const ganttDia = this.ganttRef.current.getDiagram();
        const taskDia = this.tasksRef.current.getDiagram();

        ganttDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(e.taskID);
            diag.model.set(data, "comments", e.comments);
            diag.updateAllTargetBindings();
        }, null);

        taskDia.commit(diag => {
            const data = diag.model.findNodeDataForKey(e.taskID);
            diag.model.set(data, "comments", e.comments);
            diag.updateAllTargetBindings();
        }, null);

        const index = this.state.tasks.findIndex(obj => { return obj.taskID === this.state.selectedTask.taskID });
        const newTasks = update(this.state.tasks, { [index]: { comments: { $set: e.comments } } });

        this.setState({ tasks: newTasks });
        this.setState({ showModal: '' });
    }
 
    render() {
        return (
            <div className="ganttViewContainer">
                <TaskDetails show={this.state.showModal==='details'} task={this.state.selectedTask} onHide={() => this.setState({ showModal: '' })} />
                <CloseTaskDialog show={this.state.showModal === 'close'} task={this.state.selectedTask} onCancel={() => this.setState({ showModal: '' })} onClose={this.handleClose.bind(this)} isLoading={this.state.isLoading} />
                <TaskFlag show={this.state.showModal === 'flag'} task={this.state.selectedTask} onCancel={() => this.setState({ showModal: '' })} onClose={this.handleFlag.bind(this)} />
                <TaskComment show={this.state.showModal === 'comment'} task={this.state.selectedTask} onCancel={() => this.setState({ showModal: '' })} onClose={e => this.handleComment(e)} />

                <Card className="ganttView" >
                    <Card.Header><b>My WorkTasks  </b>{' '}
                        <ButtonGroup>
                            <Button onClick={() => this.props.navigate("/TaskList")} variant="primary" color="primary" size='sm'>Card list</Button>
                            <Button variant="outline-primary" size='sm' disabled={true}>Gantt</Button>
                        </ButtonGroup>
                        <div style={{ float: 'right' }}>
                            <Button onClick={() => { this.setScale(this.state.scale / 1.2) }} variant="primary" color="primary" size='sm' disabled={this.state.scale <= ZOOM_IN_LIMIT}> <FontAwesomeIcon icon={faMagnifyingGlassPlus} /></Button>{' '}
                            <Button onClick={() => { this.setScale(this.state.scale * 1.2) }} variant="primary" color="primary" size='sm' disabled={!this.state.canScaleUp}> <FontAwesomeIcon icon={faMagnifyingGlassMinus} /></Button>{' '}
                        </div>
                    </Card.Header>
                    <Card.Body className='ganttCardBody'>
                        <div className="contentRow">
                            <ReactDiagram
                                ref={this.tasksRef}
                                initDiagram={this.initTasks.bind(this)}
                                divClassName='taskList'
                            />
                            <ReactDiagram
                                ref={this.ganttRef}
                                initDiagram={this.initGantt.bind(this)}
                                divClassName='ganttDiagram'
                            />
                        </div>
                    </Card.Body>
                </Card> 
            </div>
        );
    }
}
export default withRouter(MyGantt);