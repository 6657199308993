import React from "react";
import { NavLink } from 'react-router-dom';
import { withRouter } from "./react/WithRouter";

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';

import { faKey, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Login.css";

class Register extends React.Component {
    state = {
        username: "",
        isLoading: false,
        password1:"",
        password2: "",
        message: "",
        confirmID: "",
        isConfirmation: false,
        success: false,
    };

    constructor(props) {
        super(props);

        this.passwordRef = React.createRef(null);

    }

    componentDidMount() {
        const confirmID = this.props.params.id;
        if (confirmID) {
            if ((/^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/).test(confirmID)) {
                this.setState({ confirmID: confirmID });
                this.checkConfirmID(confirmID);
            }
        }
    }

    //asking server if confirmation ID is valid
    checkConfirmID(confirmID) {
        this.setState({ isLoading: true });
        fetch('api/Password/CheckConfirmID/' + confirmID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                if (!data.success) {
                    this.setState({ message: data.message });
                }
                this.setState({ isConfirmation: data.success });
                this.setState({ isLoading: false });
            })
            .catch(error => {
                this.setState({ message: 'Error checking confirm ID' });
            })
    }


    tryReset() {
        const { username } = this.state;

        //return;
        this.setState({ isLoading: true });
        fetch('api/Password/RequestReset', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ username }) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                if (data.message) {
                    this.setState({ message: data.message });
                }
                this.setState({ isLoading: false });
            })
            .catch(error => {
                this.setState({ message: error });
                this.setState({ isLoading: false });
            })
    }

    tryConfirm() {
        if (!this.validatePassword())
             return;
        var password = this.state.password1;
        this.setState({ isLoading: true });
        fetch('api/Password/Reset', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ password: password, confirmID: this.state.confirmID }) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                if (data.message) {
                    this.setState({ message: data.message });
                }
                this.setState({ success: data.success });
                this.setState({ isLoading: false });
            })
            .catch(error => {
                this.setState({ message: 'Error' });
            })
    }

    validatePassword() {
        if (this.state.password1 !== this.state.password2) { 
            this.setState({ message: "Passwords do not match" });
            return false;
        }
        if (this.state.password1.length < 8) { 
            this.setState({ message: "Password is shorter than 8 characters" });
            return false;
        }
        this.setState({ message: "" });
        return true;
    }

    getFooter(mode) {
        if (mode === "request") {
            return (
                <div className="flex flex-wrap justify-content-end gap-2">
                    <Button
                        className="p-button-sm"
                        label={this.state.isLoading ? 'Loading...' : 'Request password reset'}
                        icon={this.state.isLoading ? 'pi pi-spin pi-spinner' : null}
                        style={{ width: '100%' }}
                        disabled={this.state.isLoading}
                        onClick={this.tryReset.bind(this)} 
                    />
                    <NavLink to="/Login">Login</NavLink>
                </div>
            );
        } else if (mode === "confirm") {
            return (
                <div className="flex flex-wrap justify-content-end gap-2">
                    <Button
                        className="p-button-sm"
                        label={this.state.isLoading ? 'Loading...' : 'Register new password'}
                        icon={this.state.isLoading ? 'pi pi-spin pi-spinner' : null}
                        style={{ width: '100%' }}
                        disabled={this.state.isLoading}
                        onClick={this.tryConfirm.bind(this)}
                    />
                    <NavLink to="/Login">Login</NavLink>
                </div>
            );
        } else {
            return (
                <NavLink to="/Login">Login</NavLink>
            );
        }
    }

    getBody(mode) {
        if (mode === "request") {
            return (
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        <FontAwesomeIcon variant="primary" icon={faAt} />
                    </span>
                    <InputText
                        placeholder="my_email@email.com"
                        value={this.state.username}
                        onChange={e => this.setState({ username: e.target.value })}
                        maxLength="255"
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && !this.state.isLoading) {
                                this.tryReset();
                            }
                        }}
                    />
                </div>
            );
        } else if (mode === "confirm") {
            const header = <div className="font-bold mb-3">Pick a password</div>;
            const footer = (
                <>
                    <Divider />
                    <p className="mt-2">Password must:</p>
                    <ul className="pl-2 ml-2 mt-0 line-height-3">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>At least one special character</li>
                        <li>Minimum 8 characters</li>
                    </ul>
                </>
            );
            return (
                <div>
                    {this.state.message.length ?
                        <Message severity="error" text={this.state.message} /> : null}
                    <div className="p-inputgroup flex-1" style={{ marginTop: '0.5rem' }}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon variant="primary" icon={faKey} />
                        </span>
                        <Password
                            placeholder="password"
                            toggleMask value={this.state.password1}
                            onChange={(e) => this.setState({ password1: e.target.value })}
                            header={header}
                            footer={footer}
                            onKeyPress={(e) => { if (e.key === "Enter") { this.passwordRef.current.focus(); } }}
                        />
                    </div>
                    <div className="p-inputgroup flex-1" style={{ marginTop: '0.5rem' }}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon variant="primary" icon={faKey} />
                        </span>
                        <Password
                            ref={this.passwordRef}
                            placeholder="repeat password"
                            toggleMask
                            value={this.state.password2}
                            onChange={(e) => this.setState({ password2: e.target.value })}
                            header={header}
                            footer={footer}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && !this.state.isLoading) {
                                    this.tryConfirm();
                                }
                            }}
                        />
                    </div>
                </div>
            );
        } else {
            return (
               <div> {this.state.message}</div>
            );
        }
    }

    render() {
        var mode = "request";
        if (this.state.isConfirmation && !this.state.success) {
            mode = "confirm";
        } else if (this.state.message.length > 0) {
            mode = "message";
        }

        const header = (
            <img alt="Card" src="../img/Login.jpg" height="199" width="100%" />
        );

        const body = this.getBody(mode);
        const footer = this.getFooter(mode);

        return (
            <div className="loginBackground">
                <Card className="loginContainer" title="Reset password" header={header} footer={footer}>
                    {body}
                </Card>
            </div>
        );
    }
}
export default withRouter(Register);