import React from "react";
import { NavLink, Navigate } from 'react-router-dom';
import { withRouter } from "./react/WithRouter";

//import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
//import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';


import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';


import "./Login.css";

import { faUser, faKey, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Register extends React.Component {
    state = {
        username: "",
        password1: "",
        password2: "",
        fullName: "",
        message: "",
        isLoading: false,
        success: false,
        isPreFilled: false,
        isConfirmation: false,
        confirmID: "",
        isMounted: false,
        errorMess: '',
    };

    constructor(props) {
        super(props);

        this.passwordRef = React.createRef(null);
    }

    componentDidMount() {
        const confirmID = this.props.params.id;
        if (confirmID) {
            if ((/^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/).test(confirmID)) {
                this.setState({ confirmID: confirmID });
                this.checkConfirmID(confirmID);
            }
        }
        this.setState({ isMounted: true })

    }


    //asking server if confirmation ID is valid
    checkConfirmID(confirmID) {
        this.setState({ isLoading: true });
        fetch('api/Register/CheckConfirmID/' + confirmID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                if (!data.success) {
                    this.setState({ message: data.message });
                }
                this.setState({ isConfirmation: data.success });
                this.setState({ isLoading: false });
            })
            .catch(error => {
                this.setState({ message: 'Error checking confirm ID' });
            })
    }

    fetchConfirm() {
        const { password1, password2, confirmID } = this.state;
        if (password1 == password2) {
            this.setState({ isLoading: true });
            fetch(`api/Register/Confirm`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ password1, password2, confirmID }) })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.error) {
                        throw (data.error);
                    }
                    this.setState({ success: data.isSuccess });
                    this.setState({ message: data.responseText });
                    this.setState({ isLoading: false });

                })
                .catch(error => {
                    console.log(error);
                    //this.setState({ errorMess: error });
                })
        } else {
            this.setState({ errorMess: 'Passwords do not match' });
        }
    }

    getFooter() {
        if (this.state.isConfirmation && !this.state.success) {
            return (
                <div className="flex flex-wrap justify-content-end gap-2">
                    <Button
                        className="p-button-sm"
                        label={this.state.isLoading ? 'Loading...' : 'Register password'}
                        icon={this.state.isLoading ? 'pi pi-spin pi-spinner' : null}
                        style={{ width: '100%' }}
                        disabled={this.state.isLoading}
                        onClick={this.fetchConfirm.bind(this)}
                    />
                    <NavLink to="/Login">Login</NavLink>
                </div>
            );
        } else {
            return (
                <NavLink to="/Login">Login</NavLink>
            );
        }
    }

    getBody() {
        if (this.state.isConfirmation && !this.state.success) {
            const header = <div className="font-bold mb-3">Pick a password</div>;
            const footer = (
                <>
                    <Divider />
                    <p className="mt-2">Password must:</p>
                    <ul className="pl-2 ml-2 mt-0 line-height-3">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>At least one special character</li>
                        <li>Minimum 8 characters</li>
                    </ul>
                </>
            );
            return (
                <div>
                    {this.state.message.length ?
                        <Message severity="error" text={this.state.message} /> : null}
                    <div className="p-inputgroup flex-1" style={{ marginTop: '0.5rem' }}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon variant="primary" icon={faKey} />
                        </span>
                        <Password
                            placeholder="password"
                            toggleMask value={this.state.password1}
                            onChange={(e) => this.setState({ password1: e.target.value })}
                            header={header}
                            footer={footer}
                            onKeyPress={(e) => { if (e.key === "Enter") { this.passwordRef.current.focus(); } }}
                        />
                    </div>
                    <div className="p-inputgroup flex-1" style={{ marginTop: '0.5rem' }}>
                        <span className="p-inputgroup-addon">
                            <FontAwesomeIcon variant="primary" icon={faKey} />
                        </span>
                        <Password
                            ref={this.passwordRef}
                            placeholder="repeat password"
                            toggleMask value={this.state.password2}
                            onChange={(e) => this.setState({ password2: e.target.value })}
                            header={header}
                            footer={footer}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && !this.state.isLoading) {
                                    this.fetchConfirm();
                                }
                            }}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div> {this.state.message}</div>
            );
        }
    }

    render() {
        const header = (
            <img alt="Card" src="../img/Login.jpg" height="199" width="100%" />
        );

        const body = this.getBody();
        const footer = this.getFooter();

        return (
            <div className="loginBackground">
                <Card className="loginContainer" title="Confirm account" header={header} footer={footer}>
                    {body}
                </Card>
            </div>
        );
    }
}


export default withRouter(Register);