import React from "react";

import Card from "react-bootstrap/Card";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import "../Layout.css";

class TaskDetails extends React.Component {

    state = {
    };

    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.task || !this.props.show) {
            return (<div></div>);
        } else {
            return (
                <Modal scrollable={true} show={this.props.show} style={{ zIndex: '4077' }} centered onHide={() => { if (this.props.onHide()) this.props.onHide(); }}>
                    <Modal.Header style={{ background: this.props.task.statusColor, color: 'white' }} closeButton><b>Details for {this.props.task.name}</b></Modal.Header>
                    <Modal.Body>
                        <Card bg='light' className='mb-3'>
                            <Card.Header className='dependencyHeader'><b>Info</b></Card.Header>
                            <Card.Body>
                                <Table size='sm'>
                                    <tbody>
                                        <tr>
                                            <td><b>WorkFlow:</b></td>
                                            <td>{this.props.task.flowName}</td>
                                        </tr>
                                        {this.props.task.isStarted ? null : <tr>
                                            <td><b>Scheduled:</b></td>
                                            <td>{this.props.task.scheduledDate.toLocaleDateString('sv-SE')}</td>
                                        </tr>}
                                        <tr>
                                            <td><b>Deadline:</b></td>
                                            <td>{this.props.task.deadline.toLocaleDateString('sv-SE')}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Status:</b></td>
                                            <td>{this.props.task.status}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Responsible:</b></td>
                                            <td>{this.props.task.responsible}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Description:</b></td>
                                            <td>{this.props.task.description}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                        <Card bg='light' className='mb-3'>
                            <Card.Header className='dependencyHeader'><b>Dependencies</b></Card.Header>
                            <Card.Body>

                                <Table hover size='sm'>
                                    {this.props.task.previousTasks.length === 0 ? null :
                                        <thead>
                                            <tr>
                                                <th>Previous task</th>
                                                <th>Status</th>
                                                <th>Responsible</th>
                                            </tr>
                                        </thead>
                                    }
                                    <tbody>
                                        {this.props.task.previousTasks.map(value => {
                                            return (
                                                <tr key={'previousTask' + value.taskID}>
                                                    <td>{value.name}</td>
                                                    <td style={{ 'backgroundColor': value.statusColor }}>{value.status}</td>
                                                    <td>{value.responsible}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                    {this.props.task.nextTasks.length === 0 ? null :
                                        <thead>
                                            <tr>
                                                <th>Next task</th>
                                                <th>Status</th>
                                                <th>Responsible</th>
                                            </tr>
                                        </thead>
                                    }
                                    <tbody>
                                        {this.props.task.nextTasks.map(value => {
                                            return (
                                                <tr key={'nextTask' + value.taskID} >
                                                    <td>{value.name}</td>
                                                    <td style={{ 'backgroundColor': value.statusColor }}>{value.status}</td>
                                                    <td>{value.responsible}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                </Modal>
            );
        }
    }
 
}
export default TaskDetails; 