import React, { useState } from 'react';
import { Link } from "react-router-dom";

import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { WhiteLogoLarge, WhiteLogoSmall } from "./Logo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram, faDraftingCompass, faBook, faCalendarAlt, faCheckSquare, faBan, faChartLine, faMagnifyingGlassChart, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function SideMenu({ menuCollapse, menuItems } ) {

    const { collapseSidebar, collapsed } = useProSidebar();

    React.useEffect(() => {
        if (collapsed !== menuCollapse) {
            collapseSidebar(menuCollapse);
        }
    });

    const menuIcon = (iconNum) => {
        switch (iconNum) {
            case 0:
                return <FontAwesomeIcon icon={faProjectDiagram} />
                break;
            case 1:
                return <FontAwesomeIcon icon={faDraftingCompass} />
                break;
            case 2:
                return <FontAwesomeIcon icon={faCheckSquare} />
                break;
            case 3:
                return <FontAwesomeIcon icon={faBook} />
                break;
            case 4:
                return <FontAwesomeIcon icon={faCalendarAlt} />
                break;
            case 5:
                return <FontAwesomeIcon icon={faChartLine} />
                break;
            case 6:
                return <FontAwesomeIcon icon={faMagnifyingGlassChart} />
                break;
            case 7:
                return <FontAwesomeIcon icon={faTriangleExclamation} />
                break;
            default:
                return <FontAwesomeIcon icon={faBan} />
        }
    }

    return (
        <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
            <Sidebar
                width="14rem"
                backgroundColor="transparent"
                rootStyles={{ background: 'linear-gradient(0deg, CornflowerBlue, royalblue)', }}
            >
                <div className="logotext">
                    <p>{menuCollapse ? <WhiteLogoSmall /> : <WhiteLogoLarge />}</p>
                </div>
                <Menu
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            // only apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    color: disabled ? '#101010' : '#FFFFFF',
                                    backgroundColor: active ? 'rgba(0, 0, 0, 0.15)' : undefined,
                                    //fontWeight: 'bold',
                                    //fontSize: '14px',
                                    "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.15) !important",
                                    },
                                };
                        },
                        icon: ({ level, active, disabled }) => {
                            if (level === 0)
                                return {
                                    backgroundColor: 'rgba(0, 0, 0, 0.15)',
                                    borderRadius: '3px',
                                };
                        },
                    }}

                >
                    {menuItems.map((item, index) => (
                        <MenuItem key={index} icon={menuIcon(item.icon)} component={<Link to={item.link} />}>{item.text}</MenuItem>
                    ))}
                </Menu>
            </Sidebar>
        </div >
    );
}

export default SideMenu;

