import React from "react";
import { Navigate, NavLink } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import UserContext from '../context/UserContext';

import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Login.css";


class Login extends React.Component {

    static contextType = UserContext;

    state = {
        username: " ",
        password: "",
        message: "",
        emailInvalid: false,
        passwordInvalid: false,
        loading: false,
        isLoggedIn: false
    };

    constructor(props) {
        super(props);

        this.passwordRef = React.createRef(null);
    }

    componentDidMount() {
        //const { user, setUser } = this.context;
        //this.setUser = setUser;
        /*setUser({
            userID: "phlerp@gmail.com",
            fullName: "Daniel Berlin",
            organisation: "Zymology",
            orgID: 2,
            availableOrgs: [],
            isLoggedIn: true});*/

        //console.log(user) 
        if (this.context.user.isLoggedin && this.props.action === "logout") {
            this.context.logout();
        } else {
            //this.fetchVersion();
        }
    }

    fetchVersion() {
        this.setState({ loading: true });
        fetch('api/Session/CurrentVersion', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                console.log(data);
                this.setState({ loading: false });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
                //Gör något om fel
            })
    }


    componentDidUpdate(prevProps) {
        if (this.context.user.isLoggedin && this.props.action === "logout") {
            this.context.logout();
        }
      
    }  

    tryLogin() {
        const { username, password } = this.state;
        if (!username) {
            this.setState({ message: "Provide a valid username" })
            this.setState({ emailInvalid: true });
            this.setState({ passwordInvalid: false });
            return;
        } else if (!password) {
            this.setState({ message: "Provide a valid password." });
            this.setState({ passwordInvalid: true });
            this.setState({ emailInvalid: false });
            return;
        }
        this.context.login(username, password);
        this.setState({ password: "" });
        this.setState({ passwordInvalid: false });
        this.setState({ emailInvalid: false });
        this.setState({ loading: true });
        return;
    };

    render() {
        const { user, isLoading, login, logout } = this.context;

        if (!user.isLoggedin && this.props.action === "logout") {
            return <Navigate to="/Login" />
        } else if (user.isLoggedin && this.props.action !== "logout") {
            return <Navigate to="/TaskList" />
        }
        
        return (
            <div className="loginBackground">
                <Card className="loginContainer shadow-lg" >
                    <Card.Img top="true" width="100%" src="../img/Login.jpg" height="199" />
                    <Card.Body>
                        <Row>
                            <InputGroup className="mb-3 loginInput">
                                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon variant="primary" icon={faUser} /></InputGroup.Text>
                                <FormControl
                                    id="loginEmail"
                                    type="email"
                                    name="email"
                                    placeholder="my_email@email.com"
                                    value={this.state.username}
                                    onChange={e => this.setState({ username: e.target.value })}
                                    onKeyPress={(e) => { if (e.key === "Enter") { this.passwordRef.current.focus(); } }}
                                />
                            </InputGroup>
                        </Row>
                        <Row>
                            <InputGroup className="mb-2 loginInput">
                                <InputGroup.Text><FontAwesomeIcon variant="primary" icon={faKey} /></InputGroup.Text>
                                <FormControl
                                    ref={this.passwordRef}
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="password"
                                    value={this.state.password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter" && !(isLoading || this.props.action === "logout")) {
                                            this.tryLogin();
                                        }
                                    }}
                                />
                            </InputGroup>
                        </Row>
                        <Row>
                        {isLoading || this.props.action === "logout"
                            ? <Button variant="primary" color="primary" disabled >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {" "}Loading...
                            </Button>
                            : <Button variant="primary" color="primary" onClick={this.tryLogin.bind(this)}>Login</Button>
                            }
                        </Row>
                        <span style={{ float: "right" }}><NavLink to="/PasswordReset">Reset password</NavLink></span>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default Login;

