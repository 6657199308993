import React, { Component } from 'react'


const emptyUser = {
    userID: "",
    fullName: "",
    organisation: "",
    orgID: 0,
    isAdmin: false,
    availableOrgs: [],
    isLoggedin: false,
};
const UserContext = React.createContext({ user: emptyUser });

class UserProvider extends Component {
    // Context state
    state = {
        user: {
            userID: "",
            fullName: "",
            organisation: "",
            orgID: 0,
            isAdmin: false,
            availableOrgs: [],
            permissions:[],
            isLoggedin: false,
        },
        isLoading: false,
    }

    login = (username, password) => {
        this.setState((prevState) => ({ isLoading: true }));
        fetch(`api/Session/Login`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ username: username, password: password }) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                //Add data to context
                this.setState((prevState) => ({ user: data, isLoading: false }));
            })
            .catch(error => {
                //Do something at error
                console.log(error);
                this.setState((prevState) => ({ user: emptyUser, isLoading: false }));
            })
    }

    logout = () => {
        this.setState((prevState) => ({ user: emptyUser, isLoading: true }));
        fetch(`api/Session/Logout`, { method: 'DELETE' })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                //Add data to context
                this.setState((prevState) => ({ user: emptyUser, isLoading: false }));
            })
            .catch(error => {
                //Do something at error
                console.log("error logging out");
                console.log(error);
                this.setState((prevState) => ({ user: emptyUser, isLoading: false }));
            })
    }

    changeOrg = (orgID) => {
        this.setState((prevState) => ({ isLoading: true }));
        fetch(`api/Session/ChangeOrg`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ orgID: orgID }) })
            .then((response) => {
                //console.log(response);
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    //console.log(data.error);
                    throw (data.error);
                }
                //Add data to context
                this.setState((prevState) => ({ user: data, isLoading: false }));
            })
            .catch(error => {
                //Do something at error
                console.log(error);
                this.setState((prevState) => ({ isLoading: false }));
            })
    }

    render() {
        const { children } = this.props;
        const { user, isLoading } = this.state;
        const { login, logout, changeOrg } = this;
        return (
            <UserContext.Provider value={{ user, isLoading, login, logout, changeOrg }} >
                {children}
            </UserContext.Provider>
        );
    }
}

export default UserContext;

export { UserProvider };


    

  