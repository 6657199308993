import * as React from 'react';

import update from 'immutability-helper';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import { faFlag, faCommentDots, faHourglassHalf as faHourglass, faTasks, faUser, faEnvelope, faCalendarAlt, faFile, faBookReader, faGlasses, faClock as fasClock } from "@fortawesome/free-solid-svg-icons";
import { faFlag as farFlag, faCheckSquare, faComment, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GanttCloseTask from './GanttCloseTask';
import GanttComment from './GanttComment';
import GanttFlag from './GanttFlag';

import "./Gantt.css";

import { faStatusEmpty } from '../Icons/custom-icon-pack'
import { faStatusQuarter } from '../Icons/custom-icon-pack'
import { faStatusHalf } from '../Icons/custom-icon-pack'
import { faStatusThreeQuarter } from '../Icons/custom-icon-pack'
import { faStatusFull } from '../Icons/custom-icon-pack'
import { faStatusUndefined } from '../Icons/custom-icon-pack'

import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faStatusEmpty);
library.add(faStatusQuarter);
library.add(faStatusHalf);
library.add(faStatusThreeQuarter);
library.add(faStatusFull);
library.add(faStatusUndefined);


class GanttProperties extends React.Component {

    state = {
        workFlow: { flowName: '', flowDescr: '' },
        workTask: { nodeID: 0, nodeName: '', nodeDescr: '', responsibleName: '' },
        taskDetails: { taskID: 0, name: "", comments: [], subTasks: [] },
        tabKey: "WorkFlow",
        isLoading: false,
        isCloseLoading: false,
    };

    progress = [
        { name: ' n/d', icon: <FontAwesomeIcon icon={faStatusUndefined} />, key: 0 },
        { name: '  0%', icon: <FontAwesomeIcon icon={faStatusEmpty} />, key: 1 },
        { name: ' 25%', icon: <FontAwesomeIcon icon={faStatusQuarter} />, key: 2 },
        { name: ' 50%', icon: <FontAwesomeIcon icon={faStatusHalf} />, key: 3 },
        { name: ' 75%', icon: <FontAwesomeIcon icon={faStatusThreeQuarter} />, key: 4 },
        { name: '100%', icon: <FontAwesomeIcon icon={faStatusFull} />, key: 5 },

    ];

    statusIcon(iconNum) {
        switch (iconNum) {
            case 0:
                return faStatusUndefined;
            case 1:
                return faStatusEmpty;
            case 2:
                return faStatusQuarter;
            case 3:
                return faStatusHalf;
            case 4:
                return faStatusThreeQuarter;
            case 5:
                return faStatusFull;
            default:
                return faStatusUndefined;
        }
    } 

    componentDidUpdate(prevProps) { 
        if (prevProps.workFlow !== this.props.workFlow) {
            this.setState({ workFlow: this.props.workFlow || {} });
        }
        if (prevProps.workTask !== this.props.workTask) {
            this.setState({ workTask: this.props.workTask || {} });
            if (this.props.workTask.nodeID) {
                this.setState({ tabKey: "Task" });
                this.fetchDetails(this.props.workTask.nodeID);
            } else {
                this.setState({ tabKey: "WorkFlow" });
            }
        }
    } 

    fetchDetails(taskID) {
        if (!taskID) return;
        this.setState({ isLoading: true });
        fetch(`api/WorkTask/OneTasks/` + taskID , { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ taskDetails: data });
                this.setState({ isLoading: false });

            })
            .catch(error => {
                this.setState({ isLoading: false });
            })

    }

    setSubTaskStatus(taskID, subTasks) {
        this.setState({ isCloseLoading: true });
        fetch(`api/WorkTask/SetSubTaskStatus/` + taskID, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(subTasks) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ Tasks: data });
                this.setState({ isCloseLoading: false });

                this.props.onReloadFlow && this.props.onReloadFlow();
                this.fetchDetails(this.state.workTask.nodeID);
            })
            .catch(error => {
                this.setState({ isCloseLoading: false });
            })
    }

    CloseTask(taskID, commentText, reason, closeType, subtasks) {
        this.setState({ isCloseLoading: true });
        const closeTaskData = { taskID: taskID, closeType: closeType, comment: commentText, outOfRoleReason: reason, subTasks: subtasks };

        fetch(`api/WorkTask/CloseTask/`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(closeTaskData) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ Tasks: data });
                this.setState({ isCloseLoading: false });

                this.props.onReloadFlow && this.props.onReloadFlow();
                this.fetchDetails(this.state.workTask.nodeID);
            })
            .catch(error => {
                this.setState({ isCloseLoading: false });
            })

    }

    handleCloseTask(event) {
        if (event.closeType === 0) {
            this.setSubTaskStatus(event.taskID, event.subTasks);
        } else {
            this.CloseTask(event.taskID, event.comment, event.reason, event.closeType, event.subTasks);
        }
    }

    handleAddComment(e) {
        const taskDetails = update(this.state.taskDetails, { comments: { $set: e.comments } });
        this.setState({ taskDetails: taskDetails });
    }

    handleToggleFlag(e) {
        this.setState({ taskDetails: e });
        this.props.onReloadFlow && this.props.onReloadFlow();
    }

    handleProgressChange(progress) {
        //this.setState({ isLoading: true });
        fetch(`api/WorkTask/SetProgressStatus/` + this.state.workTask.nodeID, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(progress) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ Tasks: data });
               

                this.props.onReloadFlow && this.props.onReloadFlow();
                this.fetchDetails(this.state.workTask.nodeID);
                this.setState({ isLoading: false });
            })
            .catch(error => {
                this.setState({ isLoading: false });
            })
    }

    render() {
        const that = this;
        return (
            <div className="ganttProperties">
                <Tabs defaultActiveKey="profile" id="ganttPropertiesTabs" activeKey={this.state.tabKey} onSelect={(key) => this.setState({ tabKey: key })}>
                    <Tab eventKey="WorkFlow" title="WorkFlow">
                        <div className="propertyTab">
                            <Form.Label>Name</Form.Label>
                            <FormControl
                                type="text"
                                name="flowName"
                                value={this.state.workFlow.flowName}                               
                                readOnly
                            />
                            <Form.Label>Description</Form.Label>
                            <FormControl
                                as="textarea"
                                rows="3"
                                name="flowDescr"
                                value={this.state.workFlow.flowDescr}                               
                                readOnly
                            />                      
                        </div>
                    </Tab>
                    <Tab eventKey="Task" title="Task" disabled={this.state.workTask.nodeID === 0}>
                        <div className="propertyTab">
                            <Form.Label>Name</Form.Label>
                            <FormControl
                                type="text"
                                name="flowName"
                                value={this.state.workTask.nodeName}
                                readOnly
                            />
                            <Form.Label>Description</Form.Label>
                            <FormControl
                                as="textarea"
                                rows="3"
                                name="flowDescr"
                                value={this.state.workTask.nodeDescr}
                                readOnly
                            />
                            <Form.Label>Responsible</Form.Label>
                            <FormControl
                                type="text"
                                name="flowName"
                                value={this.state.workTask.responsibleName}
                                readOnly
                            />  
                            <hr />
                            {this.state.isLoading ?

                                <div>
                                    <Spinner animation="border" /> Loading details...
                                </div>
                                : this.props.ganttEdit ? null:
                                    <Accordion  flush >
                                        <Accordion.Item eventKey="CommentAccordion">
                                            <Accordion.Header>
                                                {this.state.taskDetails.comments.length>0 ?
                                                    <FontAwesomeIcon icon={faCommentDots} />
                                                    : <FontAwesomeIcon icon={faComment} />
                                                }
                                                &nbsp; Comments</Accordion.Header>
                                            <Accordion.Body style={{ padding: '2px' }}>
                                                <GanttComment task={this.state.taskDetails} onAddComment={this.handleAddComment.bind(this) } />
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="FlagAccordion">
                                            <Accordion.Header>
                                                {this.state.taskDetails.isFlagged ?
                                                    <FontAwesomeIcon icon={faFlag} />
                                                    : <FontAwesomeIcon icon={farFlag} />
                                                }
                                                &nbsp; Flag</Accordion.Header>
                                            <Accordion.Body style={{ padding: '2px' }}>
                                                <GanttFlag task={this.state.taskDetails} onToggle={this.handleToggleFlag.bind(this)} disabled={this.state.taskDetails.isClosed} />
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="ProgressAccordion" >
                                            <Accordion.Header>
                                                <FontAwesomeIcon icon={this.statusIcon(this.state.taskDetails.progressStatus)} />  
                                                &nbsp; Progress status</Accordion.Header>
                                            <Accordion.Body style={{ padding: '2px' }}>
                                              
                                                <div className="d-grid gap-2" style={{ paddingTop: '0.5rem' }}>
                                                    <ButtonGroup vertical>
                                                        {this.progress.map((progress, index) => (
                                                            <ToggleButton
                                                                key={index}
                                                                id={'progress-${index}'}
                                                                type="radio"
                                                                variant='outline-primary'
                                                                name="radio"
                                                                value={progress.key}
                                                                checked={this.state.taskDetails.progressStatus === progress.key}
                                                                onClick={e => this.handleProgressChange(progress.key)}
                                                                disabled={this.state.taskDetails.isClosed || this.state.taskDetails.subTasks.length > 0}
                                                            >
                                                                {progress.icon} {progress.name}
                                                            </ToggleButton>
                                                        ))}
                                                    </ButtonGroup>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="CloseAccordion">
                                            <Accordion.Header>
                                                {this.state.taskDetails && this.state.taskDetails.autoClose ?
                                                    <FontAwesomeIcon icon={faHourglass} />
                                                    : this.state.taskDetails && this.state.taskDetails.subTasks && this.state.taskDetails.subTasks.length > 0 ?
                                                        <FontAwesomeIcon icon={faTasks} />
                                                        : <FontAwesomeIcon icon={faCheckSquare} />
                                                }
                                                &nbsp; Close WorkTask
                                            </Accordion.Header>
                                            <Accordion.Body style={{ padding: '2px' }}>
                                                <GanttCloseTask task={this.state.taskDetails} onClose={this.handleCloseTask.bind(this)} isLoading={this.state.isCloseLoading} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                            }
                        </div>
                    </Tab> 
                </Tabs>

            </div>
        );
    }
}

export default (GanttProperties);
