import { useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();
        const params = useParams();
        return (
            <Component
                navigate={navigate}
                {...props}
                params={params}
            />
        );
    };

    return Wrapper;
};


/*import React from 'react';
import { useParams } from 'react-router-dom';

const withRouter = WrappedRouterComponent => props => {
    const params = useParams();

    return (
        <WrappedRouterComponent
            {...props}
            params={params}
        />
    );
};

export default withRouter;*/