import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";

import UserContext from '../context/UserContext';

class PrivateRoute extends React.Component {

    static contextType = UserContext;

    render() {
        const { user, isLoading } = this.context;
        const { element: element, ...rest } = this.props;

        if (!user.isLoggedin) {
            return < Navigate to="/Login" replace/>;
        }
        return this.props.children;
    }
}

export default PrivateRoute;
