import * as React from 'react';

import { faGripLines, faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import "./FlowTemplate.css";

class SubTaskList extends React.Component {

    state = {
        subTasks: [], 
        selectedItem: null,
    };

    constructor(props) {
        super(props);
        this.state.subTasks = props.subTasks || [];
        this.focusBlurTimeout = null;
        this.nameRef = React.createRef();
    }

    componentDidMount() {
       
    }

    componentWillUnmount() {
        clearTimeout(this.focusBlurTimeout);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.subTasks !== this.props.subTasks) {
            this.setState({ subTasks: this.props.subTasks || [] });
            if (this.props.subTasks.length > prevProps.subTasks.length && prevProps.taskID === this.props.taskID) {
                this.setState({ selectedItem: this.props.subTasks.length - 1 });
            } else if (prevProps.taskID !== this.props.taskID) {
                this.setState({ selectedItem: null });
            }
        }
    }

    handleOnDragEnd(result) {
        if (result.destination != null) {
            const clone = [...this.state.subTasks];
            Array.prototype.splice.call(clone, result.destination.index, 0,
                Array.prototype.splice.call(clone, result.source.index, 1)[0]
            );
            if (this.state.selectedItem != null)
                this.setState({ selectedItem: result.destination.index });
            this.props.onListChanged(clone);
        }
    }

    deleteItem(index) {
        this.props.onListChanged([...this.state.subTasks.slice(0, index), ...this.state.subTasks.slice(index + 1)]);
        
        setTimeout(() => {
            if (this.state.selectedItem > index) {
                this.setState({ selectedItem: this.state.selectedItem - 1 });
            } else if (this.state.selectedItem == index) {
                this.setState({ selectedItem: null });
            }
        });
    }

    addItem() {
        clearTimeout(this.focusBlurTimeout);
        var newID = 0;
        if (this.state.subTasks.length>0)
            newID = this.state.subTasks.reduce((a, b) => a.id > b.id ? a : b).id;
        newID++;
        var newSubTask = { id: newID, name: '', descr: '' }
        this.props.onListChanged([...this.state.subTasks, newSubTask]);
    }

    selectItem(index) {
        this.setState({ selectedItem: index });
    }

    handleBlur(e) {
        e.persist();
        clearTimeout(this.focusBlurTimeout);
        this.focusBlurTimeout = setTimeout(() => {
            this.setState({ selectedItem: null,});
        }, 16);
    }

    handleFocus(index, e) {
        clearTimeout(this.focusBlurTimeout);
        this.focusBlurTimeout = setTimeout(() => {
            this.setState({ selectedItem: index, });
        }, 16);
    }

    handleItemNameChange(e,index) {
        const id = this.state.subTasks[this.state.selectedItem].id;
        const name = e.target.value;
        const description = this.state.subTasks[this.state.selectedItem].descr;
 
        this.props.onListChanged([...this.state.subTasks.slice(0, index), { id: id, name: name, descr: description }, ...this.state.subTasks.slice(index + 1)]);
    }

    handleItemDescrChange(e,index) {
        const id = this.state.subTasks[this.state.selectedItem].id;
        const name = this.state.subTasks[this.state.selectedItem].name;
        const description = e.target.value;

        this.props.onListChanged([...this.state.subTasks.slice(0, index), { id: id, name: name, descr: description }, ...this.state.subTasks.slice(index + 1)]);
    }

    render() {
        return (
            <div>
                <DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
                    <Droppable droppableId="items" type="PERSON">
                        {(provided, snapshot) => {
                            return (
                                <div
                                    className="subtaskList"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    {this.state.subTasks.map(({ id, name, descr }, index) => {
                                        return (
                                            <Draggable key={"draggable" + id} draggableId={"draggable" +id} index={index} >
                                                {(provided) => (
                                                    <div
                                                        className="subTaskItem"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                            <div className="subTaskHandle" {...provided.dragHandleProps}>
                                                                <FontAwesomeIcon icon={faGripLines} color="lightgray" />
                                                            </div>
                                                        {this.state.selectedItem === index ? <div className="subTaskContent">
                                                            <FormControl
                                                                ref={this.nameRef}
                                                                type="text"
                                                                name="subTaskName"
                                                                placeholder="Name of subtask"
                                                                value={index < this.props.subTasks.length ? this.props.subTasks[index].name : ''}
                                                                maxLength="64"
                                                                size="sm"
                                                                onBlur={this.handleBlur.bind(this)}
                                                                onFocus={this.handleFocus.bind(this, index)}
                                                                onChange={e => this.handleItemNameChange(e, index)}
                                                                autoFocus
                                                            />
                                                            <FormControl
                                                                as="textarea"
                                                                rows="3"
                                                                size="sm"
                                                                name="subTaskDescr"
                                                                placeholder="Description of subtask"
                                                                value={index < this.props.subTasks.length ? this.props.subTasks[index].descr:''}
                                                                onBlur={this.handleBlur.bind(this)}
                                                                onFocus={this.handleFocus.bind(this, index)}
                                                                onChange={e => this.handleItemDescrChange(e,index)}
                                                            />    
                                                                </div> : <div className="subTaskContent"
                                                                onClick={this.selectItem.bind(this, index)}>{name}</div>}
                                                        {this.state.selectedItem === index ? "" :
                                                            <div className="subTaskButtons">
                                                                <Button variant="outline-secondary" className="btn-xs" onClick={this.deleteItem.bind(this, index)} onFocus={this.handleFocus.bind(this, this.state.selectedItem)}><FontAwesomeIcon icon={faTrashAlt} color="darkGray" /></Button>
                                                            </div>}
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
                <div className="d-grid gap-2">
                    <Button variant="outline-secondary" onClick={this.addItem.bind(this)} onFocus={this.handleFocus.bind(this, this.state.selectedItem)}>Add new subtask <FontAwesomeIcon icon={faPlus} /></Button>
                </div>
            </div>
        );
    }
}

export default (SubTaskList);
