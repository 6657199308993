import React from "react";
import update from 'immutability-helper';

import { withRouter } from "./react/WithRouter";

import DataTable from 'react-data-table-component';

import UserContext from '../context/UserContext';

import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Spinner from 'react-bootstrap/Spinner';

import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { faEllipsisVertical, faShareNodes, faPencil, faTrashCan, faCirclePlay, faCalendarDays, faCopy, faRecycle, faPlusSquare, faCircleXmark, faShare, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ShareTemplate from './ShareTemplate';

import "../Layout.css";

class FlowTemplateList extends React.Component {
	static contextType = UserContext;

	state = {
		filterText: "",
		newTemplateID: 0, //ID of the created 
		openModal: "",
		isModified: false,
		isModalLoading: false,
		isLoadingData: false,
		isModalValidated: false,
		isNotValidDeadline: true,
		EditBeforeAct: false,
		AcceptShortDeadlines: false,
		DeadlineIsShort: false,
		ModalCreateName: "",
		IsDeadline: true,
		DeadlineDate: "",
		selectedTemplateID: 0,
		shareTemplateID:0,
		templateListData: [],
		templateListUnfiltered: [],
		deadlineValidationMessage: "",
		selectedTemplateOwners: [],
		selectedTemplateRunners: [],
		adminFilter: false,
		myRoles: [],
		mySelectedRoleID: 0,
	};

	componentDidMount() {
		this.fetchTemplateList();
		this.fetchRoleList();
	}

	savePermissions() {
		// id,owners,runners
		this.setState({ isModalLoading: true });
		this.setState({ isLoadingData: true });
		fetch(`api/Template/SavePermissions`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: this.state.selectedTemplateID, owners: this.state.selectedTemplateOwners, runners: this.state.selectedTemplateRunners }) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {

					throw (data.error);
				}
				this.setState({ isModalLoading: false });
				this.setState({ openModal: "" }) 
				this.fetchTemplateList()
			})
			.catch(error => {
				this.setState({ isLoadingData: false });
				//this.setState({ message: error });
			})

	}

	fetchTemplateList() {
		this.setState({ isLoadingData: true });
		fetch(`api/Template/GetTemplateList`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}

				var multiAdmin = this.context.user.orgList.filter(org => org.admin).length > 1
				var canShare = multiAdmin && this.context.user.isAdmin;
				data.forEach(template => { canShare && template.copyrightOrg === this.context.user.orgID ? template.canShare = true : template.canShare = false })

				this.setState({ templateListUnfiltered: data });
				if (this.context.user.isAdmin && !this.state.adminFilter) {
					const filteredTemplates = data.filter(template => template.isEditable || template.isRunable);
					this.setState({ templateListData: filteredTemplates });
				} else { 
					this.setState({ templateListData: data });
				}
				this.setState({ isLoadingData: false });
			})
			.catch(error => {
				this.setState({isLoadingData: false});
				//this.setState({ message: error });
			})
	}

	fetchRoleList() {
		this.setState({ isModalLoading: true });
		fetch(`api/UserRoles/GetRoleListForUser`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				
				this.setState({ mySelectedRoleID: data[0].roleID });
				

				this.setState({ myRoles: data });
				this.setState({ isModalLoading: false });
			})
			.catch(error => {
				this.setState({ isModalLoading: false });
				//this.setState({ message: error });
			})
	}

	fetchNew(templateID, name, owner) {
		this.setState({ selectedTemplateID: 0 });
		fetch(`api/Template/AddTemplate`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ copiedTemplateID: templateID, templateName: name, owner: owner }) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);

				}
				this.setState({ isModalLoading: false });
				this.setState({ openModal: "" });
				//this.setState({ newTemplateID: data });
				this.props.navigate("/FlowTemplateView/" + data);
			})
			.catch(error => {
				//this.setState({ message: error });
			})
	}

	fetchDelete(templateID) {
		this.setState({ selectedTemplateID: 0 });
		fetch(`api/Template/DeleteTemplate/` + templateID, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {

					throw (data.error);
				}
				this.setState({ isModalLoading: false });
				this.setState({ openModal: "" });
				this.fetchTemplateList();
			})
			.catch(error => {
				//this.setState({ message: error });
			})
	}

	fetchStartNow() {
		const templateID = this.state.selectedTemplateID;
		const name = this.state.ModalCreateName;
		const startDate = (new Date()).toISOString().slice(0, 10);

		fetch(`api/WorkFlow/CreateWorkFlow`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ templateID: templateID, workflowName: name, deadline: startDate, isDeadline: false, shortDeadline: true, activated: true }) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				} else {
					this.setState({ isModalStart: false });
					this.setState({ isModalLoading: false });
					this.setState({ openModal: "" });
					this.props.navigate("/WorkFlowView/" + data);
					//this.props.history.push("/WorkFlowView/" + data);
				}
			})
			.catch(error => {
				//this.setState({ message: error });
			})
	}

	fetchStart() {
		const templateID = this.state.selectedTemplateID;
		const name = this.state.ModalCreateName;
		const deadline = this.state.DeadlineDate;
		const isDeadline = this.state.IsDeadline;
		const shortDeadline = this.state.AcceptShortDeadlines;
		const activated = this.state.EditBeforeAct;
		fetch(`api/WorkFlow/CreateWorkFlow`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ templateID: templateID, workflowName: name, deadline: deadline, isDeadline: isDeadline, shortDeadline: shortDeadline, activated: !activated }) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				if (data === -1) {
					this.setState({ isNotValidDeadline: true });
					this.setState({ DeadlineIsShort: true });
					this.setState({ deadlineValidationMessage: "The deadline is too short for the estimate. Change date or accept short deadlines below" });
					this.setState({ isModalValidated: true });
					this.setState({ isModalLoading: false });
				} else if (data > 0) {
					this.setState({ openModal: "" });
					this.setState({ isModalLoading: false });
					this.props.navigate("/WorkFlowView/" + data);
					//this.props.history.push("/WorkFlowView/" + data);
				}

			})
			.catch(error => {
				//this.setState({ message: error });
			})
	}

	checkDeadline(date, acceptShortDeadlines, isStartDate = false) {
		var today = new Date();
		today.setHours(0, 0, 0, 0);

		var deadline = new Date(date);
		deadline.setHours(0, 0, 0, 0);
		if (date === "") {
			this.setState({ isNotValidDeadline: true });
			this.setState({ DeadlineIsShort: false });
		} else if (Date.parse(deadline) - Date.parse(today) < 0 && !isStartDate) {
			this.setState({ isNotValidDeadline: true });
			this.setState({ DeadlineIsShort: false });
		} else if (this.state.DeadlineIsShort && !acceptShortDeadlines) {
			this.setState({ isNotValidDeadline: true });
		} else {
			this.setState({ isNotValidDeadline: false });
			this.setState({ DeadlineIsShort: false });
		}
	}

	startWorkFlow() {
		this.checkDeadline(this.state.DeadlineDate, this.state.AcceptShortDeadlines);

		if (this.state.ModalCreateName !== "" && !this.state.isNotValidDeadline) {
			this.setState({ isModalLoading: true });
			this.fetchStart();
		} else {
			this.setState({ isModalValidated: true });
		}
	}

	startWorkFlowNow() {
		if (this.state.ModalCreateName !== "") {
			this.setState({ isModalLoading: true });
;
			this.fetchStartNow();
		} else {
			this.setState({ isModalValidated: true });
		}
	}

	activateDeleteModal(templateID) {
		document.activeElement.blur()
		this.setState({ selectedTemplateID: templateID });
		this.setState({ openModal: "delete" });
		this.setState({ isModalValidated: false });
	}


	activateNewModal() {
		document.activeElement.blur()
		this.setState({ selectedTemplateID: 0 });
		this.setState({ ModalCreateName: "" });
		this.setState({ openModal: "new" });
		this.setState({ isModalValidated: false });
		this.fetchRoleList();
	}

	activateCopyModal(templateID) {
		document.activeElement.blur()
		this.setState({ selectedTemplateID: templateID });
		this.setState({ ModalCreateName: "" });
		this.setState({ openModal: "new" });
		this.setState({ isModalValidated: false });
	}


	activateScheduleModal(templateID, name) {
		document.activeElement.blur()
		this.setState({ IsDeadline: true });
		this.setState({ isNotValidDeadline: true });
		this.setState({ DeadlineDate: "" });
		this.setState({ ModalCreateName: name });
		this.setState({ selectedTemplateID: templateID });
		this.setState({ openModal: "schedule" });		
		this.setState({ isModalValidated: false });
		this.setState({ deadlineValidationMessage: "" });
		this.setState({ DeadlineIsShort: false });
	}

	activateStartModal(templateID, name) {
		document.activeElement.blur()
		this.setState({ selectedTemplateID: templateID });
		this.setState({ openModal: "start" });
		this.setState({ ModalCreateName: name });
		this.setState({ isModalValidated: false });
	}

	activateShareModal(templateID, name) {
		document.activeElement.blur()

		this.setState({ shareTemplateName: name });
		this.setState({ shareTemplateID: templateID });
    }

	activateRoleModal(templateID) {
		this.setState({ openModal: "roles" });
		this.setState({ selectedTemplateID: templateID });
		this.setState({ isModified: false });

		const template = this.state.templateListData.find(template => template.id === templateID) || {};

		const selectedOwners = template.owners.map(a => ({ ...a }));
		const selectedRunners = template.runners.map(a => ({ ...a }));

		this.setState({ selectedTemplateOwners: selectedOwners });
		this.setState({ selectedTemplateRunners: selectedRunners });
	}

	EditTemplate(templateID) {
		//this.setState({ newTemplateID: templateID });
		this.props.navigate("/FlowTemplateView/" + templateID);
	}

	onFilter(e) {
		this.setState({ filterText: e.target.value });
	}

	handleCloseShare() {
		this.setState({ shareTemplateName: '' });
		this.setState({ shareTemplateID: 0 });
	}

	handleAdminFilter(e) {
		this.setState({ adminFilter: e.target.checked });

		if (this.context.user.isAdmin && !e.target.checked) {
			const filteredTemplates = this.state.templateListUnfiltered.filter(template => template.isEditable || template.isRunable);
			this.setState({ templateListData: filteredTemplates });
		} else {
			this.setState({ templateListData: this.state.templateListUnfiltered });
		}
	}

	scheduleModal() {
		return (
			<Modal show={true} style={{ zIndex: '4077' }} centered>
				<ModalHeader>Schedule Workflow</ModalHeader>
				<ModalBody>
					<Form>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control
								required
								type="text"
								placeholder="Enter name"
								name="TemplateName"
								value={this.state.ModalCreateName}
								onChange={e => this.setState({ ModalCreateName: e.target.value })}
								disabled={this.state.isModalLoading}
								isInvalid={this.state.ModalCreateName === "" && this.state.isModalValidated}
								isValid={this.state.ModalCreateName !== "" && this.state.isModalValidated}
								maxLength="64"
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label> {this.state.IsDeadline ? "Deadline" : "Start date"}</Form.Label>
							<Form.Control
								type="date"
								placeholder="Enter date"
								name="TemplateDate"
								value={this.state.DeadlineDate}
								onChange={e => { this.checkDeadline(e.target.value, this.state.AcceptShortDeadlines); this.setState({ DeadlineDate: e.target.value }); this.setState({ DeadlineIsShort: false }); }}
								disabled={this.state.isModalLoading}
								isInvalid={this.state.isNotValidDeadline && this.state.isModalValidated}
								isValid={!this.state.isNotValidDeadline && this.state.isModalValidated}
							/>
							<Form.Control.Feedback type="invalid">
								{this.state.deadlineValidationMessage}
							</Form.Control.Feedback>
							<Form.Check
								type="switch"
								id="date-switch"
								label="Date is deadline"
								checked={this.state.IsDeadline}
								onChange={e => { this.checkDeadline(e.target.valu, this.state.AcceptShortDeadlines); this.setState({ IsDeadline: e.target.checked }); this.setState({ DeadlineIsShort: false }); }}
								disabled={this.state.isModalLoading}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Check
								type="switch"
								id="edit-switch"
								label="Edit before activation"
								checked={this.state.EditBeforeAct}
								onChange={e => this.setState({ EditBeforeAct: e.target.checked })}
								disabled={this.state.isModalLoading}
							/>
							<Form.Check
								type="switch"
								id="ignore-switch"
								label="Accept short deadlines"
								checked={this.state.AcceptShortDeadlines}
								onChange={e => { this.setState({ AcceptShortDeadlines: e.target.checked }); this.checkDeadline(this.state.DeadlineDate, e.target.checked); }}
								disabled={this.state.isModalLoading}
							/>
						</Form.Group>

					</Form>
				</ModalBody>
				<ModalFooter>
					<Button variant="primary" onClick={() => { this.startWorkFlow(); }} disabled={this.state.isModalLoading}>
						{this.state.isModalLoading ?
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/> : ''}
						{' '}Schedule{' '}
					</Button>{' '}
					<Button variant="secondary" onClick={() => { this.setState({ openModal: "" }) }} disabled={this.state.isModalLoading}>Cancel</Button>
				</ModalFooter>
			</Modal>
		);
    }

	startModal() {
		return (
			<Modal show={true} style={{ zIndex: '4077' }} centered>
				<ModalHeader>Start Workflow now</ModalHeader>
				<ModalBody>
					<Form>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								name="TemplateName"
								id="registerEmail"
								value={this.state.ModalCreateName}
								onChange={e => this.setState({ ModalCreateName: e.target.value })}
								disabled={this.state.isModalLoading}
								isInvalid={this.state.ModalCreateName === "" && this.state.isModalValidated}
								isValid={this.state.ModalCreateName !== "" && this.state.isModalValidated}
								maxLength="64"
							/>
							<Form.Text className="text-muted">
								This is the name you use to identify this particular active FlowTemplate
							</Form.Text>
						</Form.Group>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button variant="primary" onClick={() => { this.startWorkFlowNow(); }} disabled={this.state.isModalLoading}>
						{this.state.isModalLoading ?
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/> : ''}
						{' '}Start{' '}
					</Button>{' '}
					<Button variant="secondary" onClick={() => { this.setState({ openModal: "" }) }} disabled={this.state.isModalLoading}>Cancel</Button>
				</ModalFooter>
			</Modal>
		);
    }

	deleteModal() {
		return (
			<Modal show={true} style={{ zIndex: '4077' }} centered>
				<ModalHeader>Archive FlowTemplate</ModalHeader>
				<ModalBody>
					Do you really want to move the FlowTemplate to the archive?
				</ModalBody>
				<ModalFooter>
					<Button variant="primary" onClick={() => { this.fetchDelete(this.state.selectedTemplateID); this.setState({ isModalLoading: true }); }}>
						{this.state.isModalLoading ? <Spinner animation="border"
							as="span"
							size="sm"
							role="status"
							aria-hidden="true" /> : ''}
						{' '}Archive{' '}
					</Button>{' '}
					<Button variant="secondary" onClick={() => { this.setState({ openModal: "" }) }} disabled={this.state.isModalLoading}>Cancel</Button>
				</ModalFooter>
			</Modal>
		);
    }

	newModal() {

		var dialogName = "Create new FlowTemplate";
		if (this.state.selectedTemplateID > 0) {
			dialogName = "Create copy of FlowTemplate"; 
		}

		const footerContent = (
			<div>
				<Button variant="primary" onClick={() => { this.fetchNew(this.state.selectedTemplateID, this.state.ModalCreateName, this.state.mySelectedRoleID); this.setState({ isModalLoading: true }); }} disabled={this.state.isModalLoading || this.state.ModalCreateName.length === 0}>
					{this.state.isModalLoading ? <Spinner animation="border"
						as="span"
						size="sm"
						role="status"
						aria-hidden="true" /> : ''}
					{' '}Create{' '}
				</Button>{' '}
				<Button variant="secondary" onClick={() => { this.setState({ openModal: "" }) }} disabled={this.state.isModalLoading}>Cancel</Button>
			</div>
		);

		return (
			<Dialog header={dialogName} visible={this.state.openModal === 'new'} style={{ width: '50vw' }} onHide={() => this.setState({ modalWindow: '' })} closable={false} footer={footerContent}>
				<div className="p-fluid">
					<div className="p-field">
						<label className="p-field-label">Name</label>
						<InputText
							value={this.state.ModalCreateName}
							placeholder="Enter name"
							onChange={e => this.setState({ ModalCreateName: e.target.value })}
							disabled={this.state.isModalLoading}
							maxLength="64"
						/>
					</div>	
					{this.state.myRoles.length > 1 && this.state.selectedTemplateID === 0 ?
						<div className="p-field">
							<label className="p-field-label">FlowTemplate owner (can edit)</label>
							<Dropdown
								value={this.state.mySelectedRoleID}
								optionValue="roleID"
								options={this.state.myRoles}
								optionLabel="name"
								placeholder="Select one of your roles..."
								disabled={this.state.isModalLoading}
								onChange={e => this.setState({ mySelectedRoleID: e.value }) }
								className="p-inputtext-sm w-full md:w-20rem"
							/>
						</div>
					: null}
				</div>
			</Dialog>
		);
		/*	<Modal show={true} style={{ zIndex: '4077' }} centered>
				<ModalHeader> {this.state.selectedTemplateID > 0 ? "Create copy of FlowTemplate" : "Create new FlowTemplate"}</ModalHeader>
				<ModalBody>
					<Form>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								name="TemplateName"
								id="registerEmail"
								value={this.state.ModalCreateName}
								onChange={e => this.setState({ ModalCreateName: e.target.value })}
								disabled={this.state.isModalLoading}
								maxLength="64"
							/>
							<Form.Text className="text-muted">
								This is the name you use to identify this particular active FlowTemplate
							</Form.Text>
							{ this.state.myRoles.length>1 ? 
								<div className="p-fluid">
									<div className="p-field">
										<label className="p-field-label">FlowTemplate owner (can edit)</label> 
										<MultiSelect
											optionValue = "roleID"
											options={this.state.myRoles}
											optionLabel="name"
											placeholder="Select one of your roles..."
											className="p-inputtext-sm w-full md:w-20rem"
										/>
									</div>
								</div>
							: null}
						</Form.Group>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button variant="primary" onClick={() => { this.fetchNew(this.state.selectedTemplateID, this.state.ModalCreateName); this.setState({ isModalLoading: true }); }} disabled={this.state.isModalLoading}>
						{this.state.isModalLoading ? <Spinner animation="border"
							as="span"
							size="sm"
							role="status"
							aria-hidden="true" /> : ''}
						{' '}Create{' '}
					</Button>{' '}
					<Button variant="secondary" onClick={() => { this.setState({ openModal: "" }) }} disabled={this.state.isModalLoading}>Cancel</Button>
				</ModalFooter>
			</Modal>
		);*/
	}

	roleModal() {
		
		const template = this.state.templateListData.find(template => template.id === this.state.selectedTemplateID) || {};

		var assignedOwners, assignedRunners;
		if (this.state.selectedTemplateOwners) {
			assignedOwners = this.state.selectedTemplateOwners.filter(perm => perm.isAssigned);
		}
		if (this.state.selectedTemplateRunners) {
			assignedRunners = this.state.selectedTemplateRunners.filter(perm => perm.isAssigned);
		}

		var isThisUserOwner = false;
		if (template.owners) {
			isThisUserOwner = assignedOwners.some(role => role.isThisUser) || false;
		}

		const footerContent = (
			<div>
				<Button variant="primary" onClick={() => { this.savePermissions()}} disabled={this.state.isModalLoading || !this.state.isModified}>Save</Button>
				<Button variant="secondary" onClick={() => { this.setState({ openModal: "" }) }} disabled={this.state.isModalLoading}>Cancel</Button>
			</div>
		);

		return (
			<Dialog header={template.name} visible={this.state.openModal === 'roles'} style={{ width: '22.5rem' }} onHide={() => this.setState({ modalWindow: '' })} closable={false} footer={footerContent}>
				<p>Handle permissions for this FlowTemplate</p> 
				
				<div className="p-fluid">
					<div className="p-field">
						<label className="p-field-label">FlowTemplate owner (can edit)</label> {isThisUserOwner ? "" : <small id="username2-help" className="p-error block">You are currently not owner of this Flowtemplate and will not be able to change it</small>}
						<MultiSelect
							value={assignedOwners}
							options={this.state.selectedTemplateOwners}
							optionLabel="name"
							placeholder="Select role..."
							className="p-inputtext-sm w-full md:w-20rem"
							display="chip"
							onChange={(e) => this.handleFlowOwnerChange(e)}
						/>
						
					</div>
					<div className="p-field">
						<label className="p-field-label">FlowTemplate implementer (can start)</label>
						<MultiSelect
							value={assignedRunners}
							options={this.state.selectedTemplateRunners}
							optionLabel="name"
							placeholder="Select role..."
							className="p-inputtext-sm w-full md:w-20rem"
							display="chip"
							onChange={(e) => this.handleFlowStarterChange(e)}
						/>
					</div>
				</div>
			</Dialog>
		);
	}

	handleFlowOwnerChange(e) {
		if (e.value.length > 0) {
			this.setState({ isModified: true });
			const roles = this.state.selectedTemplateOwners.map(role => { role.isAssigned = e.value.some(assigned => assigned.roleID === role.roleID); return role; });
			this.setState({ selectedTemplateOwner: roles });
		}
	}

	handleFlowStarterChange(e) {
		this.setState({ isModified: true });
		const roles = this.state.selectedTemplateRunners.map(role => { role.isAssigned = e.value.some(assigned => assigned.roleID === role.roleID); return role; });
		this.setState({ selectedTemplateRunners: roles });	
	} 

	menuPopup(row) {
		return (
			<Popover id="popover-basic" className="popup-menu shadow ">
				<Popover.Body>
					<ListGroup>
						<ListGroup.Item onClick={() => { document.body.click(); this.EditTemplate(row.id); }} disabled={!row.isEditable}>
							<FontAwesomeIcon icon={faPencil} size="sm" /> Edit
						</ListGroup.Item>
						<ListGroup.Item onClick={() => { document.body.click(); this.activateStartModal(row.id, row.name); }} disabled={row.status != 1 || (!row.isEditable && !row.isRunable)}>
							<FontAwesomeIcon icon={faCirclePlay} size="sm" /> Start now
						</ListGroup.Item>
						<ListGroup.Item onClick={() => { document.body.click(); this.activateScheduleModal(row.id, row.name); }} disabled={row.status != 1 || (!row.isEditable && !row.isRunable)}>
							<FontAwesomeIcon icon={faCalendarDays} size="sm" /> Schedule
						</ListGroup.Item>
						<ListGroup.Item onClick={() => { document.body.click(); this.activateCopyModal(row.id) }} disabled={!row.isEditable}>
							<FontAwesomeIcon icon={faCopy} /> Create copy
						</ListGroup.Item>
						<ListGroup.Item onClick={() => { document.body.click(); this.activateDeleteModal(row.id) }} disabled={!row.isEditable}>
							<FontAwesomeIcon icon={faTrashCan} size="sm" /> Delete
						</ListGroup.Item>
						<ListGroup.Item onClick={() => { document.body.click(); this.activateShareModal(row.id, row.name) }} disabled={!row.canShare}>
							<FontAwesomeIcon icon={faShare} size="sm" /> Share
						</ListGroup.Item>
						<ListGroup.Item onClick={() => { document.body.click(); this.activateRoleModal(row.id) }} disabled={!row.isEditable && !this.context.user.isAdmin} >
							<FontAwesomeIcon icon={faUserGear} size="sm"/> Permissions
						</ListGroup.Item>
					</ListGroup>
				</Popover.Body>
			</Popover>
		);
	}

	customLoader() {
		return (
			<div style={{ padding: '24px'}}>
				<Spinner animation="border"/>
				<div>Loading...</div>
			</div>
		);
	}

	render() {
		{/*if (this.state.newTemplateID > 0) {
			return <Redirect to={"/FlowTemplateView/" + this.state.newTemplateID} />
		}*/}

		const statusFilterOptions = {
			0: 'Archived',
			1: 'Active',
			2: 'Incomplete'
		};

		const conditionalRowStyles = [
			{
				when: row => !row.isEditable,
				style: {
					color: '#999999'
				},
			},
			{
				when: row => row.isEditable,
				style: {
					color: '#202124'
				},
			},
		];

		const filteredData = this.state.templateListData.filter(
			item => item.name && item.name.toLowerCase().includes(this.state.filterText.toLowerCase()),
		);

		const customStyles = {
			headRow: {
				style: {
					border: 'none',
				},
			},
			headCells: {
				style: {
					color: '#202124',
					fontSize: '14px',
				},
			},
			rows: {
				highlightOnHoverStyle: {
					backgroundColor: 'AliceBlue',
					borderBottomColor: '#FFFFFF',
					borderRadius: '25px',
					outline: '1px solid #FFFFFF',
				},
			},
			pagination: {
				style: {
					border: 'none',
				},
			},
		};

		const columns = [
			{
				cell: () => <FontAwesomeIcon icon={faShareNodes} />,
				width: '1.5rem', // custom width for icon button
				style: {
					borderBottom: '1px solid #FFFFFF',
					marginBottom: '-1px',
				},
			},
			{
				name: 'Name',
				selector: row => row.name,
				sortable: true,
				grow: 2,
				style: {
					fontSize: '14px',
					fontWeight: 500,
				},
			},
			{
				name: 'Owner',
				selector: row => row.owners.filter(roles => roles.isAssigned).map(role => role.name).join(", ") || "",
				sortable: true,
				width: '10rem',

			},
			{
				name: 'Implementer',
				selector: row => row.runners.filter(roles => roles.isAssigned).map(role => role.name).join(", ") || "",
				sortable: true,
				width: '10rem',

			},			
			{
				name: 'Status',
				selector: row => statusFilterOptions[row.status],
				sortable: true,
				width: '10rem',

			},
			{
				name: 'Created',
				selector: row => row.createDate,
				sortable: true,
				width: '10rem',

			},{
				cell: row => <OverlayTrigger trigger="click" placement="right" rootClose placement='left' overlay={this.menuPopup(row)}>
					<Button variant="light" className="btn-circle"><FontAwesomeIcon icon={faEllipsisVertical} /></Button>
				</OverlayTrigger>,
				allowOverflow: true,
				button: true,
				width: '3rem',
			},
		];


		return (	
			<Card className="workspace-card">
				{this.roleModal()}
				{this.newModal()}
				{this.state.openModal === "delete" ? this.deleteModal() : this.state.openModal === "start" ? this.startModal() :
					this.state.openModal === "schedule" ? this.scheduleModal() : this.state.openModal === "share" ? this.shareModal() : null}
				<ShareTemplate templateID={this.state.shareTemplateID} templateName={this.state.shareTemplateName} onClose={() => this.handleCloseShare()}/>
				<Card.Header>
					<div style={{ float: 'left' }}>
						<Row>
							<Col md="auto">
								<b>FlowTemplates</b>
							</Col>
							<Col md="auto">
								<InputGroup>
									<Form.Control
										style={{ minWidth: '20rem' }}
										placeholder="Filter by name"
										size="sm"
										type="text"
										value={this.state.filterText}
										onChange={this.onFilter.bind(this)}
									/>
									<Button size='sm' onClick={() => { this.setState({ filterText: "" }) }} variant="secondary"><FontAwesomeIcon icon={faCircleXmark}/></Button>
								</InputGroup>
							</Col>
							{this.context.user.isAdmin ? 
								<Col md="auto">
									<Form.Check
										id="custom-switch"
										label="Admin"
										type="checkbox"
										checked={this.state.adminFilter}
										onChange={e => { this.handleAdminFilter(e) }}
									/>
								</Col>
							: null }
						</Row>
					</div>
					<div style={{ float: 'right' }}>
						<Row>
							
							<Col md="auto">
								<Button size='sm' onClick={() => { this.activateNewModal() }} id={"btn_add"} variant="primary" disabled={this.state.myRoles.length === 0 }><FontAwesomeIcon icon={faPlusSquare} /> New </Button>
							</Col>
						</Row>


					</div>
				</Card.Header>
				<Card.Body>
					<DataTable
						className="data-table"
						columns={columns}
						data={filteredData}
						customStyles={customStyles}
						progressPending={this.state.isLoadingData}
						progressComponent={this.customLoader()}
						highlightOnHover
						pointerOnHover
						noHeader={true}
						onRowClicked={(row, event) => { if (row.isEditable) this.props.navigate("/FlowTemplateView/" + row.id); }}
						conditionalRowStyles={conditionalRowStyles}
					/>
				</Card.Body>
			</Card >
		)
	}
}

export default withRouter(FlowTemplateList);