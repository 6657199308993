import React from "react";
import UserContext from '../context/UserContext';

import update from 'immutability-helper';

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { Typeahead, Highlighter, Token } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class ShareTemplate extends React.Component {
	static contextType = UserContext;

	state = {
		newShareName: '',
		templateID: 0,
		isModalLoading: false,
		isLoadingRoles: false,
		isLoadingTargetRoles: false,
		rolesForTemplate: [],
		availableTargetRoles: [],
		selectedTargetRoles: [],
		selectedTargetOrg: { orgID: 0 },
		selectedOwner: {roleID:0},
		success: false,
	};

	componentDidUpdate(prevProps) {
		if (prevProps.templateID !== this.props.templateID) {
			this.setState({ rolesForTemplate: [] });
			this.setState({ templateID: this.props.templateID });
			this.setState({ selectedTargetOrg: { orgID: 0 } });
			this.setState({ availableTargetRoles: [] });
			this.setState({selectedOwner: { roleID : 0 } });


			if (this.props.templateID != 0) {
				this.fetchRoles(this.props.templateID);
				this.setState({ success: false })
			} 
		}
		if (prevProps.templateName !== this.props.templateName) {
			this.setState({ newShareName: this.props.templateName });
		}
	}

	fetchRoles(flowTemplateID) {
		this.setState({ isLoadingRoles: true });
		fetch(`api/Template/GetRoles/` + flowTemplateID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ rolesForTemplate: data });
				this.setState({ isLoadingRoles: false });
			})
			.catch(error => {
				//this.setState({ message: error });
				this.setState({ isLoadingRoles: false });
			})
	}

	fetchTargetRoles(orgID) {
		this.setState({ isLoadingTargetRoles: true });
		
		fetch(`api/UserRoles/GetRolesForOrg/` + orgID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.preSelectTargetRoles(data);
				this.setState({ availableTargetRoles: data });
				this.setState({ isLoadingTargetRoles: false });
			})
			.catch(error => {
				//this.setState({ message: error });
				this.setState({ isLoadingTargetRoles: false });
			})
	}

	shareWorkFlow() {
		const templateID = this.state.templateID;
		const newName = this.state.newShareName;
		const targetOrg = this.state.selectedTargetOrg.orgID;
		const templateRoles = this.state.rolesForTemplate.map(role => role.roleID);
		const targetRoles = this.state.selectedTargetRoles;
		const owner = this.state.selectedOwner;

		this.setState({ isModalLoading: true });
		fetch(`api/Template/Share`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ templateID: templateID, newName: newName, targetOrg: targetOrg, templateRoles: templateRoles, targetRoles: targetRoles, ownerID: owner.roleID }) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				if (data === -1) {
					this.setState({ isModalLoading: false });
				} else if (data > 0) {
					this.setState({ success: true });
					this.setState({ isModalLoading: false });
				}
			})
			.catch(error => {
				//this.setState({ message: error });
				this.setState({ isModalLoading: false });
			})
	}


	preSelectTargetRoles(targetRoles) {
		var selectedRoles = [];
		var templateRoles = [];
		this.state.rolesForTemplate.map((role, index) => {
			var selected = targetRoles.find(target => target.name.toLowerCase() === role.roleName.toLowerCase());
			if (selected != null) {
				selectedRoles.push(selected.roleID);
				templateRoles.push({ roleID: role.roleID, roleName: role.roleName, canCreate: false });
			} else {
				selectedRoles.push(-1);
				templateRoles.push({ roleID: role.roleID, roleName: role.roleName, canCreate: true });
			}
		})
		this.setState({ selectedTargetRoles: selectedRoles });
		this.setState({ rolesForTemplate: templateRoles });
	}

	handleTargetOrgChange(e) {
		this.setState({ selectedTargetOrg: e.value });
		this.setState({ selectedOwner: { roleID: 0 } });
		this.setState({ availableTargetRoles: [] });
		this.setState({ selectedTargetRoles: [] });

		this.fetchTargetRoles(e.value.orgID);
	}

	handleTargetRoleChange(e, index) {
		const selectedRole = update(this.state.selectedTargetRoles, { [index]: { $set: e.target.value } });
		this.setState({ selectedTargetRoles: selectedRole });
	}

	availableRoles(index) {
		return (
			<Form.Select size="sm" onChange={(e) => this.handleTargetRoleChange(e, index)} value={this.state.selectedTargetRoles[index]} >
				<option value={0}>&#60;Leave empty&#62;</option>
				<option value={-1} disabled={!this.state.rolesForTemplate[index].canCreate}>&#60;Create&#62;</option>
				{
					this.state.availableTargetRoles.map((role, index) => {
						return (
							<option value={role.roleID} key={'targetRole' + index}>{role.name}</option>
						);
					})
				}
			</Form.Select>
		);
	}

	handleClose() {
		if (this.props.onClose) {
			this.props.onClose();
        }
	}

	roleList() {
		return (
			<div>
				<hr />
				Match roles to recieving organisation
				<Table hover>
					<tbody>
						{
							this.state.rolesForTemplate.map((role, index) => {
								return (
									<tr key={'role' + index}>
										<td>{role.roleName}</td>
										<td>{this.state.availableTargetRoles.length < 1 ? <span>...</span> : this.availableRoles(index)}</td>
									</tr>
								);
							})
						}
					</tbody>
				</Table >
			</div>
		);
	}

	render() {
		var targetOrgs = this.context.user.orgList.filter(org => org.admin && org.orgID != this.context.user.orgID);

		const confirmFooter = (
			<div>
				<Button variant="primary" onClick={() => this.handleClose()} >OK</Button>
			</div>
		);

		const footerContent = (
			<div>
				<Button
					variant="primary"
					onClick={() => { this.shareWorkFlow(); }}
					disabled={this.state.isModalLoading || this.state.isLoadingRoles || this.state.isLoadingTargetRoles || this.state.selectedTargetOrg.orgID === 0 || this.state.newShareName.length === 0 || this.state.selectedOwner.roleID === 0}
				>
					{this.state.isModalLoading ?
						<Spinner
							as="span"
							animation="border"
							size="sm"
							role="status"
							aria-hidden="true"
						/> : ''}
					{' '}Share{' '}
				</Button>{' '}
				<Button variant="secondary" onClick={() => this.handleClose()} disabled={this.state.isModalLoading}>Cancel</Button>
			</div>
		);

		return (
			<Dialog
				header={"Share FlowTemplate - " + this.props.templateName}
				visible={this.state.templateID != 0}
				style={{ width: '50vw' }}
				onHide={() => this.setState({ modalWindow: '' })}
				closable={false}
				footer={this.state.success ? confirmFooter : footerContent}
			>
				{this.state.success ?
					<div className="p-fluid">
						<div className="p-field">
							You have succesfully shared the FlowTemplate
						</div>						
					</div>
					:
					<div className="p-fluid">
						<div className="p-field">
							<label className="p-field-label">Recieving organisation</label>
							<Dropdown
								value={this.state.selectedTargetOrg}
								options={targetOrgs}
								optionLabel="name"
								placeholder="Choose an organisation..."
								disabled={this.state.isModalLoading}
								onChange={(e) => this.handleTargetOrgChange(e)}
								className="p-inputtext-sm w-full md:w-20rem"
							/>
						</div>
						<hr />
						<div className="p-field">
							<label className="p-field-label">Name</label>
							<InputText
								value={this.state.newShareName}
								placeholder="Enter name"
								onChange={e => this.setState({ newShareName: e.target.value })}
								maxLength="64"
							/>
						</div>
						<div className="p-field">
							<label className="p-field-label">Owner</label>
							<Dropdown
								value={this.state.selectedOwner}
								options={this.state.availableTargetRoles}
								optionLabel="name"
								placeholder="Choose a role in the target organisation..."
								className="p-inputtext-sm w-full md:w-20rem"
								onChange={e => this.setState({ selectedOwner: e.value })}
								disabled={this.state.selectedTargetOrg.orgID === 0}
							/>
						</div>
						{this.roleList()}
					</div>
				}
			</Dialog>

			
		);
	}
}
/*<Modal show={this.state.templateID != 0} style={{ zIndex: '4077' }} centered disabled={this.state.isLoadingRoles}>
				<ModalHeader>Share FlowTemplate{this.state.isLoadingRoles ? <Spinner
					as="span"
					animation="border"
					size="sm"
					role="status"
					aria-hidden="true"
				/> : null} </ModalHeader>
				{this.state.success?<ModalBody>You have succesfully shared the FlowTemplate</ModalBody>:
					<ModalBody>
						<div className="p-fluid">
							<div className="p-field">
								<label className="p-field-label">Recieving organisation</label>
								<Dropdown
									
									options={targetOrgs}
									optionLabel="name"
									placeholder="Choose an organisation..."
									className="p-inputtext-sm w-full md:w-20rem"
								/>
							</div>
							<div className="p-field">
								<label className="p-field-label">Recieving organisation</label>
								<Dropdown
									
									options={targetOrgs}
									optionLabel="name"
									placeholder="Choose an organisation..."
									className="p-inputtext-sm w-full md:w-20rem"
								/>
							</div>
						</div>
						<Form.Label>Recieving organisation</Form.Label>
						 
						<Typeahead
							clearButton
							key={"org-" + (new Date().getTime())}
							name="targetOrgs"
							positionFixed
							id="targetOrgs"
							labelKey="name"
							flip
							options={targetOrgs}
							selected={this.state.selectedTargetOrg}
							onChange={this.handleTargetOrgChange.bind(this)}
							placeholder="Choose an organisation..."
							disabled={this.state.isModalLoading}
						/>
						<hr />
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								name="TemplateName"
								value={this.state.newShareName}
								onChange={e => this.setState({ newShareName: e.target.value })}
								maxLength="64"
							/>
						</Form.Group>
						{this.roleList()}
					</ModalBody>
				}
				{this.state.success ? <ModalFooter><Button variant="primary" onClick={() => this.handleClose()} >OK</Button></ModalFooter> :
					<ModalFooter>
						<Button variant="primary" onClick={() => { this.shareWorkFlow(); }} disabled={this.state.isModalLoading || this.state.isLoadingRoles || this.state.isLoadingTargetRoles || this.state.selectedTargetOrg.length === 0 || this.state.newShareName.length === 0}>
							{this.state.isModalLoading ?
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/> : ''}
							{' '}Share{' '}
						</Button>{' '}
						<Button variant="secondary" onClick={() => this.handleClose()} disabled={this.state.isModalLoading}>Cancel</Button>
					</ModalFooter>
				}
			</Modal>*/
export default (ShareTemplate);