import React from "react";
import { withRouter } from "./react/WithRouter";

import ExcelJS from "exceljs";
import saveAs from "file-saver";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';

import UserContext from '../context/UserContext';

class InternalControl extends React.Component {
	static contextType = UserContext;

	riskScale = [1, 2, 3, 4, 5];

	probabilityScale = [{ id: 1, text: '1 Very unlikely' }, { id: 2, text: '2 Unlikely' }, { id: 3, text: '3 Possible' }, { id: 4, text: '4 Likely' }, { id: 5, text: '5 Very likely' }];
	consequenceScale = [{ id: 1, text: '1 Negligible' }, { id: 2, text: '2 Minor' }, { id: 3, text: '3 Moderate' }, { id: 4, text: '4 Significant' }, { id: 5, text: '5 Severe' }];
	riskScale = [
		{ id: 1, text: 'Low', low: 0, high: 3, severity: 'success' },
		{ id: 2, text: 'Moderate', low: 4, high: 7,severity: 'warning', color: '#FFEE00' },
		{ id: 3, text: 'High', low: 8, high: 14,severity: 'warning' },
		{ id: 4, text: 'Extreme', low: 15, high: 25, severity: 'danger' }
	];

	controlTypeScale = [{ id: 1, text: 'Preventive' }, { id: 2, text: 'Detective' }, { id: 3, text: 'Corrective' }];

	columns = [
		{ caption: 'FlowTemplate', order:1, isVisible: false, width: 150, data: "templateName" },
		{ caption: 'WorkFlow', order: 2, isVisible: true, width: 150, data: "flowName" },
		{ caption: 'WorkTask', order: 3, isVisible: true, width: 150, data: "taskName" },
		{ caption: 'Task Description', order: 4, isVisible: false, width: 250, data: "description" },
		{ caption: 'Responsible', order: 5, isVisible: true, width: 100, data: "controlRole" },
		{ caption: 'Control Date', order: 6, isVisible: true, width: 100, data: "controlDate" },
		{ caption: 'Performed By', order: 7, isVisible: true, width: 150, data: "controlName" },
		{ caption: 'Status', order: 8, isVisible: true, width: 150, data: "status", color: "statusColor"  },
		{ caption: 'Comment', order: 9, isVisible: false, width: 250, data: "closingComment" },
		{ caption: 'Control Type', order: 10, isVisible: true, width: 150, data: "controlTypeText"  },
		{ caption: 'Risk Name', order: 11, isVisible: true, width: 150, data: "riskName" },
		{ caption: 'Risk Description', order: 12, isVisible: false, width: 250, data: "riskDescription" },
		{ caption: 'Probability', order: 13, isVisible: true, width: 150, data: "probabilityText" },
		{ caption: 'Consequence', order: 14, isVisible: true, width: 150, data: "consequenceText" },
		{ caption: 'Risk', order: 15, isVisible: true, width: 150, data: "riskText" },
		
	];
	 
	state = {
		isLoadingData: true,
		controlList: [],
		filteredData: [],
		visibleColumns: [],
		filters: {},
	};

	constructor(props) {
		super(props);
		this.dataTableRef = React.createRef();

	}

	componentDidMount() {
		this.initFilters();
		this.initVisibleColumns();
		this.fetchControlList();
	}

	fetchControlList() {
		this.setState({ isLoadingData: true });
		
		fetch(`api/InternalControl/ControlList/`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				data.map(task => {
					task.controlDate = new Date(task.controlDate);
	
					if (task.controlType > 0) {
						task.controlTypeText = this.controlTypeScale[task.controlType - 1].text;
					} else {
						task.controlTypeText = "";
					}

					if (task.probability > 0) {
						task.probabilityText = this.probabilityScale[task.probability - 1].text;
					} else {
						task.probabilityText = "";
					}
					if (task.consequence > 0) {
						task.consequenceText = this.consequenceScale[task.consequence - 1].text;
					} else {
						task.consequenceText = "";
					}

					task.risk = task.consequence * task.probability;
					if (task.probability > 0 && task.consequence > 0) {
						task.riskText = task.risk + " " + this.riskScale.find((element) => task.risk >= element.low && task.risk <= element.high).text
					} else {
						task.riskText = "";
					}
					
					return task;
				})
				this.setState({ controlList: data });
				this.setState({ filteredData: data });
				this.setState({ isLoadingData: false });
			})
			.catch(error => {
				this.setState({ isLoadingData: false });
			})
	}

	initFilters() {
		var filterDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

		this.setState(
			{
				filters: {
					templateName: { value: null, matchMode: FilterMatchMode.IN },
					flowName: { value: null, matchMode: FilterMatchMode.IN },
					taskName: { value: null, matchMode: FilterMatchMode.IN },
					controlRole: { value: null, matchMode: FilterMatchMode.IN },
					controlDate: { operator: FilterOperator.AND, constraints: [{ value: filterDate, matchMode: FilterMatchMode.DATE_AFTER }] },
					controlName: { value: null, matchMode: FilterMatchMode.IN },
					status: { value: null, matchMode: FilterMatchMode.IN },
					controlTypeText: { value: null, matchMode: FilterMatchMode.IN },
					riskName: { value: null, matchMode: FilterMatchMode.IN },
					probabilityText: { value: null, matchMode: FilterMatchMode.IN },
					consequenceText: { value: null, matchMode: FilterMatchMode.IN },
					riskText: { value: null, matchMode: FilterMatchMode.IN },
				}
			}
		);
	}

	initVisibleColumns() {
		var newColumns = this.columns.filter((column) => column.isVisible === true);
		this.setState({ visibleColumns: newColumns });
	}

	templateFilterTemplate(options) {
		const templates = [...new Set(this.state.controlList.map(control => control.templateName))];
		return <MultiSelect value={options.value} options={templates} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	}

	flowFilterTemplate(options) {
		const flows = [...new Set(this.state.controlList.map(control => control.flowName))];
		return <MultiSelect value={options.value} options={flows} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	}

	taskFilterTemplate = (options) => {
		const task = [...new Set(this.state.controlList.map(control => control.taskName))];
		return <MultiSelect value={options.value} options={task} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	}

	roleFilterTemplate = (options) => {
		const role = [...new Set(this.state.controlList.map(control => control.controlRole))];
		return <MultiSelect value={options.value} options={role} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	}

	dateFilterTemplate  (options) {
		return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="yy-mm-dd" placeholder="yyyy-mm-dd" mask="9999-99-99" />;
	}

	nameFilterTemplate = (options) => {
		const names = [...new Set(this.state.controlList.map(control => control.controlName))];
		return <MultiSelect value={options.value} options={names} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	}


	statusFilterTemplate = (options) => {
		const status = [...new Set(this.state.controlList.map(control => control.status))];
		return <MultiSelect value={options.value} options={status} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	};

	typeFilterTemplate = (options) => {
		const type = [...new Set(this.state.controlList.map(control => control.controlTypeText))];
		return <MultiSelect value={options.value} options={type} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	};

	riskNameFilterTemplate = (options) => {
		const risk = [...new Set(this.state.controlList.map(control => control.riskName))];
		return <MultiSelect value={options.value} options={risk} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	};

	probabilityFilterTemplate = (options) => {
		const probability = [...new Set(this.state.controlList.map(control => control.probabilityText))];
		probability.sort((a, b) => parseInt(a) > parseInt(b));
		return <MultiSelect value={options.value} options={probability} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	};

	consequenceFilterTemplate = (options) => {
		
		const consequence = [...new Set(this.state.controlList.map(control => control.consequenceText))];
		consequence.sort((a, b) => parseInt(a) > parseInt(b));
		return <MultiSelect value={options.value} options={consequence} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	};

	riskFilterTemplate = (options) => {
		const risk = [...new Set(this.state.controlList.map(control => control.riskText))];
		risk.sort((a, b) => parseInt(a) > parseInt(b));
		return <MultiSelect value={options.value} options={risk} onChange={(e) => options.filterCallback(e.value)} placeholder="Any" className="p-column-filter" />;
	};

	dateBodyTemplate(rowData) {
		return rowData.controlDate.toLocaleDateString('sv-SE', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		});
	}

	excelExport(list) {
		var ExcelJSWorkbook = new ExcelJS.Workbook();
		var worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");

		worksheet.mergeCells("A1:I1");

		//Writer header
		worksheet.getRow(1).height = 25;
		const customCell = worksheet.getCell("A1");
		customCell.font = {
			bold: true,
			size: 20,
		};

		customCell.value = "Internal Control for " + this.context.user.organisation;

		var headerRow = worksheet.addRow();
		worksheet.getRow(2).font = { bold: true };

		//Set column headers
		for (let i = 0; i < this.state.visibleColumns.length; i++) {
			let currentColumnWidth = this.state.visibleColumns[i].width;// DataGrid.option().columns[i].width;
			worksheet.getColumn(i + 1).width = currentColumnWidth !== undefined ? currentColumnWidth / 6 : 20;
			var cell = headerRow.getCell(i + 1);
			
			cell.value = this.state.visibleColumns[i].caption;
		}

		worksheet.autoFilter = {
			from: {
				row: 2,
				column: 1
			},
			to: {
				row: 2,
				column: this.state.visibleColumns.length
			}
		};

		worksheet.views = [{ state: "frozen", ySplit: 2 }];

		worksheet.properties.outlineProperties = {
			summaryBelow: false,
			summaryRight: false
		};

		//Fill Data
		for (let i = 0; i < list.length; i++) {
			var dataRow = worksheet.addRow();
			dataRow.outlineLevel = 1;
			for (let j = 0; j < this.state.visibleColumns.length; j++) {
				var cell = dataRow.getCell(j + 1);
				var fieldName = this.state.visibleColumns[j].data;
				cell.value = list[i][fieldName];
				
				if (list[i][fieldName] instanceof Date) {
					cell.numFmt = 'yyyy-mm-dd';
				}
				 

				if (this.state.visibleColumns[j].color) { 
					var cellColor = list[i][this.state.visibleColumns[j].color].substring(1);
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: cellColor },
					};
				}
			}		
		}

		//Save excel file	
		ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
			saveAs(
				new Blob([buffer], { type: "application/octet-stream" }),
				'InternalControl' + '_export_' + new Date().getTime()+'.xlsx'
			);
		});
	}

	riskTemplate(rowData, props) {
		if (rowData.risk > 0) {
			var riskTemp = this.riskScale.find((element) => rowData.risk >= element.low && rowData.risk <= element.high)
			var style = { width: "100%" };
			if (riskTemp.color != null) {
				style = { backgroundColor: "#FFEE00", width: "100%" };
			}
			return (
				<Tag style={style} severity={riskTemp.severity} value={rowData.riskText}></Tag>
			);
		} else {
			return (
				<div></div>
			);
		}

	}

	probabilityTemplate(rowData, props) {
		if (rowData.probability > 0) {
			return (
				<Tag style={{ width: "100%", border: "1px solid black", backgroundColor: "white", color: "black" }} value={rowData.probabilityText}></Tag>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

	consequenceTemplate(rowData, props) {
		if (rowData.consequence > 0) {
			return (
				<Tag style={{ width: "100%", border: "1px solid black", backgroundColor: "white", color: "black" }} value={rowData.consequenceText}></Tag>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

	clearFilter() {
		this.initFilters();
	}

	onColumnToggle (event) {
		var selectedColumns = event.value;
		selectedColumns.sort((a,b) => a.order - b.order );
		this.setState({ visibleColumns: selectedColumns });
	}

	render() {
		const leftTools = (
			<div style={{ textAlign: 'middle' }}>
				<Button className="p-button-sm" type="button" icon="pi pi-filter-slash" label="Reset filter" outlined="true" onClick={this.clearFilter.bind(this)} /> &nbsp;
				<MultiSelect value={this.state.visibleColumns} options={this.columns} optionLabel="caption" onChange={this.onColumnToggle.bind(this)}
					className="w-full sm:w-13rem" maxSelectedLabels={0} selectedItemsLabel='Select visible columns' placeholder='Select visible columns'
					style={{ fontSize: '0.875rem' }}
				/>
			</div>
		);

		const rightTools = (
			<Button className="p-button-sm" label="Export to Excel" onClick={this.excelExport.bind(this, this.state.filteredData)} />
		);

		const toolbar = (
			<Toolbar className="mb-0" start={leftTools} end={rightTools}></Toolbar>
		);

		return (
			<div style={{ width: '100%', overflow: 'auto', height: '100%' }}>
				<DataTable
					loading={this.state.isLoadingData}
					ref={this.dataTableRef}
					value={this.state.controlList}
					tableStyle={{ minWidth: '50rem', width: '100%' }}
					size="small"
					removableSort
					header={toolbar}
					scrollable
					scrollHeight="flex"
					resizableColumns showGridlines
					emptyMessage="No WorkTasks found."
					selectionMode="single"
					filters={this.state.filters}
					filterDisplay="menu"
					onValueChange={filteredData => this.setState({ filteredData: filteredData })}
				>
					{this.state.visibleColumns.some(col => col.caption === 'FlowTemplate') ?
						< Column
							field="templateName"
							header="FlowTemplate"
							sortable
							style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '7rem', minWidth: '11rem' }}
							filter
							filterElement={this.templateFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'WorkFlow') ?
						<Column
							field="flowName"
							header="WorkFlow"
							sortable
							style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '7rem', minWidth: '9rem' }}
							filter 
							filterElement={this.flowFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'WorkTask') ?
						<Column
							field="taskName"
							header="WorkTask"
							sortable
							style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '7rem', minWidth: '9rem' }}
							filter
							filterElement={this.taskFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Task Description') ?
						<Column
							field="description"
							header="Description"
							style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '20rem', minWidth: '15rem' }}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Responsible') ?
						<Column
							field="controlRole"
							header="Responsible"
							sortable
							style={{ minWidth: '10rem' }}
							filter
							filterElement={this.roleFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Control Date') ?
						<Column
							field="controlDate"
							header="Control Date"
							sortable
							dataType="date"
							body={this.dateBodyTemplate}
							style={{ minWidth: '11rem' }}
							filter 
							filterElement={this.dateFilterTemplate.bind(this)} 
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Performed By') ?
						<Column
							field="controlName"
							header="Performed By"
							sortable
							style={{ minWidth: '11rem' }}
							filter
							filterElement={this.nameFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Status') ?
						<Column
							field="status"
							header="Status"
							sortable
							style={{ minWidth: '10rem' }}
							filter
							filterElement={this.statusFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Comment') ?
						<Column
							field="closingComment"
							header="Comment"
							style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '20rem', minWidth: '15rem' }}
						>
						</Column>
						: null
					}

					{this.state.visibleColumns.some(col => col.caption === 'Control Type') ?
						<Column
							field="controlTypeText"
							header="Control Type"
							sortable
							style={{ minWidth: '10rem' }}
							filter
							filterElement={this.typeFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Risk Name') ?
						<Column
							field="riskName"
							header="Risk Name"
							sortable
							style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '7rem', minWidth: '9rem' }}
							filter
							filterElement={this.riskNameFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Risk Description') ?
						<Column
							field="riskDescription"
							header="Description"
							style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexBasis: '20rem', minWidth: '15rem' }}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Probability') ?
						<Column
							field="probabilityText"
							header="Probability"
							body={this.probabilityTemplate.bind(this)}
							sortable
							style={{ minWidth: '10rem' }}
							filter
							filterElement={this.probabilityFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Consequence') ?
						<Column
							field="consequenceText"
							header="Consequence"
							body={this.consequenceTemplate.bind(this)}
							sortable
							style={{ minWidth: '11rem' }}
							filter
							filterElement={this.consequenceFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
					{this.state.visibleColumns.some(col => col.caption === 'Risk') ?
						<Column
							field="riskText"
							header="Risk"
							body={this.riskTemplate.bind(this)}
							sortable
							style={{ minWidth: '7rem' }}
							filter
							filterElement={this.riskFilterTemplate.bind(this)}
							showFilterMatchModes={false}
						>
						</Column>
						: null
					}
				</DataTable>
			</div>
		);
	}
}

export default withRouter(InternalControl);