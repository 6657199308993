import React from "react";
import { Link } from 'react-router-dom';

import { withRouter } from "./react/WithRouter";

import DataTable from 'react-data-table-component';

import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Spinner from 'react-bootstrap/Spinner';

import { faEllipsisVertical, faShareNodes, faPencil, faTrashCan, faCirclePlay, faCalendarDays, faCopy, faRecycle, faPlusSquare, faCircleXmark, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../Layout.css";

class WorkFlowList extends React.Component {

	state = {
		filterText: "",
		flowListData: [],
		deleteFlowID: 0,
		modal: "",
		isModalLoading: false,
		isLoadingData: false,
	};

	componentDidMount() {
		this.fetchWorkFlows();
	}

	fetchWorkFlows() {
		this.setState({ isLoadingData: true });
		fetch(`api/WorkFlow/GetFlowList/`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ flowListData: data });
				this.setState({ isLoadingData: false });

			})
			.catch(error => {
				//this.setState({ message: error });
				this.setState({ isLoadingData: false });
			})
	}

	fetchPublish(flowID) {
		fetch(`api/WorkFlow/PublishWorkFlow/` + flowID, { method: 'PUT', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {

					throw (data.error);
				}
				this.fetchWorkFlows();
			})
			.catch(error => {
				//this.setState({ message: error });
			})
	}

	fetchDelete(flowID) {
		fetch(`api/WorkFlow/DeleteWorkFlow/` + flowID, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {

					throw (data.error);
				}
				this.setState({ modal: '' });
				this.setState({ deleteFlowID: 0 });
				this.setState({ isModalLoading: false });
				this.fetchWorkFlows();
			})
			.catch(error => {
				//this.setState({ message: error });
			})
	}

	deleteWorkFlow(id) {
		this.setState({ deleteFlowID: id });
		this.setState({ modal: "delete" });
	}

	onFilter(e) {
		this.setState({ filterText: e.target.value });
	}

	menuPopup(row) {
		return (
			<Popover id="popover-basic" className="popup-menu shadow ">
				<Popover.Body>
					<ListGroup>
						{row.editorID === 0 ?
							<ListGroup.Item onClick={() => document.body.click()}>
								<Link to={'/WorkFlowView/' + row.id}>
									<FontAwesomeIcon icon={faEye} size="sm" /> View
								</Link>
							</ListGroup.Item>
							:
							<ListGroup.Item onClick={() => document.body.click()}>
								<Link to={'/WorkFlowView/' + row.id}>
									<FontAwesomeIcon icon={faPencil} size="sm" /> Edit
								</Link>
							</ListGroup.Item>
						}
						{!row.editable || row.editorID === 0 ? null :
							<ListGroup.Item onClick={() => {document.body.click(); this.fetchPublish(row.id) }} >
								<FontAwesomeIcon icon={faPaperPlane} size="sm" /> Publish
							</ListGroup.Item>
						}
						{!row.editable || row.editorID != 0 ? null :
							<ListGroup.Item onClick={() => { document.body.click(); this.deleteWorkFlow(row.id) }} >
								<FontAwesomeIcon icon={faTrashCan} /> Delete
							</ListGroup.Item>
						}
					</ListGroup>
				</Popover.Body>
			</Popover>
		);

	}

	modalDelete() {
		return (
			<Modal show={true} style={{ zIndex: '4077' }} centered>
				<ModalHeader>Delete WorkFlow</ModalHeader>
				<ModalBody>
					Do you really want to delete <b>{this.state.flowListData.find(item => item.id === this.state.deleteFlowID).name}</b>?<br /> This operation cannot be undone.
				</ModalBody>
				<ModalFooter>
					<Button variant="primary" onClick={() => { this.fetchDelete(this.state.deleteFlowID); this.setState({ isModalLoading: true }); }}>
						{this.state.isModalLoading ? <Spinner animation="border"
							as="span"
							size="sm"
							role="status"
							aria-hidden="true" /> : ''}
						{' '}Delete{' '}
					</Button>{' '}
					<Button variant="secondary" onClick={() => { this.setState({ modal: '' }) }} disabled={this.state.isModalLoading}>Cancel</Button>
				</ModalFooter>
			</Modal>
		);
    }

	statusField(row) {
		if (row.editorID !== 0) {
			const popoverDelay = { show: 1000, hide: 250 };
			const editorPopover = ( 
				<Popover>
					<Popover.Body>
						Editor: {row.editorName}
					</Popover.Body>
				</Popover>
			);
			return (
				<OverlayTrigger placement="auto" overlay={editorPopover} delay={popoverDelay} trigger = { ["hover", "hover"] } >
					<b>Editing</b>
				</OverlayTrigger >
			);
		} else {
			return row.statusText;
		}
    }

	customLoader() {
		return (
			<div style={{ padding: '24px' }}>
				<Spinner animation="border" />
				<div>Loading...</div>
			</div>
		);
	}

	displayOwner(workFlow) {
		var owners = "";
		if (workFlow.editorID === 0) {
			owners = workFlow.ownerRoles.map(role => role.roleName).join(", ") || "";
		} else {
			owners = workFlow.editorName;
		}
		return owners;
	}

	render() {
		const filteredData = this.state.flowListData.filter(
			item => item.name && item.name.toLowerCase().includes(this.state.filterText.toLowerCase()),
		);

		const conditionalRowStyles = [
			{
				when: row => row.editorID !== 0,
				style: {
					backgroundColor: '#FFFF9E',
				},
			},
		];

		const customStyles = {
			headRow: {
				style: {
					border: 'none',
				},
			},
			headCells: {
				style: {
					color: '#202124',
					fontSize: '14px',
				},
			},
			rows: {
				highlightOnHoverStyle: {
					backgroundColor: 'AliceBlue',
					borderBottomColor: '#FFFFFF',
					borderRadius: '25px',
					outline: '1px solid #FFFFFF',
				},
			},
		};

		const columns = [
			{
				cell: (row) => <FontAwesomeIcon icon={faShareNodes} style={{ backgroundColor: row.statusColor, color: 'white', padding: '0.3rem 0.35rem', borderRadius: '5px' }} />,
				width: '2.75rem', // custom width for icon button
				style: {
					borderBottom: '1px solid #FFFFFF',
					marginBottom: '-1px',
				},
			},
			{
				name: 'Name',
				selector: row => row.name,
				sortable: true,
				grow: 2,
				style: {
					color: '#202124',
					fontSize: '14px',
					fontWeight: 500,
				},
			},
			{
				name: 'Status',
				selector: row => this.statusField(row),
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				name: 'Scheduled',
				selector: row => row.startDate,
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				name: 'Deadline',
				selector: row => row.deadline,
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				name: 'Owner',
				selector: row => this.displayOwner(row),
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				cell: row => <OverlayTrigger trigger="click" placement="right" rootClose placement='left' overlay={this.menuPopup(row)}>
					<Button variant="light" className="btn-circle"><FontAwesomeIcon icon={faEllipsisVertical} /></Button>
				</OverlayTrigger>,
				allowOverflow: true,
				button: true,
				width: '3rem',
			},
		];


		return (
			<Card className="workspace-card">
				{this.state.modal === 'delete' ? this.modalDelete():null}
				<Card.Header>
					<div style={{ float: 'left' }}>
						<Row>
							<Col md="auto">
								<b>Active WorkFlows</b>
							</Col>
							<Col md="auto">
								<InputGroup>
									<Form.Control
										style={{ minWidth: '20rem' }}
										placeholder="Filter by name"
										size="sm"
										type="text"
										value={this.state.filterText}
										onChange={this.onFilter.bind(this)}
									/>
									<Button size='sm' onClick={() => { this.setState({ filterText: "" }) }} variant="secondary"><FontAwesomeIcon icon={faCircleXmark} /></Button>
								</InputGroup>
							</Col>
						</Row>
					</div>
				</Card.Header>
				<Card.Body>
					<DataTable
						className="data-table"
						columns={columns}
						data={filteredData}
						customStyles={customStyles}
						conditionalRowStyles={conditionalRowStyles}
						progressPending={this.state.isLoadingData}
						progressComponent={this.customLoader()}
						highlightOnHover
						pointerOnHover
						noHeader={true}
						onRowClicked={(row, event) => this.props.navigate("/WorkFlowView/" + row.id)}
					/>
				</Card.Body>
			</Card >
		)
	}
}

export default withRouter(WorkFlowList);