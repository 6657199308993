import React from "react";

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';

import update from 'immutability-helper';

const MAX_COMMENT_CHARS = 255;

const CLOSE_TYPES = ['Save', 'Close', 'Close incomplete', 'No action'];

class GanttCloseTask extends React.Component {

    state = { 
        task: {},
        subTasks: [],
        validate: false,
        commentText: '',
        reasonText: '',
        availableCloseTypes: [1, 2, 3, 4],
        activeCloseType: 1,
    }

    componentDidMount() {
        if (this.props.task) {
            this.setState({ task: this.props.task });
            this.setState({ subTasks: this.props.task.subTasks }); //Make a copy of subtasks so that it is not changed unless saved
            var activeCloseType = 1
            if (this.props.task.subTasks && this.props.task.subTasks.length > 0 && !this.IsSubTaskReady(this.props.task.subTasks)) {
                activeCloseType = 0;
            }
            this.setState({ activeCloseType: activeCloseType });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.task && prevProps.task !== this.props.task) {
            this.setState({ task: this.props.task });
            this.setState({ subTasks: this.props.task.subTasks }); //Make a copy of subtasks so that it is not changed unless saved 
            var activeCloseType = 1
            if (this.props.task.subTasks && this.props.task.subTasks.length > 0 && !this.IsSubTaskReady(this.props.task.subTasks)) {
                activeCloseType = 0;
            }
            this.setState({ activeCloseType: activeCloseType });
            this.setState({ commentText: '' });
            this.setState({ reasonText: '' });
            this.setState({ validate: false });

        }
    }

    evaluateCloseButton() {
        var availableCloseTypes = [1, 2, 3, 4];
        if (this.state.subTasks && this.state.subTasks !== null && this.state.subTasks.length > 0) {
            if (this.unfinishedSubTasks(this.state.subTasks) === this.state.subTasks.length) {
                availableCloseTypes = [0, 1, 2, 3];
            } else {
                availableCloseTypes = [0, 1, 2];
            }
        } else if (this.state.task.progressStatus > 1) {
            availableCloseTypes = [1, 2];
        }
        return availableCloseTypes;
    }

    unfinishedSubTasks(subTasks) {
        var unfinished = 0;
        if (subTasks != null) {
            unfinished = subTasks.reduce((acc, obj) => {
                if (obj.isChecked === false)
                    return acc + 1;
                return acc;
            }, 0)
        }
        return unfinished;
    }

    IsSubTaskReady(subTasks) {
        var isReady = false;
        if (subTasks != null) {
            isReady = subTasks.reduce((acc, obj) => {
                if (obj.isChecked === false) acc = false;
                return acc;
            }, true);
        }
        return isReady;
    }

    handleSubTaskChange(index, e) {
        const newSubTasks = update(this.state.subTasks, { [index]: { isChecked: { $set: e.target.checked } } });
        this.setState({ subTasks: newSubTasks })

        const isReady = this.IsSubTaskReady(newSubTasks);
        ;
        if (this.state.activeCloseType === 0 && isReady && this.state.task.isStarted) {
            this.setState({ activeCloseType: 1 });
        } else if (this.state.activeCloseType === 1 && !isReady) {
            this.setState({ activeCloseType: 0 });
        }

    }

    handleCloseTask() {
        if (this.state.commentText === "" && (this.state.task.closingComment || this.state.activeCloseType === 2 || this.state.activeCloseType === 3) && this.state.activeCloseType != 0) {
            this.setState({ validate: true });
            return;
        }

        this.setState({ isModalLoading: true });
        if (this.props.onClose) {
            this.props.onClose({ taskID: this.state.task.taskID, closeType: this.state.activeCloseType, comment: this.state.commentText, reason: this.state.reasonText, subTasks: this.state.subTasks });
        }
    }

    render() {
        const unfinishedSubTasks = this.unfinishedSubTasks(this.state.subTasks)
        const hasUncheckedSubTasks = unfinishedSubTasks > 0;
        const availableCloseTypes = this.evaluateCloseButton();
        const requireComment = this.state.task.closingComment || this.state.task.internalControl || this.state.activeCloseType === 2 || this.state.activeCloseType === 3;

        var canClose = false;
        var commentPlaceholder = "Enter Comment";

        if (this.state.activeCloseType === 0) {
            canClose = true;
        }else  if (this.state.activeCloseType === 1) {
            if ((this.state.task.closingComment || this.state.task.internalControl)) {
                commentPlaceholder = "This task require a closing comment";
                canClose = this.state.task.isStarted && !hasUncheckedSubTasks && this.state.commentText.length > 0;//if it is started and don't have unchecked subtasks and has a comment      
            } else {
                canClose = this.state.task.isStarted && !hasUncheckedSubTasks;//if it is started and don't have unchecked subtasks      
            }
        } else if (this.state.activeCloseType === 2 || this.state.activeCloseType === 3) {
            commentPlaceholder = "Closing tasks in an incomplete state require a closing comment";
            canClose = this.state.commentText.length > 0; 
        }
        canClose = canClose && (this.props.task.isResponsible || this.state.reasonText.length > 0 || this.state.activeCloseType === 0) //For closing (not just saving subtasks) you need to be owner or leave a comment as reason 
        canClose = canClose && !this.props.isLoading; //if loading you can't press button no matter what

        if (this.state.task.isClosed) {
            return (<div>WorkTask was {this.state.task.status.toLowerCase()} </div>);
        }
        return (    
            <div>
                {this.props.task.subTasks && this.state.subTasks.length > 0 ?
                    <Card bg='light' className='mb-3'>
                        <Card.Header><b>Subtasks</b></Card.Header>
                        <Card.Body>
                            {this.state.subTasks.map((value, index) => {
                                return (
                                    <Form.Check
                                        key={index + "-subtask"}
                                        type="checkbox"
                                        id={index + "-subtask"}
                                        label={value.name}
                                        checked={value.isChecked}
                                        onChange={this.handleSubTaskChange.bind(this, index)}
                                    />
                                );
                            })}
                        </Card.Body>
                    </Card>
                    : ''}
                {this.state.activeCloseType === 0 ? '' : //Don't show comments if we are only saving subtasks as we will not save the comments
                    <div>
                        { this.props.task.isResponsible ? null :
                        <Form.Group>
                            <Form.Label>Reason<span style={{ color: 'red' }} > (required)</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                maxLength={MAX_COMMENT_CHARS}
                                placeholder="Enter reason for handling WorkTask assigned to other role"
                                disabled={this.props.isLoading}
                                value={this.state.reasonText}
                                onChange={e => this.setState({ reasonText: e.target.value })}
                            />
                            <Form.Text className="text-muted">
                                Characters Left: {MAX_COMMENT_CHARS - this.state.reasonText.length}
                            </Form.Text>
                            </Form.Group>
                        }
                        <Form.Group>
                            <Form.Label>Closing Comment {!requireComment ? null : <span style={{ color: 'red' }} >(required)</span>}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                maxLength={MAX_COMMENT_CHARS}
                                placeholder={commentPlaceholder}
                                disabled={this.props.isLoading}
                                value={this.state.commentText}
                                onChange={e => this.setState({ commentText: e.target.value })}
                                required={this.state.task.closingComment || this.state.activeCloseType === 2 || this.state.activeCloseType === 3}
                            />
                            <Form.Text className="text-muted">
                                Characters Left: {MAX_COMMENT_CHARS - this.state.commentText.length}
                            </Form.Text>
                        </Form.Group>

                    </div>
                }
                <Dropdown as={ButtonGroup} align="end">
                    <Button
                        variant="primary"
                        onClick={() => { this.handleCloseTask() }}
                        disabled={!canClose}
                    >
                        {this.props.isLoading ? <Spinner animation="border"
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true" /> : ''}
                        {' '} {CLOSE_TYPES[this.state.activeCloseType]} {' '}
                    </Button>

                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                    <Dropdown.Menu>
                        {availableCloseTypes.map((value) => { return (<Dropdown.Item key={value} onClick={() => { this.setState({ activeCloseType: value }) }}>{CLOSE_TYPES[value]}</Dropdown.Item>) })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

}

export default (GanttCloseTask);