import * as React from 'react';

export const WhiteLogoLarge = () => {
	return (
		<svg viewBox="3.181 10.327 835.726 200.255" height = "45px" xmlns="<http://www.w3.org/2000/svg>">
			<polygon fill="#FFFFFF" opacity="0.3" points="49.959,135.622 89.361,160.213 201.372,160.143 162.033,135.6 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="96.726,60.609 136.137,85.25 248.212,85.224 128.192,10.327 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="89.361,160.213 34.606,160.226 3.181,210.582 170.01,210.539 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="170.01,210.539 201.434,160.186 89.361,160.213 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="136.137,85.25 81.383,85.27 49.959,135.622 216.787,135.581 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="216.787,135.581 248.212,85.224 136.137,85.25 " />
			<g fill="#FFFFFF" opacity="0.3">
				<path d="M331.529,208.744h-10.498l-9.86-26.188l-8.164-24.917l-8.163,25.021l-9.755,26.083h-10.39l-26.931-74.32
		h13.253l18.872,57.146l19.191-57.146h7.633l19.615,57.146l18.448-57.146h13.252L331.529,208.744z"/>
				<path d="M404.729,163.104c5.054,5.194,7.579,11.679,7.579,19.453c0,7.849-2.488,14.332-7.473,19.455
		c-4.982,5.124-11.539,7.686-19.667,7.686c-8.057,0-14.562-2.562-19.509-7.686c-4.947-5.123-7.422-11.606-7.422-19.455
		c0-7.845,2.491-14.346,7.477-19.508c4.98-5.16,11.465-7.739,19.454-7.739C393.156,155.31,399.675,157.905,404.729,163.104z
		 M373.559,170.363c-2.934,3.324-4.4,7.387-4.4,12.193c0,4.805,1.447,8.837,4.347,12.087c2.896,3.25,6.784,4.874,11.662,4.874
		c4.877,0,8.783-1.624,11.716-4.874c2.933-3.25,4.399-7.282,4.399-12.087c0-4.735-1.519-8.783-4.558-12.14
		c-3.04-3.358-6.891-5.033-11.557-5.033C380.361,165.384,376.491,167.039,373.559,170.363z"/>
				<path d="M434.04,156.363l0.739,6.894c3.039-5.368,8.164-8.053,15.374-8.053c5.303,0,9.612,1.481,12.936,4.449
		l-4.984,9.546c-2.614-2.122-5.687-3.179-9.22-3.179c-4.029,0-7.391,1.232-10.072,3.702c-2.689,2.481-4.033,6.014-4.033,10.605
		v28.311h-10.918v-52.275H434.04z"/>
				<path d="M481.535,134.529v43.257l19.4-21.423h13.25v0.641l-22.58,23.96l25.658,26.93v0.745h-13.359l-22.369-24.172
		v24.172h-11.026v-74.109H481.535z"/>
				<path d="M578.116,187.217v-52.793h11.874v74.215h-9.009l-42.094-52.378v52.378h-11.983v-74.215h9.122
		L578.116,187.217z"/>
				<path d="M648.669,163.104c5.055,5.194,7.579,11.679,7.579,19.453c0,7.849-2.486,14.332-7.47,19.455
		c-4.987,5.124-11.545,7.686-19.671,7.686c-8.061,0-14.562-2.562-19.505-7.686c-4.953-5.123-7.426-11.606-7.426-19.455
		c0-7.845,2.493-14.346,7.476-19.508c4.984-5.16,11.469-7.739,19.455-7.739C637.098,155.31,643.616,157.905,648.669,163.104z
		 M617.498,170.363c-2.934,3.324-4.398,7.387-4.398,12.193c0,4.805,1.446,8.837,4.345,12.087s6.785,4.874,11.663,4.874
		c4.876,0,8.784-1.624,11.713-4.874c2.936-3.25,4.403-7.282,4.403-12.087c0-4.735-1.52-8.783-4.56-12.14
		c-3.042-3.358-6.894-5.033-11.557-5.033C624.299,165.384,620.432,167.039,617.498,170.363z"/>
				<path d="M720.385,134.529v74.109h-10.282l-0.638-8.272c-3.813,6.222-9.859,9.331-18.13,9.331
		c-7.773,0-14.167-2.439-19.188-7.313c-5.017-4.88-7.524-11.521-7.524-19.933c0-8.626,2.453-15.319,7.363-20.092
		c4.917-4.771,11.293-7.155,19.14-7.155c3.462,0,6.985,0.827,10.55,2.489c3.569,1.662,6.168,3.943,7.79,6.839v-30.003H720.385z
		 M680.261,170.048c-3.15,3.036-4.721,7.171-4.721,12.403c0,5.16,1.57,9.296,4.721,12.403c3.144,3.111,7.083,4.663,11.822,4.663
		c4.662,0,8.603-1.587,11.819-4.769c3.214-3.18,4.823-7.282,4.823-12.298c0-4.95-1.609-9.015-4.823-12.193
		c-3.217-3.181-7.157-4.769-11.819-4.769C687.344,165.489,683.405,167.006,680.261,170.048z"/>
				<path d="M743.391,186.586c0.492,3.955,2.28,7.14,5.35,9.541c3.077,2.405,6.986,3.605,11.72,3.605
		c2.824,0,5.674-0.512,8.534-1.537c2.863-1.024,5.067-2.386,6.625-4.08l7.106,6.892c-2.616,2.826-5.957,5.015-10.022,6.573
		c-4.063,1.55-8.219,2.331-12.458,2.331c-8.552,0-15.391-2.508-20.513-7.527c-5.127-5.019-7.688-11.737-7.688-20.146
		c0-7.987,2.545-14.523,7.632-19.614c5.095-5.091,11.697-7.631,19.828-7.631c8.765,0,15.581,2.683,20.46,8.057
		c4.876,5.374,6.826,13.217,5.83,23.537H743.391z M775.303,177.357c-0.282-4.022-1.819-7.139-4.612-9.325
		c-2.792-2.191-6.418-3.283-10.868-3.283c-4.096,0-7.615,1.092-10.549,3.283c-2.931,2.187-4.824,5.303-5.672,9.325H775.303z"/>
				<path d="M830.323,169.199c-3.533-3.253-8.127-4.88-13.783-4.88c-3.392-0.07-6.095,0.441-8.111,1.537
		c-2.016,1.099-3.018,2.669-3.018,4.72c0.067,3.744,3.85,5.971,11.344,6.676c2.965,0.288,5.529,0.652,7.68,1.117
		c2.161,0.458,4.459,1.235,6.894,2.331c2.444,1.095,4.313,2.742,5.621,4.933c1.313,2.192,1.959,4.874,1.959,8.056
		c-0.067,4.95-2.116,8.942-6.146,11.979c-4.028,3.042-9.401,4.562-16.117,4.562c-10.176,0-18.128-3.222-23.854-9.649l5.726-7.742
		c4.524,5.021,10.638,7.598,18.344,7.742c2.966,0,5.547-0.561,7.738-1.696c2.191-1.129,3.323-2.828,3.394-5.09
		c0.071-2.26-0.903-3.926-2.914-4.98c-2.016-1.062-5.073-1.807-9.176-2.228c-14.136-1.345-21.238-6.576-21.309-15.691
		c0-5.3,2.21-9.281,6.63-11.927c4.412-2.652,9.452-3.976,15.104-3.976c8.196,0,14.916,2.33,20.147,6.994L830.323,169.199z"/>
			</g>
			</svg>
	);
}

export const WhiteLogoSmall = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="45px" viewBox="3.181 10.327 245.03 200.255" >
			<polygon fill="#FFFFFF" opacity="0.3" points="49.959,135.622 89.361,160.213 201.372,160.143 162.033,135.6 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="96.726,60.609 136.137,85.25 248.212,85.224 128.192,10.327 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="89.361,160.213 34.606,160.226 3.181,210.582 170.01,210.539 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="170.01,210.539 201.434,160.186 89.361,160.213 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="136.137,85.25 81.383,85.27 49.959,135.622 216.787,135.581 " />
			<polygon fill="#FFFFFF" opacity="0.3" points="216.787,135.581 248.212,85.224 136.137,85.25 " />
			</svg>
	);
}
