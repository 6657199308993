import React from "react";
import DataTable from 'react-data-table-component';
import update from 'immutability-helper';

import Form from "react-bootstrap/Form";
import FormControl from 'react-bootstrap/FormControl';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Spinner from 'react-bootstrap/Spinner';

import { faEllipsisVertical, faPencil, faPlusSquare, faCircleXmark, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typeahead, Highlighter, Token } from 'react-bootstrap-typeahead';


import "../Layout.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';

class OrganisationRoles extends React.Component {


	state = {
		selectedRoleID: 0,
		selectedRole: { name: '', description: '', active: false },
		showArchived: false,
		roles: [],
		isModalLoading: false,
		isDeletable: false,
		filterText: '',
		isLoadingData: false,
	};

	componentDidMount() {
		this.loadAllUsers();
		this.loadRoles();
	}

	loadAllUsers() {
		
		fetch('api/User/GetOrgUsers/', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ users: data });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}

	loadSelectedRole(roleID) {
		fetch('api/UserRoles/GetRole/' + roleID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				(data.taskTemplateUsage + data.workTasksOpen + data.workTasksClosed) > 0 ? this.setState({ isDeletable: false }) : this.setState({ isDeletable: true });
				this.setState({ selectedRole: data });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}

	loadRoles() {
		this.setState({ isLoadingData: true });
		fetch('api/UserRoles/GetListofRoles', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ roles: data });
				this.setState({ isLoadingData: false });
			})
			.catch(error => {
				console.log(error);
				this.setState({ isLoadingData: false });
				//Gör något om fel
			})
	}

	addRole() {
		fetch('api/UserRoles/AddRole', { method: 'POST', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.loadRoles();
				this.setState({ selectedRoleID: data });
				this.loadSelectedRole(data);
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}

	saveRole() {
		fetch('api/UserRoles/SaveRole', { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(this.state.selectedRole) })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.loadRoles();
				this.setState({ selectedRoleID: 0 });
				this.setState({ isModalLoading: false });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}

	deleteRole(roleID) {
		fetch('api/UserRoles/RemoveRole/' + roleID, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.loadRoles();
				this.setState({ selectedRoleID: 0 });
				this.setState({ isModalLoading: false });
			})
			.catch(error => {
				console.log(error);
				//Gör något om fel
			})
	}


	HandleEditRole(id) {
		this.setState({ selectedRoleID: id });
		this.loadSelectedRole(id);
	}

	handleShowArchivedChange(e) {
		if (this.state.showArchived) {
			this.onStatusFilter(['true']);
		} else {
			this.onStatusFilter(['true', 'false']);
		}

		this.setState({ showArchived: !this.state.showArchived });
	}

	handleNameChange(e) {
		const selectedRole = update(this.state.selectedRole, { name: { $set: e.target.value } });
		this.setState({ selectedRole: selectedRole });
	}

	handleDescriptionChange(e) {
		const selectedRole = update(this.state.selectedRole, { description: { $set: e.target.value } });
		this.setState({ selectedRole: selectedRole });
	}

	handleActiveChange(e) {
		const selectedRole = update(this.state.selectedRole, { active: { $set: e.target.checked } });
		this.setState({ selectedRole: selectedRole });
	}

	handleAssignmentChange(e) {
		const selectedRole = update(this.state.selectedRole, { assignmentList: { $set: e } });
		this.setState({ selectedRole: selectedRole });
	}

	renderMenuItemChildren(option, { text }, index) {
		return (
			<React.Fragment>
				<Highlighter search={text}>
					{option.fullName}
				</Highlighter>,
				<div>
					<small>
						{option.email.toLocaleString()}
					</small>
				</div>
			</React.Fragment>
		);
	}

	renderToken(option, { onRemove }, index) {
		return (
			<Token
				key={index}
				onRemove={onRemove}
				option={option}>
				{`${option.fullName} (${option.email.toLocaleString()})`}
			</Token>
		);
	}

	onFilter(e) {
		this.setState({ filterText: e.target.value });
	}

	menuPopup(row) {
		return (
			<Popover id="popover-basic" className="popup-menu shadow ">
				<Popover.Body>
					<ListGroup>
						<ListGroup.Item onClick={() => { document.body.click(); this.HandleEditRole(row.roleID); }} >
							<FontAwesomeIcon icon={faPencil} size="sm" /> Edit
						</ListGroup.Item>
					</ListGroup>
				</Popover.Body>
			</Popover>
		);

	}

	modalRole() {
		return (
			<Modal show={true} style={{ zIndex: '4077' }} centered>
				<ModalHeader><h3>Edit Role</h3></ModalHeader>
				<ModalBody>
					<Form.Label>Name</Form.Label>
					<FormControl
						type="text"
						name="roleName"
						value={this.state.selectedRole.name}
						maxLength="64"
						onChange={this.handleNameChange.bind(this)}
						disabled={this.state.isModalLoading}

					/>
					<Form.Label>Description</Form.Label>
					<FormControl
						as="textarea"
						rows="3"
						name="roleDescr"
						value={this.state.selectedRole.description}
						onChange={this.handleDescriptionChange.bind(this)}
						disabled={this.state.isModalLoading}
					/>

					<Form.Label>Assigned users</Form.Label>
					<Typeahead
						multiple
						renderMenuItemChildren={this.renderMenuItemChildren.bind(this)}
						renderToken={this.renderToken.bind(this)}
						key={"user-" + (new Date().getTime())}
						name="users"
						positionFixed
						id="users"
						labelKey="fullName"
						flip
						options={this.state.users}
						selected={this.state.selectedRole.assignmentList}
						onChange={this.handleAssignmentChange.bind(this)}
						placeholder="Choose a user..."
						disabled={this.state.isModalLoading}
					/>

				</ModalBody>
				<ModalFooter>

					<Button className='mr-auto' variant="secondary" onClick={() => { this.setState({ isModalLoading: true }); this.deleteRole(this.state.selectedRoleID); }} disabled={this.state.isModalLoading || !this.state.isDeletable}>
						{this.state.isModalLoading ? <Spinner animation="border"
							as="span"
							size="sm"
							role="status"
							aria-hidden="true" /> : ''}
						{' '}Remove{' '}
					</Button>{' '}

					<Button variant="primary" onClick={() => { this.setState({ isModalLoading: true }); this.saveRole(); }} disabled={this.state.isModalLoading}>
						{this.state.isModalLoading ? <Spinner animation="border"
							as="span"
							size="sm"
							role="status"
							aria-hidden="true" /> : ''}
						{' '}Save{' '}
					</Button>{' '}
					<Button variant="secondary" onClick={() => { this.setState({ selectedRoleID: 0 }) }} disabled={this.state.isModalLoading}>Cancel</Button>
				</ModalFooter>
			</Modal>
		);
	}

	customLoader() {
		return (
			<div style={{ padding: '24px' }}>
				<Spinner animation="border" />
				<div>Loading...</div>
			</div>
		);
	}


	render() {
		const filteredData = this.state.roles.filter(
			item => item.name && item.name.toLowerCase().includes(this.state.filterText.toLowerCase()),
		);

		const customStyles = {
			headRow: {
				style: {
					border: 'none',
				},
			},
			headCells: {
				style: {
					color: '#202124',
					fontSize: '14px',
				},
			},
			rows: {
				highlightOnHoverStyle: {
					backgroundColor: 'AliceBlue',
					borderBottomColor: '#FFFFFF',
					borderRadius: '25px',
					outline: '1px solid #FFFFFF',
				},
			},
			pagination: {
				style: {
					border: 'none',
				},
			},
		};

		const columns = [
			{
				cell: () => <FontAwesomeIcon icon={faUserGroup} />,
				width: '1.5rem', // custom width for icon button
				style: {
					borderBottom: '1px solid #FFFFFF',
					marginBottom: '-1px',
				},
			},
			{
				name: 'Name',
				selector: row => row.name,
				sortable: true,
				grow: 2,
				style: {
					color: '#202124',
					fontSize: '14px',
					fontWeight: 500,
				},
			},
			{
				name: 'Assignees',
				selector: row => row.assignees,
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				name: 'Template tasks',
				selector: row => row.templateTasks,
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				name: 'Active Tasks',
				selector: row => row.activeTasks,
				sortable: true,
				width: '10rem',
				style: {
					color: 'rgba(0,0,0,.54)',
				},
			},
			{
				cell: row => <OverlayTrigger trigger="click" placement="right" rootClose placement='left' overlay={this.menuPopup(row)}>
					<Button variant="light" className="btn-circle"><FontAwesomeIcon icon={faEllipsisVertical} /></Button>
				</OverlayTrigger>,
				allowOverflow: true,
				button: true,
				width: '3rem',
			},
		];

		return (
			<Card className="workspace-card">
				{this.state.selectedRoleID !== 0 ?  this.modalRole():null }
				<Card.Header>
					<div style={{ float: 'left' }}>
						<Row>
							<Col md="auto">
								<b>Organisational roles</b>
							</Col>
							<Col md="auto">
								<InputGroup>
									<Form.Control
										style={{ minWidth: '20rem' }}
										placeholder="Filter by name"
										size="sm"
										type="text"
										value={this.state.filterText}
										onChange={this.onFilter.bind(this)}
									/>
									<Button size='sm' onClick={() => { this.setState({ filterText: "" }) }} variant="secondary"><FontAwesomeIcon icon={faCircleXmark} /></Button>
								</InputGroup>
							</Col>
						</Row>
					</div>
					<div style={{ float: 'right' }}>
						<Row>
							<Col md="auto">
								<Button size='sm' onClick={()=>this.addRole()} id={"btn_add"} variant="primary"><FontAwesomeIcon icon={faPlusSquare} /> New </Button>
							</Col>
						</Row>


					</div>
				</Card.Header>
				<Card.Body>
					<DataTable
						className="data-table"
						columns={columns}
						data={filteredData}
						customStyles={customStyles}
						highlightOnHover
						pointerOnHover
						noHeader={true}
						progressPending={this.state.isLoadingData}
						progressComponent={this.customLoader()}
						onRowClicked={(row, event) => this.HandleEditRole(row.roleID)}
					/>
				</Card.Body>
			</Card >
		)
	}
}

export default (OrganisationRoles);