import * as React from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';

import { Dropdown as DropdownList } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

import SubTaskList from './SubTaskList';
import RiskDialog from './RiskDialog';

import "./FlowTemplate.css";


import { faUser, faEnvelope, faCalendarAlt, faFile, faBookReader, faGlasses, faHourglassHalf, faTasks, faClock } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";




class FlowTemplateProperties extends React.Component {
  
    state = {
        flowID:0,
        flowName:"",
        flowDescr: "",
        totalTime: 0,
        //flowOwners: [],
        //flowStarters: [],
        //selectedFlowOwners: [],
        //selectedFlowStarters:[],
        nodeID: 0,
        nodeName: "",
        nodeDescr: "",
        responsible: {roleID:0},
        subTasks: [],
        nodeEstimate: 0,
        tabKey: "FlowTemplate",
        roles: [],
        internalControl: false,
        attachFiles: 0,
        closingComments: false,
        nodeIcon: 0,   
        autoClose: false,
        hardEstimate: false,
        preTime: 0,
        controlTypeList: [{ typeID: 1, typeName: "Preventive" }, { typeID: 2, typeName: "Detective" }, { typeID: 3, typeName: "Corrective" }],
        controleModeList: [{ modeID: 1, modeName: "Manual" }, { modeID: 2, modeName: "Evaluation of automated" }],
        showRiskDialog: false,
        risks: [],
        riskList: [],
    };
   
    nodeIcon(iconNum) {
        switch (iconNum) {
            case 0:
                return faUser;
            case 1:
                return faEnvelope;
            case 2:
                return faCalendarAlt;
            case 3:
                return faFile;
            case 4:
                return faBookReader;
            case 5:
                return faGlasses;
            case 6:
                return faHourglassHalf;
            case 7:
                return faClock;
            case 8:
                return faTasks;
            default:
                return faUser;
        }
    } 

    constructor(props) {
        super(props);
    
        this.nodeNameRef = React.createRef();
    }

    componentDidMount() {
        this.fetchRoles();
        this.fetchRiskList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.FlowTemplate !== this.props.FlowTemplate) {
            this.setState({ flowID: this.props.FlowTemplate.flowID || 0 });
            this.setState({ flowName: this.props.FlowTemplate.flowName || "" });
            this.setState({ flowDescr: this.props.FlowTemplate.flowDescr || "" });
            this.setState({ totalTime: this.props.FlowTemplate.totalTime || 0 });
            //this.setState({ flowOwners: this.props.FlowTemplate.owners || [] });
            //this.setState({ flowStarters: this.props.FlowTemplate.starters || [] });
        }

        if (prevProps.ActiveNode.nodeID !== this.props.ActiveNode.nodeID && this.props.ActiveNode.nodeID !== this.state.nodeID) {
            this.nodeNameRef.current.focus();
            this.setState({ nodeID: this.props.ActiveNode.nodeID || 0 });
            this.setState({ nodeName: this.props.ActiveNode.nodeName || '' });
            this.setState({ nodeDescr: this.props.ActiveNode.nodeDescr || '' });
            this.setState({ responsible: this.props.ActiveNode.responsible || { roleID: 0 } });
            this.setState({ subTasks: this.props.ActiveNode.subTasks || [] });
            this.setState({ nodeEstimate: this.props.ActiveNode.nodeEstimate || 0 });
            this.setState({ nodeIcon: this.props.ActiveNode.nodeIcon || 0 });
            this.setState({ closingComments: this.props.ActiveNode.closingComments || false });
            this.setState({ internalControl: this.props.ActiveNode.internalControl || false });
            this.setState({ autoClose: this.props.ActiveNode.autoClose || false });
            this.setState({ hardEstimate: this.props.ActiveNode.hardEstimate || false });
            this.setState({ preTime: this.props.ActiveNode.preTime || 0 });
            this.setState({ risks: this.props.ActiveNode.risks || [] })

            this.setState({ controlType: this.props.ActiveNode.controlType || {} });
            this.setState({ controlMode: this.props.ActiveNode.controlMode || {} });

            if (this.props.ActiveNode.nodeID) {
                this.setState({ tabKey: "Node" });
               //onEntered = { e => { if (this.state.nodeID < 0) this.nodeNameRef.current.select(); this.nodeNameRef.current.focus(); }}
               
               
                setTimeout(() => this.nodeNameRef.current.focus(), 30)
                if (this.props.ActiveNode.nodeID < 0) {
                    setTimeout(() => this.nodeNameRef.current.select(), 30)
                } 
            } else {
                this.setState({ tabKey: "FlowTemplate" });
            }
        } else if (this.props.ActiveNode.nodeName !== prevProps.ActiveNode.nodeName) {
            this.setState({ nodeName: this.props.ActiveNode.nodeName || '' });
        }
    }

    fetchRoles() {
        fetch(`api/UserRoles/GetListofRoles`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                var roles=[];
                for (const row in data) {
                    const id = data[row].roleID;
                    const name = data[row].name;
               
                    roles[row] = {roleName: name, roleID: id};
                }
               
                this.setState({ roles: roles });
            })
            .catch(error => {
                //Gör något om fel
            })
    }

    fetchRiskList() {
        fetch(`api/Risk/GetAllRisks`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ riskList: data });              
            })
            .catch(error => {
                
            })
    }

    changeTaskID(newTaskID) {
        this.setState({ nodeID: newTaskID || 0 });
    }

    onResponsibleChanged(property, e) { 
        this.setState({ responsible: e.value});
        var responsibleName = "";

        if (this.props.onNodeChange) {
           
            responsibleName = e.value.roleName;
           
            var obj = { nodeID: this.state.nodeID, responsibleName: responsibleName, responsible: e.value };
            this.props.onNodeChange(obj);
        }
    }

    onNodeContentChanged(e) {
        this.setState(e);
        if (this.props.onNodeChange) {
            var obj = { nodeID: this.state.nodeID }; //new Object;
            Object.assign(obj, e)
            this.props.onNodeChange(obj);
        }
    }

    onTemplateContentChanged(e) {
        this.setState(e);
        if (this.props.onFlowChange) {
            this.props.onFlowChange(e);
        }
    }

    onRiskDialogClose(e) {
        this.setState({ showRiskDialog: false });
        this.setState({ risks: e })

        this.props?.onNodeChange && this.props?.onNodeChange({ nodeID: this.state.nodeID, risks: e }, false);
    }

    onSubListChanged(list) {
        this.setState({ subTasks: list });
        
        if (this.props.onNodeChange) {
            var obj = { nodeID: this.state.nodeID }; //new Object;
            Object.assign(obj, { subTasks: list })
            this.props.onNodeChange(obj);
        }
    }

    /*handleFlowStarterChange(e) { 
        const starters = this.state.flowStarters.map(role => { role.isAssigned = e.value.some(assigned => assigned.roleID === role.roleID); return role; });
        this.onTemplateContentChanged({ flowStarters: starters });
    }

    handleFlowOwnerChange(e) {
        if (e.value.some(role => role.isThisUser)) {
            const owners = this.state.flowOwners.map(role => { role.isAssigned = e.value.some(assigned => assigned.roleID === role.roleID); return role; });
            this.onTemplateContentChanged({ flowOwners: owners });
        }
    }*/

    render() {

        var startDay = this.state.preTime + 1;
        if (this.state.nodeEstimate === 0 && this.state.preTime > 0) {
            startDay = this.state.preTime;
        }
       /* var selectedFlowOwners, selectedFlowStarters;
        if (this.state.flowOwners) {
            selectedFlowOwners = this.state.flowOwners.filter(perm => perm.isAssigned);
        }
        if (this.state.flowStarters) {
            selectedFlowStarters = this.state.flowStarters.filter(perm => perm.isAssigned);
        }*/ 
 
        return (
            <div className="TemplateProperties">
                {/*<RiskDialog visible={this.state.showRiskDialog} onClose={this.onRiskDialogClose.bind(this)} taskID={this.state.nodeID} />*/}
                <Tabs defaultActiveKey="profile" mountOnEnter={false} id="templatePropertiesTabs" activeKey={this.state.tabKey} onSelect={(key) => this.setState({ tabKey: key })}>
                    <Tab eventKey="FlowTemplate" title="FlowTemplate" >
                        <div className="propertyTab">
                            <Form.Label size="sm">Name</Form.Label>
                            <FormControl
                                size="sm"
                                type="text"
                                name="flowName"
                                value={this.state.flowName}
                                onChange={e => this.onTemplateContentChanged({ flowName: e.target.value })}
                                maxLength="64"
                            />
                            <Form.Label size="sm">Description</Form.Label>
                            <FormControl
                                size="sm"
                                as="textarea"
                                rows="3"
                                name="flowDescr"
                                value={this.state.flowDescr}
                                onChange={e => this.onTemplateContentChanged({ flowDescr: e.target.value })}
                            />             

                            {/*
                             <div className="p-fluid">
                                <div className="p-field">
                                    <label className="p-field-label">FlowTemplate owner (can edit)</label>
                                    <MultiSelect
                                        value={selectedFlowOwners}
                                        options={this.state.flowOwners}
                                        optionLabel="name" 
                                        placeholder="Select role..."
                                        className="p-inputtext-sm w-full md:w-20rem"
                                        display="chip"
                                        onChange={(e) => this.handleFlowOwnerChange(e)}
                                    />
                                </div>
                                <div className="p-field">
                                    <label className="p-field-label">WorkFlow Owner (can start)</label>
                                    <MultiSelect
                                        value={selectedFlowStarters}
                                        options={this.state.flowStarters}
                                        optionLabel="name"
                                        placeholder="Select role..."
                                        className="p-inputtext-sm w-full md:w-20rem"
                                        display="chip"
                                        onChange={(e) => this.handleFlowStarterChange(e)}
                                    />
                                </div>
                            </div>*/}

                            <Form.Label size="sm">Total time estimate</Form.Label>
                            <InputGroup size="sm" className="mb-3">
                                <FormControl
                                    type="text"
                                    readOnly 
                                    name="totalTime"
                                    value={this.state.totalTime}
                                />
                                <InputGroup.Text>Days</InputGroup.Text>                            
                            </InputGroup>
                        </div>
                    </Tab>
                    <Tab eventKey="Node" title="Task" disabled={this.state.nodeID === 0} >
                        <div className="propertyTab">
                            <Form.Label size="sm">Name</Form.Label>
                            <InputGroup size="sm" className="mb-3">
                                <Dropdown>   
                                    <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                        <FontAwesomeIcon icon={this.nodeIcon(this.state.nodeIcon)} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 0 })}><FontAwesomeIcon icon={faUser} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 1 })}><FontAwesomeIcon icon={faEnvelope} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 2 })}><FontAwesomeIcon icon={faCalendarAlt} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 3 })}><FontAwesomeIcon icon={faFile} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 4 })}><FontAwesomeIcon icon={faBookReader} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 5 })}><FontAwesomeIcon icon={faGlasses} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 6 })}><FontAwesomeIcon icon={faHourglassHalf} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 7 })}><FontAwesomeIcon icon={faClock} /></Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onNodeContentChanged({ nodeIcon: 8 })}><FontAwesomeIcon icon={faTasks} /></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <FormControl
                                    type="text"
                                    ref={this.nodeNameRef}
                                    name="nodeName"
                                    value={this.state.nodeName}
                                    onChange={e => this.onNodeContentChanged({ nodeName: e.target.value })}
                                    maxLength="64"
                                />
                            </InputGroup>
                            <Form.Label size="sm">Description</Form.Label>
                            <FormControl
                                as="textarea"
                                rows="3"
                                name="nodeDescr"
                                value={this.state.nodeDescr}
                                onChange={e => this.onNodeContentChanged({ nodeDescr: e.target.value })}
                            />   
                            <div className="p-fluid">
                                <div className="p-field">
                                    <label className="p-field-label">Responsible</label>
                                    <DropdownList
                                        style={{ maxWidth: '100%' }}  
                                        value={this.state.responsible}
                                        options={this.state.roles}
                                        optionLabel="roleName"
                                        placeholder="Select responsible role..."
                                        className="p-inputtext-sm w-full md:w-20rem"
                                        onChange={e => this.onResponsibleChanged("responsible", e)}
                                    />
                                </div>
                            </div>

                            <Form.Group controlId="formTaskEstimate">
                                <Form.Label>Estimate</Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                    
                                        <Button variant="outline-secondary" onClick={(e) => { const estimate = this.state.nodeEstimate + 1; this.setState({ nodeEstimate: estimate }); this.onNodeContentChanged({ nodeEstimate: estimate }); }}  >+</Button>
                                        <Button variant="outline-secondary" onClick={(e) => { const estimate = Math.max(this.state.nodeEstimate - 1, 0); this.setState({ nodeEstimate: estimate }); this.onNodeContentChanged({ nodeEstimate: estimate }); }}>-</Button>
                                    
                                    <FormControl
                                        type="text"
                                        name="nodeEstimate"
                                        placeholder=""
                                        value={this.state.nodeEstimate}
                                        onChange={e => this.onNodeContentChanged({ nodeEstimate: Math.max(parseInt("0" + e.target.value),0) })}
                                    />
                                    
                                        <InputGroup.Text>Days</InputGroup.Text>   
                                </InputGroup>

                                <Form.Text className="text-muted">
                                    This task starts on workday {startDay}
                                </Form.Text>
                            </Form.Group>   

                            <hr />

                            <Form.Label>Subtasks</Form.Label>
                            <SubTaskList
                                taskID={this.state.nodeID}
                                subTasks={this.state.subTasks}
                                onListChanged={this.onSubListChanged.bind(this)} />
                            <hr />

                            <Form.Label>Closing options</Form.Label>

                            <Form.Check
                                type="checkbox"
                                className="my-1 mr-sm-2"
                                id="requireComment"
                                label="Require closing comment"
                                disabled={this.state.autoClose || this.state.internalControl}
                                checked={this.state.closingComments || this.state.internalControl}
                                onChange={e => this.onNodeContentChanged({ closingComments: e.target.checked })}  
                            />


                            <Form.Check
                                type="checkbox"
                                className="my-1 mr-sm-2"
                                id="autoClose"
                                label="Auto close"
                                disabled={this.state.internalControl || this.state.closingComments || this.state.subTasks.length > 0}
                                checked={this.state.autoClose}
                                onChange={e => { this.onNodeContentChanged({ autoClose: e.target.checked }); this.onNodeContentChanged({ hardEstimate: false }) }}  
                            />
                            <Form.Check
                                style={{ marginLeft: "1em"}}
                                type="checkbox"
                                className="my-1 mr-sm-2"
                                id="hardEstimate"
                                label="Hard estimate (keep estimate rather than deadline)"
                                disabled={!this.state.autoClose}
                                checked={this.state.hardEstimate}
                                onChange={e => this.onNodeContentChanged({ hardEstimate: e.target.checked })}  
                            />   

                            <hr />
                            <Form.Check
                                type="checkbox"
                                className="my-1 mr-sm-2"
                                id="internalControl"
                                label="Internal control"
                                disabled={this.state.autoClose}
                                checked={this.state.internalControl}
                                onChange={e => this.onNodeContentChanged({ internalControl: e.target.checked })} 
                            />
                            {this.state.internalControl ?

                            <div className="p-fluid">
                               
                                    <div className="p-field">

                                    <label className="p-field-label">Risks</label>
                                        <MultiSelect
                                            value={this.state.risks}
                                            options={this.state.riskList}
                                            optionLabel="name"
                                            placeholder="Select risks..."
                                            className="w-full md:w-20rem"
                                            onChange={e => this.onNodeContentChanged({ risks: e.value })}
                                            disabled={!this.state.internalControl}
                                            maxSelectedLabels={1}
                                            style={{ maxWidth: '100%' }} 
                                        />

                                    <label className="p-field-label">Control Type</label>
                                    <DropdownList
                                        style={{ maxWidth: '100%' }}
                                        value={this.state.controlType}
                                        optionValue="typeID"
                                        optionLabel="typeName"
                                        options={this.state.controlTypeList}
                                        disabled={!this.state.internalControl}
                                        onChange={e => this.onNodeContentChanged({ controlType: e.target.value })}
                                        optionLabel="typeName"
                                        placeholder="Select control type..."
                                        className="p-inputtext-sm w-full md:w-20rem"
                                    />
                                        { /*(e) => this.setState({ risks: e.value })
                                    <DropdownList
                                        style={{ maxWidth: '100%' }}
                                        value={this.state.controlMode}
                                        optionValue="modeID"
                                        optionLabel="modeName"
                                        options={this.state.controleModeList}
                                        disabled={!this.state.internalControl}
                                        onChange={e => this.onNodeContentChanged({ controlMode: e.target.value })}
                                        optionLabel="modeName"
                                        placeholder="Select control mode..."
                                        className="p-inputtext-sm w-full md:w-20rem"
                                       
                                    />*/ }
                                </div>                             
                            </div>
                           : null }
                        </div>
                    </Tab>
                    
                </Tabs>
           
            </div>
        );
    }
}

export default (FlowTemplateProperties);

