import * as React from 'react';
import * as go from 'gojs';
//import { Prompt  } from "react-router";
import { ReactDiagram } from 'gojs-react';
import FlowTemplateProperties from "./FlowTemplateProperties";

import { withRouter } from "./react/WithRouter";

import "./FlowTemplate.css";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GojsIcons } from './GojsIcons';

const nodeIcons = ["user", "envelope", "calendar", "file", "bookReader", "glasses", "hourglass", "clock", "tasks"];

class FlowTemplateView extends React.Component {

    state = {
        SelectedNode: 0, 
        FlowTemplate: { flowID: 0, flowName: '', flowDescr: '', totalTime: 0, owners:[], runners:[] },
        ActiveNode: {
            nodeID: 0, nodeName: '', nodeDescr: '', responsible: {roleID:0}, nodeEstimate: 0, nodeIcon: 0, closingComments: false, internalControl: false, autoClose: false, hardEstimate: false, subTasks: [],risks: [], controlType:0, controlMode:0
        },
        Modified: false,
        Saving: false,
        LayoutComplete: false,
    };

    constructor(props) {
        super(props);
        this.diagramRef = React.createRef();
        this.propertyRef = React.createRef();
        this.timeoutId = null;
        this.isLoaded = false;
    }
     
    componentDidUpdate() {
        if (this.state.Modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    componentDidMount() {
        this.load();
        this.flowPropertiesChanged({ flowID: Number(this.props.params.flowTemplateID) })
    }

    GetIcon(iconNum) {
        if (iconNum >= nodeIcons.length) iconNum = 0;
        var iconString = GojsIcons[nodeIcons[iconNum]];    
        var icon;
        if (typeof iconString === "string") {
            icon = go.Geometry.parse(iconString, true);
        }
        return icon;
    }

  //Make sure there are no gaps in the list
    sortNodes() {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        const model = diagram.model;
        var nodeList = [];

        for (var it = diagram.nodes; it.next();) {
            var node = it.value;
            nodeList.push({ nodeID: node.data.nodeID, ganttOrder: node.data.ganttOrder });
        }
        nodeList.sort((a, b) => { return a.ganttOrder - b.ganttOrder });
        nodeList.forEach((item, index) => { var node = diagram.findNodeForKey(item.nodeID); model.set(node.data, 'ganttOrder', index + 1); });

    }

    //recursively go through child nodes to set their order number for the GANTT
    setNodeOrder(node, nodeOrder) {
        if (!this.diagramRef.current) return nodeOrder;
        const diagram = this.diagramRef.current.getDiagram();
        const model = diagram.model;

        var it = node.findNodesOutOf();
        while (it.next()) {
            if (!(it.value.data.nodeOrder > 0)) {
                nodeOrder++;
                model.set(it.value.data, 'ganttOrder', nodeOrder);
                nodeOrder = this.setNodeOrder(it.value, nodeOrder);
            }
        }
        return nodeOrder;
    }

    //Set an ordernumber for each node for the GANTT 
    SetGanttOrder() {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        var model = diagram.model;

        var nodeOrder = 0;
        var that = this;
        diagram.nodes.each(function (n) {
            var it = n.findNodesInto();
            if (it.count === 0) {
                if (!(n.data.nodeOrder > 0)) {
                    nodeOrder++;
                    model.set(n.data, 'ganttOrder', nodeOrder);
                    nodeOrder = that.setNodeOrder(n, nodeOrder);
                }
            }
        });
        this.sortNodes();
    }

    crawlNodes(node) {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        const model = diagram.model;

        var totalTime = 0;

        var it = node.findNodesOutOf();
        while (it.next()) {
            var pretime = it.value.data.preTime || 0;
            pretime = Math.max(node.data.preTime + node.data.nodeEstimate, pretime)
            model.set(it.value.data, 'preTime', pretime);

            totalTime = Math.max(pretime + it.value.data.nodeEstimate, totalTime);
            totalTime = Math.max(this.crawlNodes(it.value), totalTime);
        }     
        return totalTime;
    }

    calcTime() {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        var model = diagram.model;

        var totalTime = 0;
        var startNodes = [];

        diagram.nodes.each(function (n) {
            model.set(n.data, 'preTime', 0);
            var it = n.findNodesInto();
            if (it.count === 0) {
                startNodes.push(n)    
            }
        });

        for (var i = 0; i < startNodes.length; i++) {
            totalTime = Math.max(startNodes[i].data.nodeEstimate, totalTime);
            totalTime = Math.max(this.crawlNodes(startNodes[i]), totalTime);
           
        }
 
        this.setState(prevState => ({
            FlowTemplate: {
                ...prevState.FlowTemplate,
                totalTime: totalTime
            }
        }));
        
        model.set(model.modelData, "totalTime", totalTime);
    }

    deleteNode (e, b) {
        var node = b.part.adornedPart;

        if (node !== null) {
            var diagram = node.diagram;
            diagram.startTransaction("Delete");
            diagram.remove(node);
            diagram.commitTransaction("Delete");
        }
       
    }

    insertNodeOnLink(e, b) {
        var link = b.part.adornedPart;

        var diagram = link.diagram;
        diagram.startTransaction("add node and link");

        var fromNode = link.fromNode;
        var toNode = link.toNode;

        var newdata = {
            nodeName: "New node",
            category: "simpleNode",
            nodeEstimate: 0,
            responsible: {roleID:0},
            subTasks: [],
            risks:[],
        };
        diagram.model.addNodeData(newdata);
        var newNode = diagram.findNodeForData(newdata);

        var p = fromNode.location.copy();
        p.x = p.x + 175;
        newNode.location = p;

        var newlink = { from: newNode.data.nodeID, to: toNode.data.nodeID };
        diagram.model.addLinkData(newlink);
        link.toNode = newNode;

        diagram.commitTransaction("add node and link");

        if (newNode !== null) diagram.scrollToRect(newNode.actualBounds);

        diagram.select(newNode);
    }

    addNodeAndLink(e, b) {      
        var node = b.part.adornedPart;

        var diagram = node.diagram;
        diagram.startTransaction("add node and link");
        //var model = diagram.model;

        var newdata = {
            nodeName: "New node",
            category: "simpleNode",
            nodeEstimate: 0,
            responsible: { roleID:0 },
            subTasks: [],
            risks:[],
        };
        diagram.model.addNodeData(newdata);

        var p = node.location.copy();
        p.x = p.x + 175;
        diagram.findNodeForData(newdata).location = p;

        var newlink = { from: node.data.nodeID, to: newdata.nodeID };
        diagram.model.addLinkData(newlink);

        diagram.commitTransaction("add node and link"); 
         
        // if the new node is off-screen, scroll the diagram to show the new node
        var newnode = diagram.findNodeForData(newdata);
        if (newnode !== null) diagram.scrollToRect(newnode.actualBounds);

        diagram.select(newnode);
    }

    nodeSelectionChanged(node) {
        if (node.isSelected) {
            this.setState({
                ActiveNode: {
                    nodeID: node.key,
                    nodeName: node.data.nodeName,
                    nodeDescr: node.data.nodeDescr,
                    nodeEstimate: node.data.nodeEstimate,
                    responsible: node.data.responsible,
                    nodeIcon: node.data.nodeIcon,
                    closingComments: node.data.closingComments,
                    internalControl: node.data.internalControl,
                    autoClose: node.data.autoClose,
                    hardEstimate: node.data.hardEstimate,
                    subTasks: node.data.subTasks,
                    preTime: node.data.preTime,
                    controlType: node.data.controlType,
                    controlMode: node.data.controlMode,
                    risks: node.data.risks,
                    controlType: node.data.controlType||0,
                    controlMode: node.data.controlMode||0,
                }   
            }); 
        } else {
            this.setState({ ActiveNode: {} });
        }
    }

    load() {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        const flowID = this.props.params.flowTemplateID
        
        fetch(`api/Template/GetTemplate/` + flowID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                data.class = "GraphLinksModel";
                data.nodeKeyProperty = "nodeID";
                data.linkKeyProperty = "linkID";

                diagram.model = go.Model.fromJSON(data);
                this.setState({ FlowTemplate: { flowID: data.modelData.flowID, flowName: data.modelData.flowName, flowDescr: data.modelData.flowDescr, totalTime: data.modelData.totalTime, owners: data.modelData.owners, starters: data.modelData.starters  } });
                diagram.isModified = false;
                this.setState({ Modified: false });
                this.isLoaded = true;

            })
            .catch(error => {
                console.log(error);
                //Gör något om fel
            })

    }

    updateKeys(newKeys) {
        const diagram = this.diagramRef.current.getDiagram();
        const model = diagram.model;

        for (var i = 0; i < newKeys.length; i++) {
            const newKey = newKeys[i].newKey;
            const oldKey = newKeys[i].oldKey
            if (newKeys[i].oldKey === this.state.ActiveNode.nodeID)
                this.propertyRef.current.changeTaskID(newKey);
            const nodeData = model.findNodeDataForKey(oldKey);
            model.set(nodeData, 'nodeID', newKey);
            //this.setState({ ActiveNode: newKey });
        }
    }

    // When the blob is complete, make an anchor tag for it and use the tag to initiate a download
    // Works in Chrome, Firefox, Safari, Edge, IE11
     svgCallback(blob) {
        var url = window.URL.createObjectURL(blob);
        var filename = "WorkFlow.svg";

        var a = document.createElement("a");
        a.style = "display: none";
        a.href = url;
        a.download = filename;

        // IE 11
        if (window.navigator.msSaveBlob !== undefined) {
            window.navigator.msSaveBlob(blob, filename);
            return;
        }

        document.body.appendChild(a);
        requestAnimationFrame(function () {
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }

    exportSVG() {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();

        var svg = diagram.makeSvg({ scale: 1, background: "white" });
        var svgstr = new XMLSerializer().serializeToString(svg);
        var blob = new Blob([svgstr], { type: "image/svg+xml" });
        this.svgCallback(blob);
    }

    save() {
        if (!this.diagramRef.current) return;
        this.setState({ Saving: true });
        const diagram = this.diagramRef.current.getDiagram();
        //diagram.clearSelection(); //Clear selection as some of the nodes might get new "real" IDs and thus mess up the connection to the properties panel

        this.SetGanttOrder(diagram.model.toJson());
        
        fetch(`api/Template/ChangeTemplate`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: diagram.model.toJson() })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.updateKeys(data);
                diagram.isModified = false;
                this.setState({ Saving: false });
                this.setState({ Modified: false });
            })
            .catch(error => {
                alert("Failed to save. Try again or contact support");
                this.setState({ Saving: false });
            })
    }

    flowPropertiesChanged(propertyData) {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        const model = diagram.model;
        const data = model.modelData;

        if (data) {
            model.startTransaction("modified property");
            for (var key in propertyData) {
                model.set(data, key, propertyData[key]);
            }
            model.commitTransaction("modified property");

            this.setState({ FlowTemplate: { flowID: model.modelData.flowID, flowName: model.modelData.flowName, flowDescr: model.modelData.flowDescr, totalTime: model.modelData.totalTime, owners: model.modelData.owners, starters: model.modelData.starters } });
        }
    }    

    nodePropertiesChanged(propertyData, isReversable = true) {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        const model = diagram.model;   
        const nodeData = model.findNodeDataForKey(propertyData.nodeID);
        
        if (nodeData) {

            if (isReversable) {
                model.startTransaction("modified property");
            }

            for (var key in propertyData) {
                if (key !== "nodeID") {
                    model.set(nodeData, key, propertyData[key]);
                }
            }

            if (isReversable) {
                model.commitTransaction("modified property");
            }
        }
    }

    manualSave() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
        this.save();
    }

    autoSave() {
        if (this.state.LayoutComplete) { 
            if (this.timeoutId) {
                clearTimeout(this.timeoutId)
            };
            this.timeoutId = setTimeout(() => {
                this.save();
            }, 3000);
        }
    }

    modelChanged(e) {
    }

    initDiagram() {
        const $ = go.GraphObject.make;

        go.Diagram.licenseKey = "73f944e0b16331b700ca0d2b113f69ed1bb37a669e821ff55e5941a1ef0068432b99ec7903d78e92d4ff5cec1c2990d1ddcc3a79c34b076be335dadb46e58ef1b63575e214584286a70576c19dfe7df4ff7976a7cabc25f0db78dff1efa0d18c5abda3d248985eba3b680530557eb04ab6e4da78";

        const diagram = $(go.Diagram,
            {
               
                'undoManager.isEnabled': true,
                'undoManager.maxHistoryLength': 10,
                model: $(go.GraphLinksModel, {
                    linkKeyProperty: 'linkID',
                    nodeKeyProperty: 'nodeID'
                }),
                padding: 20,
            });

        diagram.validCycle = go.Diagram.CycleNotDirected ;

        diagram.nodeTemplateMap.add("simpleNode",
            $(go.Node, "Auto",
                {
                    isShadowed: true,
                    shadowOffset: new go.Point(3, 3),
                    shadowColor: "#C5C1AA",
                    height: 50,
                    selectionChanged: this.nodeSelectionChanged.bind(this)
                },
                new go.Binding("location", "loc", go.Point.parse).makeTwoWay((pos) => { return Math.round(pos.x).toString() + " " + Math.round(pos.y).toString(); }),

                $(go.Shape,
                    "RoundedRectangle",
                    {
                        strokeWidth: 1,
                        fill: "#FFE4A0",
                        spot1: go.Spot.TopLeft,
                        spot2: go.Spot.BottomRight
                    },

                ),

                $(go.Panel, "Table",
                    {
                        maxSize: new go.Size(130, 999),
                        minSize: new go.Size(130, 10),
                        margin: new go.Margin(0, 3, 0, 5),
                        defaultAlignment: go.Spot.Left
                    },
                    $(go.RowColumnDefinition, { column: 3 }),
                    $(go.TextBlock,
                        {
                            row: 0,
                            column: 0,
                            columnSpan: 2,
                            font: "bold 10pt sans-serif",
                            //editable: true,
                            isMultiline: false,
                            overflow: go.TextBlock.OverflowEllipsis,
                            maxLines: 1,
                            width: 100,
                            name: "name",
                        },
                        new go.Binding("text", "nodeName").makeTwoWay()),

                    $(go.TextBlock, "Resp: ",
                        {
                            row: 1,
                            column: 0,
                            font: "9pt sans-serif"
                        },
                        new go.Binding("stroke", "responsible", function (resp) { return resp!=null && resp.roleID > 0 ? 'black' : 'red'; } ),
                    ),
                   

                    $(go.TextBlock,
                        {
                            row: 1,
                            column: 1,
                            textAlign: "left",
                            font: "9pt sans-serif",
                            isMultiline: false,
                            overflow: go.TextBlock.OverflowEllipsis,
                            maxLines: 1,
                            name: "responsible"
                        },
                        new go.Binding("text", "responsibleName").makeTwoWay()),                  
                    $(go.TextBlock, "Est: ",
                        {
                            row: 2,
                            column: 0,
                            font: "9pt sans-serif"
                        }),
                    $(go.TextBlock,
                        {
                            row: 2,
                            column: 1,
                            textAlign: "left",
                            isMultiline: false,
                            overflow: go.TextBlock.OverflowEllipsis,
                            maxLines: 1,
                            font: "9pt sans-serif"
                        },
                        new go.Binding("text", "nodeEstimate", function (data) { return data + " days"; })),
                    $(go.Shape,
                        {
                            geometry: go.Geometry.parse(GojsIcons["user"], true),
                            row: 0,
                            rowSpan: 3,
                            column: 2,
                            margin: 3,
                            fill: "rgba(0, 0, 0, 0.52)",
                            strokeWidth: 0,
                            alignment: go.Spot.Right,
                            width: 25,
                            height: 25,
                            geometryStretch: go.GraphObject.Uniform,
                        },
                        new go.Binding("geometry", "nodeIcon", this.GetIcon.bind(this))
                    ),
                ),
                {
                    toolTip:  // define a tooltip for each node that displays the color as text
                        $("ToolTip",
                            $(go.TextBlock, { margin: 4 },
                                new go.Binding("text", "nodeName"))
                        )  // end of Adornment
                },

                $(go.Shape,
                    "RoundedRectangle",
                    {
                        fill: "transparent",  // changed to a color in the mouseEnter event handler
                        strokeWidth: 0,  // no stroke
                        width: 8,  // if not stretching horizontally, just 8 wide
                        alignment: go.Spot.Right,  // align the port on the main Shape
                        stretch: go.GraphObject.Vertical,
                        portId: "from",  // declare this object to be a "port"
                        fromSpot: go.Spot.RightSide,  // declare where links may connect at this port
                        fromLinkable: true,  // declare whether the user may draw links from here
                        fromLinkableSelfNode: false,
                        fromLinkableDuplicates: false,
                        toLinkable: false,  // declare whether the user may draw links to here
                        cursor: "pointer",  // show a different cursor to indicate potential link point
                        mouseEnter: function (e, port) {  // the PORT argument will be this Shape
                            if (!e.diagram.isReadOnly) port.fill = "RoyalBlue";
                        },
                        mouseLeave: function (e, port) {
                            port.fill = "transparent";
                        }
                    }),

                $(go.Shape,
                    "RoundedRectangle",
                    {
                        fill: "transparent",  // changed to a color in the mouseEnter event handler
                        strokeWidth: 0,  // no stroke
                        width: 8,  // if not stretching horizontally, just 8 wide
                        alignment: go.Spot.Left,  // align the port on the main Shape
                        stretch: go.GraphObject.Vertical,
                        portId: "to",  // declare this object to be a "port"
                        toSpot: go.Spot.LeftSide,  // declare where links may connect at this port
                        toLinkable: true,  // declare whether the user may draw links from here
                        toLinkableSelfNode: false,
                        toLinkableDuplicates: false,
                        fromLinkable: false,  // declare whether the user may draw links to here
                        cursor: "pointer",  // show a different cursor to indicate potential link point
                        mouseEnter: function (e, port) {  // the PORT argument will be this Shape
                            if (!e.diagram.isReadOnly) port.fill = "RoyalBlue";
                        },
                        mouseLeave: function (e, port) {
                            port.fill = "transparent";
                        }
                    }),

                {
                    selectionAdornmentTemplate:
                        $(go.Adornment, "Spot",
                            $(go.Panel, "Auto",
                                $(go.Shape, "RoundedRectangle", { stroke: "RoyalBlue", strokeWidth: 2, fill: null, margin: 2 }),
                                $(go.Placeholder)
                            ),
                            $("Button",
                                {
                                    alignment: go.Spot.Right, alignmentFocus: go.Spot.Left,
                                    click: this.addNodeAndLink
                                },
                                $(go.TextBlock, "+",
                                    { font: "bold 12pt sans-serif", desiredSize: new go.Size(15, 15), textAlign: "center" })
                            ),

                            $(go.Panel, "Horizontal",
                                { alignment: go.Spot.BottomLeft, alignmentFocus: go.Spot.TopLeft },
                                $("Button",
                                    {
                                        click: this.deleteNode
                                    },
                                    $(go.Shape,
                                        {
                                            geometry: go.Geometry.parse(GojsIcons["trash"], true),
                                            strokeWidth: 0,
                                            geometryStretch: go.GraphObject.Uniform,
                                            width: 15,
                                            height: 15,
                                        })
                                ),
                                $("Button",
                                    {
                                        click: (e, node) => { e.diagram.commandHandler.copySelection(); }
                                },  
                                $(go.Shape,
                                    {
                                        geometry: go.Geometry.parse(GojsIcons["copy"], true),
                                        strokeWidth: 0,
                                        geometryStretch: go.GraphObject.Uniform,
                                        width: 15,
                                        height: 15,
                                    })
                           ),
                       )
                   ),  
                  
           }
        ));

    diagram.linkTemplate =
        $(go.Link, {
            routing: go.Link.AvoidsNodes,  
            corner: 10,
            toLinkable: false,
            fromSpot: go.Spot.Right,
            toSpot: go.Spot.Left,
            fromPortId: "from",
            toPortId: "to",
            fromEndSegmentLength: 10,
            toEndSegmentLength: 15,
        },
            { relinkableFrom: true, relinkableTo: true },
            $(go.Shape),
            $(go.Shape, { toArrow: "kite" }),
            {
                selectionAdornmentTemplate:
                    $(go.Adornment, "Link",
                        $(go.Shape, { isPanelMain: true, stroke: "RoyalBlue", strokeWidth: 2 }),
                        $("Button",
                            {
                                click: this.insertNodeOnLink
                            },
                            $(go.TextBlock, "+",
                                { font: "bold 12pt sans-serif", desiredSize: new go.Size(15, 15), textAlign: "center" })
                        )
                    )
            }
        );

    diagram.contextMenu =
        $("ContextMenu",
            $("ContextMenuButton",
                $(go.TextBlock, "New Node",
                    { margin:2, font: "12pt sans-serif", textAlign: "left"  }),
                {
                    click:function (e, obj) {
                        e.diagram.commit(function (d) {
                            var data = {
                                nodeName: "New node", category: "simpleNode", nodeEstimate: 0, responsible: { roleID: 0 } , subTasks:[],};
                            d.model.addNodeData(data);
                            var part = d.findPartForData(data);  
                            part.location = d.toolManager.contextMenuTool.mouseDownPoint;

                            var newnode = e.diagram.findNodeForData(data);
                           
                            e.diagram.select(newnode);
                        }, 'new node');
                        
                    }
                }),
                
            $("ContextMenuButton",
                $(go.TextBlock, "Paste Node",
                    { margin: 2, font: "12pt sans-serif", textAlign: "left"  }),
                {
                    click: function (e, obj) { e.diagram.commandHandler.pasteSelection(e.diagram.toolManager.contextMenuTool.mouseDownPoint); },
                },
                new go.Binding("visible", "", function (e, o) { return o.diagram.commandHandler.canPasteSelection(o.diagram.toolManager.contextMenuTool.mouseDownPoint);})
            ),
            {
                isShadowed: true,
                shadowOffset: new go.Point(3, 3),
                shadowColor: "#C5C1AA",
            }
        );

        diagram.addModelChangedListener(this.modelChanged.bind(this));

        diagram.addDiagramListener('InitialLayoutCompleted', (e) => {
            if (this.isLoaded) {
                this.setState({ LayoutComplete: "true" });
                diagram.isModified = false;
                this.setState({ Modified: false });
            }
        });

    return diagram;
}

    handleModelChange(changes) {
        if (!this.diagramRef.current) return;
        const diagram = this.diagramRef.current.getDiagram();
        this.setState({ Modified: diagram.isModified });
        this.calcTime();
        this.autoSave();
    }

    render() {
       
        return (      

            <div className="TemplateViewContainer">
                {/*<Prompt
                    when={this.state.Modified}
                    message='You have unsaved changes, are you sure you want to leave?'
                />*/}
                <Card className="TemplateView" >
                    <Card.Header><b>{this.state.FlowTemplate.flowName} - FlowTemplate diagram</b>
                        <div style={{ float: 'right' }}>    
                            <Button variant="primary" color="primary" size='sm' onClick={this.exportSVG.bind(this)} ><FontAwesomeIcon icon={faFileDownload} /> Export SVG</Button>{' '}
                            <Button variant="primary" color="primary" size='sm' onClick={this.manualSave.bind(this)} disabled={!this.state.Modified || !this.state.LayoutComplete || this.state.Saving} >{this.state.Saving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faCloudUploadAlt} />}</Button>
                        </div>
                    </Card.Header>
                    <Card.Body style={{ padding: '0px', position: 'relative' }}>
                        {/*<div id="myOverviewDiv" className="TemplateOverView"></div>*/}
                        <ReactDiagram
                            ref={this.diagramRef}
                            initDiagram={this.initDiagram.bind(this)}
                            divClassName='diagram-component'
                            onModelChange = {this.handleModelChange.bind(this)}
                            />
                    </Card.Body>
                </Card>
            
                <div className='TemplateViewSide'>
                    <FlowTemplateProperties
                        ref={this.propertyRef}
                        FlowTemplate={this.state.FlowTemplate}
                        ActiveNode={this.state.ActiveNode}
                        onNodeChange={this.nodePropertiesChanged.bind(this)}
                        onFlowChange={this.flowPropertiesChanged.bind(this)} />
                </div> 
            </div>
         );
    }
}

export default withRouter (FlowTemplateView);