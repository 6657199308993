import React from "react";
import update from 'immutability-helper';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";


class Risk extends React.Component {
	riskScale = [1, 2, 3, 4, 5];

	probabilityScale = [{ id: 1, text: '1 Very unlikely' }, { id: 2, text: '2 Unlikely' }, { id: 3, text: '3 Possible' }, { id: 4, text: '4 Likely' }, { id: 5, text: '5 Very likely' }];
	consequenceScale = [{ id: 1, text: '1 Negligible' },  { id: 2, text: '2 Minor' }, { id: 3, text: '3 Moderate' }, { id: 4, text: '4 Significant' }, { id: 5, text: '5 Severe' }];

	state = {
		riskList: [],
		editingRows: [],
		taskID: 0,
		isLoadingData: false,
		isLoadingNew: false,
	};

	componentDidMount() {
		this.fetchRiskList();
	}

	componentDidUpdate(prevProps, prevState) {
		/*if (prevProps.taskID !== this.props.taskID) {
			this.setState({ taskID: this.props.taskID || 0 });
			if (this.props.visible === true) {
				this.fetchRiskList();
			}
		}
		if (prevProps.visible !== this.props.visible) {
			if (this.props.taskID !== 0 && this.props.visible) {
				this.fetchRiskList();
			}
		}*/
	}

	fetchRiskList() {
		const taskID = this.state.taskID;
		this.setState({ isLoadingData: true });
		fetch(`api/Risk/GetRisksForTask/`+taskID, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}
				this.setState({ riskList: data });
				this.setState({ isLoadingData: false });
			})
			.catch(error => {
				this.setState({ isLoadingData: false });
			})
	}

	fetchNewRisk() {
		this.setState({ isLoadingNew: true });
		this.setState({ isLoadingData: true });
		fetch(`api/Risk/AddRisk`, { method: 'POST', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}

				this.newRisk(data);

				this.setState({ isLoadingNew: false });
				this.setState({ isLoadingData: false });
			})
			.catch(error => {
				this.setState({ isLoadingNew: false });
				this.setState({ isLoadingData: false });
			})
	}

	fetchDeleteRisk(riskID) {
		this.setState({ isLoadingData: true });
		fetch(`api/Risk/RemoveRisk/` + riskID, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}

				const newRiskList = this.state.riskList.filter((risk) => risk.riskID !== data);
				this.setState({ riskList: newRiskList });
				this.setState({ isLoadingData: false });
			})
			.catch(error => {
				this.setState({ isLoadingData: false });
			})
	}

	fetchUpdateRisk(risk) {
		this.setState({ isLoadingData: true });
		fetch(`api/Risk/SaveRisk`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(risk) } )
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) {
					throw (data.error);
				}

				const index = this.state.riskList.findIndex(item => item.riskID === data.riskID);
				const newRiskList = update(this.state.riskList, {
					[index]: {
						$set: data
					}
				});
				this.setState({ riskList: newRiskList });
				
				this.setState({ isLoadingData: false });
			})
			.catch(error => {
				this.setState({ isLoadingData: false });
			})
	}

	newRisk(riskID) {
		const newRisk = { riskID: riskID, name: 'New risk', description: '', probability: 1, consequence: 1, selected: false };
		const newRiskList = update(this.state.riskList, { $push: [newRisk] });
		this.setState({ riskList: newRiskList });
		this.setState({ editingRows: [newRisk] });
	}

	textEditor(options) {
		return <InputText autoFocus={ true } type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} style={{ width: '100%' }} maxLength="50" />;
	}

	textFieldEditor(options) {
		return <InputTextarea value={options.value} onChange={(e) => options.editorCallback(e.target.value)} rows={1} style={{ width: '100%', height:"2.6rem" }} />;
	}

	probabilityEditor(options) {
		return (
			<Dropdown
				optionLabel="text"
				optionValue="id"
				value={options.value}
				options={this.probabilityScale}
				onChange={(e) => options.editorCallback(e.value)}
				placeholder="..."
				style={{ width: '100%'}}
			/>
		);
	}

	consequenceEditor(options) {
		return (
			<Dropdown
				optionLabel="text"
				optionValue="id"
				value={options.value}
				options={this.consequenceScale}
				onChange={(e) => options.editorCallback(e.value)}
				placeholder="..."
				style={{ width: '100%' }}
			/>
		);
	}

	handleDelete(id) {
		this.fetchDeleteRisk(id);
	}

	onAddNewRisk() {
		this.fetchNewRisk();
	}

	onRowEditComplete(e) {
		this.fetchUpdateRisk(e.newData);

		/*const newRiskList = update(this.state.riskList, {
			[e.index]: {
				$set: e.newData
			}
		});
		newRiskList[e.index].risk = newRiskList[e.index].consequence * newRiskList[e.index].probability;
		this.setState({ riskList: newRiskList });*/
	}

	onRowEditChange(e) {
		if (e.data.length <= 1) { 
			this.setState({ editingRows: e.data });
		}
	}

	riskTemplate(rowData, props) {
		const risk = rowData.consequence * rowData.probability;
		var severity = "success";
		var text = risk + " Low";
		var style = { width: "100%" };
		if (risk > 3 && risk < 8) {
			severity = "warning";
			style = { backgroundColor: "#FFEE00", width: "100%" };
			var text = risk + " Moderate";
		} else if (risk > 6 && risk < 15) {
			severity = "warning";
			var text = risk + " High";
		} else if( risk>14 ) {
			severity = "danger";
			var text = risk + " Extreme";
		}
		return (
			<Tag style={style} severity={severity} value={text}></Tag>
		);
	}

	probabilityTemplate(rowData, props) {
		var text = this.probabilityScale.find(item => item.id === rowData.probability).text ;
		return (
			<Tag style={{ width: "100%", border:"1px solid black", backgroundColor:"white", color:"black" }} value={text}></Tag>
		);
	}

	consequenceTemplate(rowData, props) {
		var text = this.consequenceScale.find(item => item.id === rowData.consequence).text;
		return (
			<Tag style={{ width: "100%", border: "1px solid black", backgroundColor: "white", color: "black" }} value={text}></Tag>
		);
	}

	handleDeleteClick(riskID) {
		confirmDialog({
			message: "This Risk could be connected to WorkTasks, are you sure you want to delete it anyway?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => this.handleDelete(riskID),
			reject: () => { }
		});
	}

	rowEditorTemplate(rowData, props) {
		const rowEditor = props.rowEditor;
		if (rowEditor.editing) {
			//return rowEditor.element; // default element
			return (
				<>
					<Button
						icon="pi pi-check"
						className="p-button-success p-button-rounded p-button-text"
						onClick={(e) =>
							rowEditor?.onSaveClick &&
							rowEditor?.onSaveClick(e)
						}
						tooltip={"Accept changes"}
						tooltipOptions={{ position: "top" }}
					/>
					<Button
						icon="pi pi-times"
						className="p-button-danger p-button-rounded p-button-text"
						onClick={(e) =>
							rowEditor?.onCancelClick &&
							rowEditor?.onCancelClick(e)
						}
						tooltip={"Cancel changes"}
						tooltipOptions={{ position: "left" }}
						severity="warning"
					/>
				</>
			);
		} 
		return (
			<div>
				<Button icon={<FontAwesomeIcon icon={faPencil} />} severity="secondary" aria-label="Bookmark" className="p-button-secondary p-button-rounded p-button-text"
					onClick={(e) =>
						props.rowEditor?.onInitClick &&
						props.rowEditor?.onInitClick(e)
					}
					disabled={this.state.editingRows.length > 0}
					tooltip={"Change row"}
					tooltipOptions={{ position: "top" }}
				/>
				<Button icon={<FontAwesomeIcon icon={faTrashCan} />} severity="secondary" aria-label="Bookmark" className="p-button-secondary p-button-rounded p-button-text"
					onClick={this.handleDeleteClick.bind(this, rowData.riskID)}
					disabled={this.state.editingRows.length > 0}
					tooltip={"Delete row"}
					tooltipOptions={{ position: "left" }}
				/>
			</div>
		);
		
	}

	render() {
		const rightTools = (
			<Button
				aria-label="New risk"
				className="p-button-sm"
				tooltip={"Add risk"}
				tooltipOptions={{ position: "bottom" }}
				onClick={(e) => this.onAddNewRisk()}
				disabled={this.state.editingRows.length > 0 || this.state.isLoadingData}
			>
				<FontAwesomeIcon icon={faPlusSquare} /> &nbsp;New
			</Button >);

		const footerContent = (<Button label="Close" onClick={() => this.closeDialog()} className="p-button-text" />);

		const toolbar = (
			<Toolbar className="mb-1" end={rightTools}></Toolbar>
		);

		var selectedRisks;
		if (this.state.riskList) {
			selectedRisks = this.state.riskList.filter(risk => risk.selected);
		}

		return (
			<div style={{ width: '100%', overflow: 'hidden', height: '100%' }}>
				<ConfirmDialog />
				<DataTable
					value={this.state.riskList}
					size="small"
					scrollable
					scrollHeight="flex"
					tableStyle={{ minWidth: '50rem', width: '100%', height: '100%' }}
					header={toolbar}
					disabled={this.state.isLoadingData}
					loading={this.state.isLoadingData}
					editMode="row"
					editingRows={this.state.editingRows}
					onRowEditChange={(e) => { this.onRowEditChange(e); }}
					onRowEditComplete={(e) => { this.onRowEditComplete(e) }}
				>
					<Column field="name" header="Name" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '20%', minWidth: '7rem' }} editor={(options) => this.textEditor(options)}></Column>
					<Column field="description" header="Description" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '75%', minWidth: '10rem' }} editor={(options) => this.textFieldEditor(options)}></Column>
					<Column field="probability" header="Probability" style={{ maxWidth: '7rem', minWidth: '7rem' }} editor={(options) => this.probabilityEditor(options)} body={this.probabilityTemplate.bind(this)}></Column>
					<Column field="consequence" header="Consequence" style={{ maxWidth: '7rem', minWidth: '7rem' }} editor={(options) => this.consequenceEditor(options)} body={this.consequenceTemplate.bind(this)}></Column>
					<Column header="Risk" style={{ maxWidth: '7rem', minWidth: '7rem' }} body={this.riskTemplate.bind(this)}></Column>
					<Column rowEditor style={{ maxWidth: '6rem', minWidth: '6rem' }} body={this.rowEditorTemplate.bind(this)}></Column>
				</DataTable>
			</div>
		)
	}
}

export default (Risk);