
export const faStatusEmpty = {
    prefix: 'fac',
    iconName: 'StatusEmpty',
    icon: [
        1024,
        1024,
        [],
        null,
        'M512.009 0c-282.77 0-512.009 229.233-512.009 512.027 0 282.77 229.245 512.015 512.015 512.015 282.782 0 512.027-229.239 512.027-512.015 0-282.794-229.245-512.027-512.033-512.027zM512.009 898.269c-213.321 0-386.254-172.927-386.254-386.254 0-213.321 172.933-386.254 386.254-386.254 213.339 0 386.266 172.933 386.266 386.254 0 213.327-172.927 386.254-386.266 386.254z'
    ]
}

export const faStatusQuarter = {
    prefix: 'fac',
    iconName: 'StatusQuarter',
    icon: [
        1024,
        1024,
        [],
        null,
        'M512.241 8.553c-278.167 0-503.688 225.521-503.688 503.688s225.521 503.688 503.688 503.688c278.167 0 503.688-225.521 503.688-503.688s-225.521-503.688-503.688-503.688zM512.241 892.265c-209.86 0-379.964-170.104-379.964-379.964s170.104-379.964 379.964-379.964v0 379.964h379.964c0.060 209.8-170.104 379.964-379.964 379.964z'
    ]
}

export const faStatusHalf = {
    prefix: 'fac',
    iconName: 'StatusQuarter',
    icon: [
        1024,
        1024,
        [],
        null,
        'M1019.021 509.516v0c0-281.626-227.885-509.516-509.504-509.516v0c-281.403 0-509.516 228.113-509.516 509.528 0 281.385 228.113 509.498 509.516 509.498v0.012c280.054 0 506.873-225.404 509.384-504.862 0.012-1.547 0.12-3.095 0.12-4.642v-0.018zM509.504 893.88c-212.273 0-384.364-172.085-384.364-384.364s172.091-384.37 384.364-384.37h0.012v768.733h-0.012z'
    ]
}

export const faStatusThreeQuarter = {
    prefix: 'fac',
    iconName: 'StatusQuarter',
    icon: [
        1024,
        1024,
        [],
        null,
        'M1019.027 509.516v0c0-281.626-227.885-509.516-509.498-509.516v0c-0.006 0-0.006 0-0.012 0-281.391 0-509.516 228.113-509.516 509.522 0 26.383 2.005 52.296 5.87 77.595 37.015 242.72 245.014 428.724 497.439 431.753 2.077 0.024 4.13 0.157 6.207 0.157h0.012c280.054 0 506.873-225.392 509.378-504.856 0.012-1.553 0.12-3.089 0.12-4.654 0 0 0 0 0 0zM509.51 125.141c0.006 0 0.006 0 0.012 0l-0.006 384.37h-384.37c0-212.279 172.085-384.37 384.364-384.37z'
    ]
}

export const faStatusFull = {
    prefix: 'fac',
    iconName: 'StatusFull', 
    icon: [
        1024,
        1024,
        [],
        null,
        'M1024 512.009c0 282.764-229.227 511.991-511.997 511.991-282.782 0-512.003-229.221-512.003-511.991 0-282.782 229.221-512.009 512.003-512.009 282.764 0 511.997 229.227 511.997 512.009z'
    ]
}

export const faStatusUndefined = {
    prefix: 'fac',
    iconName: 'StatusUndefined',
    icon: [
        1024,
        1024,
        [],
        null,
        'M768.264 913.678c0 36.012-29.193 65.205-65.205 65.205s-65.205-29.193-65.205-65.205 29.193-65.205 65.205-65.205 65.205 29.193 65.205 65.205zM510.933 892.413c-36.012 0-65.205 29.193-65.205 65.205s29.193 65.205 65.205 65.205 65.205-29.193 65.205-65.205-29.193-65.205-65.205-65.205zM316.759 848.473c-36.012 0-65.205 29.193-65.205 65.205s29.193 65.205 65.205 65.205 65.205-29.193 65.205-65.205-29.193-65.205-65.205-65.205zM580.915 685.381l-135.021-105.024v-280.024c0-27.366 22.178-49.544 49.544-49.544h33.032c27.36 0 49.544 22.178 49.544 49.544v215.414l84.017 65.346c21.596 16.795 25.49 47.92 8.689 69.528l-20.278 26.072c-16.801 21.602-47.926 25.49-69.528 8.689zM967.521 42.934l-101.916 101.922c-91.909-87.837-216.481-141.79-353.654-141.79-282.416 0-511.43 228.689-511.951 510.994 0 0.325 0.025 0.638 0.025 0.963h-0.025c0.006 121.69 42.45 233.46 113.345 321.303 18.463 22.865 52.733 24.582 73.507 3.802l23.258-23.264c17.77-17.77 19.72-46.147 4.096-65.83-51.415-64.788-82.098-146.782-82.098-236.004v-0.006h0.006c0-209.957 169.917-379.837 379.83-379.837 100.775 0 192.291 39.163 260.249 103.081l-104.779 104.773c-20.805 20.811-6.064 56.387 23.356 56.387h300.099c18.248 0 33.032-14.79 33.032-33.032v-300.099c0.006-29.426-35.564-44.167-56.381-23.362z'
    ]
}