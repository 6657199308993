import React from "react";
import { Navigate } from 'react-router-dom';

import { withRouter } from "./react/WithRouter";

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';

import update from 'immutability-helper';

import './TaskList.css';

import { faFlag, faCommentDots, faHourglassHalf as faHourglass, faTasks,  faUser, faEnvelope, faCalendarAlt, faFile, faBookReader, faGlasses, faClock as fasClock} from "@fortawesome/free-solid-svg-icons";
import { faFlag as farFlag, faCheckSquare, faComment, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UserContext from '../context/UserContext';

import { faStatusEmpty } from '../Icons/custom-icon-pack'
import { faStatusQuarter } from '../Icons/custom-icon-pack'
import { faStatusHalf } from '../Icons/custom-icon-pack'
import { faStatusThreeQuarter } from '../Icons/custom-icon-pack'
import { faStatusFull } from '../Icons/custom-icon-pack'
import { faStatusUndefined } from '../Icons/custom-icon-pack'

import { library } from '@fortawesome/fontawesome-svg-core'

import CloseTaskDialog from './CloseTaskDialog'

/*import fontawesome from '@fortawesome/fontawesome'
fontawesome.library.add(faStatusEmpty);
fontawesome.library.add(faStatusQuarter);
fontawesome.library.add(faStatusHalf);
fontawesome.library.add(faStatusThreeQuarter);
fontawesome.library.add(faStatusFull);
fontawesome.library.add(faStatusUndefined);*/

library.add(faStatusEmpty);
library.add(faStatusQuarter);
library.add(faStatusHalf);
library.add(faStatusThreeQuarter);
library.add(faStatusFull);
library.add(faStatusUndefined);

const MAX_COMMENT_CHARS = 255;

class TaskList extends React.Component {
    static contextType = UserContext;

    state = {
        modalMode: "",
        isModalLoading: false,
        selectedTaskID: 0,
        selectedTaskIndex: -1,
        selectedSubTasks: [],
        range: 7,
        isFlagged: false,
        charsLeft: MAX_COMMENT_CHARS,
        commentText: "",
        comments: [],
        validate: false,
        Tasks: [],   
        closingComment: false
    };

    constructor(props) {
        super(props);

        this.taskIcons = [{ icon:faUser }, { icon: faEnvelope }, { icon: faCalendarAlt }, { icon: faFile }, { icon: faBookReader }, { icon: faGlasses }, { icon: faHourglass }, { icon: fasClock }, { icon: faTasks}];
    }

    componentDidMount() {
        this.previousContext = this.context;
        this.fetchTaskList();
    }

    componentDidUpdate(prevProps) {
        if (this.previousContext.user.organisation !== this.context.user.organisation) {
            this.fetchTaskList();
        }

        this.previousContext = this.context;
    }

    statusIcon(iconNum) {
        switch (iconNum) {
            case 0:
                return faStatusUndefined;
            case 1:
                return faStatusEmpty;
            case 2:
                return faStatusQuarter;
            case 3:
                return faStatusHalf;
            case 4:
                return faStatusThreeQuarter;
            case 5:
                return faStatusFull;
            default:
                return faStatusUndefined;
        }
    } 

    fetchTaskList() {

        fetch(`api/WorkTask/MyTasks/`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState(prevState => ({ Tasks: data }));
            })
            .catch(error => {
                
            })
    }

    DetailsModal(index, ID) {
        if (this.state.modalMode === "") {
            this.setState({ modalMode: "details" });
            this.setState({ selectedTaskID: ID });
            this.setState({ selectedTaskIndex: index });
        }
    }

    CloseModal(index, ID) {
        this.setState({ modalMode: "close" });
        this.setState({ isModalLoading: false });
        this.setState({ selectedTaskID: ID });
        this.setState({ selectedTaskIndex: index });
        this.setState({ selectedSubTasks: this.state.Tasks[index].subTasks })
        this.setState({ charsLeft: MAX_COMMENT_CHARS });
        this.setState({ commentText: "" });
        this.setState({ validate: false });
        this.setState({ closingComment: this.state.Tasks[index].closingComment });
    }

    FlagModal(index, ID, isFlagged) {
        this.setState({ modalMode: "flag" });
        this.setState({ isModalLoading: false });
        this.setState({ selectedTaskID: ID });
        this.setState({ selectedTaskIndex: index });
        this.setState({ isFlagged: isFlagged });
        this.setState({ charsLeft: MAX_COMMENT_CHARS });
        this.setState({ commentText: "" });
        this.setState({ validate: false });
    }

    CommentModal(index, ID, comments) {
        this.setState({ modalMode: "comment" });
        this.setState({ isModalLoading: false });
        this.setState({ selectedTaskID: ID });
        this.setState({ selectedTaskIndex: index });
        this.setState({ charsLeft: MAX_COMMENT_CHARS });
        this.setState({ commentText: "" });
        this.setState({ validate: false });
        if (comments) {
            this.setState({ comments: comments });
        } else {
            this.setState({ comments: [] });
        }
    }

    AddComment(index) {
        if (this.state.commentText === "") {
            this.setState({ validate: true });
            this.setState({ isModalLoading: false });
            return;
        }

        const comment = { taskID: this.state.selectedTaskID, type: 1, comment: this.state.commentText };

        fetch(`api/WorkTask/Comments/`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(comment) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }

                const newTasks = update(this.state.Tasks, { [index]: { comments: { $set: data } } });
                this.setState({ Tasks: newTasks });

                this.setState({ validate: false });
                this.setState({ commentText: "" });
                this.setState({ isModalLoading: false });
            })
            .catch(error => {
                this.setState({ isModalLoading: false });
            })
    }

    CloseTask(taskID, commentText, closeType, subtasks) {
        this.setState({ isModalLoading: true });
        const closeTaskData = { taskID: taskID, closeType: closeType, comment: commentText, outOfRoleReason:"", subTasks: subtasks};

        fetch(`api/WorkTask/CloseMyTask/`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(closeTaskData) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ Tasks: data });

                this.setState({ selectedTaskIndex: -1 });
                this.setState({ modalMode: "" });
                this.setState({ isModalLoading: false });
            })
            .catch(error => {
                this.setState({ isModalLoading: false });
            })

    }

    setProgressStatus(taskID, progress) {
        fetch(`api/WorkTask/SetProgressStatus/` + taskID, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(progress) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ Tasks: data });
                this.setState({ modalMode: "" });
            })
            .catch(error => {
                this.setState({ isModalLoading: false });
            })
    }

    setSubTaskStatus(taskID, subTasks) {
        this.setState({ isModalLoading: true });
        fetch(`api/WorkTask/SetMySubTaskStatus/`+taskID, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(subTasks) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ Tasks: data });

                this.setState({ isModalLoading: false });

                this.setState({ modalMode: '' });
                this.setState({ selectedTaskIndex: -1 });
            })
            .catch(error => {
                this.setState({ isModalLoading: false });
            })
    }

    FlagTask(index) {
        if (this.state.commentText === "") {
            this.setState({ validate: true });
            this.setState({ isModalLoading: false });
            return;
        }

        var commentType = 2;
        if (this.state.isFlagged) {
            commentType = 3;
        }

        const comment = { taskID: this.state.selectedTaskID, type: commentType, comment: this.state.commentText };

        fetch(`api/WorkTask/ToggleFlag/`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(comment) })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    throw (data.error);
                }
                this.setState({ Tasks: data });

                this.setState({ modalMode: "" });
                this.setState({ isModalLoading: false });
            })
            .catch(error => {
                this.setState({ isModalLoading: false });
            })
    }

    handleCancelClose() {
        this.setState({ modalMode: '' });
        this.setState({ selectedTaskIndex: -1 });
    }

    handleClose(event) {
        if (event.closeType === 0) {
            this.setSubTaskStatus(event.taskID, event.subTasks);
        } else {          
            this.CloseTask(event.taskID, event.comment, event.closeType, event.subTasks);    
        }
    }

    popover(action, help) {
        return (
            <Popover>
                <Popover.Header as="h3">{action}</Popover.Header>
                <Popover.Body>
                    {help}
                </Popover.Body>
            </Popover>
        );
    }

    render() {
       
//<Navigate replace to='/PersonalGantt' />
        const closingPopover = this.popover("close task", "Close the task and check off subtasks if there are any. Tasks cannot be closed before they are started");
        const flagPopover = this.popover("Warning flag", "Toggles a flag for a warning, eg a possible delay. This requires a comment to explain why the flag has been set or unset. ");
        const progressPopover = this.popover("Set progress status", "User set progress status. Progress status cannot be set manually for tasks with subtasks, as it is derived from the subtask status.");
        const commentPopover = this.popover("Add Comments", "Comments can for example be added to inform other users in the same role or for internal audit.");

        const popoverDelay = { show: 1000, hide: 250 };

        var isStarted = this.state.selectedTaskIndex < 0 ? false : this.state.Tasks[this.state.selectedTaskIndex].isStarted;
        return (
            <div className='task-list'>
                
                <Modal show={this.state.modalMode === "saving"} style={{ zIndex: '4077' }} centered backdrop="static" size="sm">
                    <Modal.Header closeButton={false}><b>Saving status</b></Modal.Header>
                    <Modal.Body>
                        <center><Spinner animation="border"
                        as="span"
                        size="lg"
                        role="status"
                        aria-hidden="true" /></center>
                    </Modal.Body>
                </Modal>

                { this.state.selectedTaskIndex < 0 ? '' :
                    <Modal scrollable={true}  show={this.state.modalMode === "details"} style={{ zIndex: '4077' }} centered onHide={() => { this.setState({ modalMode: "" }) }}>
                        <Modal.Header style={this.state.selectedTaskIndex < 0 ? null : { background: this.state.Tasks[this.state.selectedTaskIndex].statusColor, color: 'white' }} closeButton><b>Details for {this.state.Tasks[this.state.selectedTaskIndex].name}</b></Modal.Header>
                        <Modal.Body>
                            <Card bg='light' className='mb-3'>
                                <Card.Header className='dependencyHeader'><b>Info</b></Card.Header>
                                <Card.Body>
                                    <Table size='sm'>
                                        <tbody>
                                            <tr>
                                                <td><b>WorkFlow:</b></td>
                                                <td>{this.state.Tasks[this.state.selectedTaskIndex].flowName}</td>
                                            </tr>
                                            {isStarted ? null :<tr>
                                                <td><b>Scheduled:</b></td>
                                                <td>{this.state.Tasks[this.state.selectedTaskIndex].scheduledDate.substr(0, 10)}</td>
                                            </tr>}
                                            <tr>
                                                <td><b>Deadline:</b></td>
                                                <td>{this.state.Tasks[this.state.selectedTaskIndex].deadline.substr(0, 10)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Status:</b></td>
                                                <td>{this.state.Tasks[this.state.selectedTaskIndex].status}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Responsible:</b></td>
                                                <td>{this.state.Tasks[this.state.selectedTaskIndex].responsible}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Description:</b></td>
                                                <td>{this.state.Tasks[this.state.selectedTaskIndex].description}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>

                            <Card bg='light' className='mb-3'>
                                <Card.Header className='dependencyHeader'><b>Dependencies</b></Card.Header>
                                <Card.Body>

                                    <Table hover size='sm'>
                                    {this.state.Tasks[this.state.selectedTaskIndex].previousTasks.length === 0 ? null:
                                        <thead>
                                            <tr>
                                                <th>Previous task</th>
                                                <th>Status</th>
                                                <th>Responsible</th>
                                            </tr>
                                            </thead>
                                        }
                                        <tbody>
                                            {this.state.Tasks[this.state.selectedTaskIndex].previousTasks.map(value => {
                                                return (
                                                    <tr key={'previousTask' + value.taskID}>
                                                        <td>{value.name}</td>
                                                        <td style={{ 'backgroundColor': value.statusColor }}>{value.status}</td>
                                                        <td>{value.responsible}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>


                                   
                                    {this.state.Tasks[this.state.selectedTaskIndex].nextTasks.length === 0 ? null:
                                        <thead>
                                            <tr>
                                                <th>Next task</th>
                                                <th>Status</th>
                                                <th>Responsible</th>
                                            </tr>
                                        </thead>
                                    }
                                        <tbody>
                                            {this.state.Tasks[this.state.selectedTaskIndex].nextTasks.map(value => {
                                                return (
                                                    <tr key={'nextTask' + value.taskID} >
                                                        <td>{value.name}</td>
                                                        <td style={{ 'backgroundColor': value.statusColor }}>{value.status}</td>
                                                        <td>{value.responsible}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Modal.Body>
                    </Modal>
                }

                {this.state.selectedTaskIndex < 0 ? '' :               
                    <CloseTaskDialog task={this.state.Tasks[this.state.selectedTaskIndex]} show={this.state.modalMode === "close"} onCancel={this.handleCancelClose.bind(this)} onClose={this.handleClose.bind(this)} isLoading={this.state.isModalLoading} />
                }

                { this.state.selectedTaskIndex < 0 ? '' :
                    <Modal show={this.state.modalMode === "flag"} style={{ zIndex: '4077' }} centered onHide={() => { if (!this.state.isModalLoading) this.setState({ modalMode: "" }) }}>
                        <Modal.Header closeButton><b>{this.state.isFlagged ? "Remove flag for" : "Flag risk for"} {this.state.Tasks[this.state.selectedTaskIndex].name} </b></Modal.Header>
                        <Modal.Body>
                            <Form noValidate validated={this.state.validate}>
                                <Form.Group>
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        maxLength={MAX_COMMENT_CHARS}
                                        placeholder="Enter Comment"
                                        disabled={this.state.isModalLoading}
                                        value={this.state.commentText}
                                        onChange={e => this.setState({ commentText: e.target.value })}
                                        required
                                    />
                                    <Form.Text className="text-muted">
                                        Characters Left: {MAX_COMMENT_CHARS - this.state.commentText.length}
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => { this.setState({ isModalLoading: true }); this.FlagTask(this.state.selectedTaskIndex); }} disabled={this.state.isModalLoading}>
                                {this.state.isModalLoading ? <Spinner animation="border"
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true" /> : ''}
                                {' '}{this.state.isFlagged ? "Remove flag" : "Set flag"}{' '}
                            </Button>{' '}
                            <Button variant="secondary" onClick={() => { this.setState({ modalMode: "" }) }} disabled={this.state.isModalLoading}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                }

                { this.state.selectedTaskIndex < 0 ? '' :
                    <Modal scrollable={true} show={this.state.modalMode === "comment"} style={{ zIndex: '4077' }} size="lg" centered onHide={() => { if (!this.state.isModalLoading) this.setState({ modalMode: "" }) }}>
                        <Modal.Header closeButton><b>Add comment to {this.state.Tasks[this.state.selectedTaskIndex].name}</b></Modal.Header>
                        <Modal.Body>
                                {this.state.selectedTaskIndex < 0 ? "" : this.state.Tasks[this.state.selectedTaskIndex].comments.map(value => {

                                    return (
                                        <Card bg='light' className='mb-3' key={'cmt' + value.commentID}>
                                            <Card.Header>{value.poster} - {(new Date(value.time)).toLocaleString('sv-SE')}</Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    {value.comment}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='commentFooter'>
                                <Form noValidate validated={this.state.validate}>
                                    <Form.Group>
                                        <Form.Control
                                            as="textarea"
                                            rows="2"
                                            maxLength={MAX_COMMENT_CHARS}
                                            placeholder="Enter Comment"
                                            disabled={this.state.isModalLoading}
                                            value={this.state.commentText}
                                            onChange={e => this.setState({ commentText: e.target.value })}
                                            required
                                        />
                                        <Form.Text className="text-muted">
                                            Characters Left: {MAX_COMMENT_CHARS - this.state.commentText.length}
                                        </Form.Text>
                                    </Form.Group>
                                </Form>
                                 <div style={{ float: 'right' }}>
                                    <Button variant="primary" onClick={() => { this.setState({ isModalLoading: true }); this.AddComment(this.state.selectedTaskIndex); }} disabled={this.state.isModalLoading}>
                                        {this.state.isModalLoading ? <Spinner animation="border"
                                            as="span"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true" /> : ''}
                                        {' '}Add comment{' '}
                                    </Button>{' '}
                                    <Button variant="secondary" onClick={() => { this.setState({ modalMode: "" }) }} disabled={this.state.isModalLoading}>Close</Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                }

                <Card className='rounded text-nowrap' key={'taskFilter'} >
                    <Card.Header><b>My WorkTasks  </b> {' '}
                        <ButtonGroup>
                            <Button variant="outline-primary" size='sm' disabled={true}>Card list</Button>
                            <Button onClick={() => this.props.navigate("/PersonalGantt")} variant="primary" color="primary" size='sm'>Gantt</Button>
                        </ButtonGroup>
                        <div style={{ float: 'right' }}>
                             Show pending tasks starting within {this.state.range} days<br /> 
                            <Form.Range max='30' value={this.state.range} onChange={e => this.setState({ range: e.target.value })} style={{ width: "200px" }} />
                        </div> 
                    </Card.Header>
                </Card>
                <div className='card-container'>
                    <CardGroup className='card-collection'>

                        {this.state.Tasks.map((value, index) => {

                            const limitDate = new Date(); 
                            limitDate.setDate((new Date().getDate()) + parseInt(this.state.range));
                            limitDate.setHours(0, 0, 0, 0);

                            const deadline = new Date(value.deadline);
                            deadline.setHours(0, 0, 0, 0);

                            const startDate = new Date(value.scheduledDate);
                            startDate.setHours(0, 0, 0, 0);

                            const today = new Date(); 
                            today.setHours(0, 0, 0, 0);

                            const daysToDeadline = Math.round((deadline - today) / (1000 * 60 * 60 * 24));
                            const dayToStart = Math.round((startDate - today) / (1000 * 60 * 60 * 24));

                            var timeText = "";

                            if (!value.isStarted) {
                                if (dayToStart > 0) {
                                    timeText += "Scheduled in " + dayToStart + " days. "
                                } else if (dayToStart < 0) {
                                    timeText += "Scheduled " + Math.abs(dayToStart) + " days ago. "
                                } else {
                                    timeText += "Should start today. "
                                }
                            }

                            if (daysToDeadline > 0) {
                                timeText += "Deadline in " + daysToDeadline + " days."
                            } else if (daysToDeadline < 0) {
                                timeText += "Deadline was " + Math.abs(daysToDeadline) + " days ago."
                            } else {
                                timeText += "Deadline is today."
                            }
                        
                        
                            if (value.isStarted || startDate <= limitDate)
                                return (
                                    <Card className='shadow rounded task-card' key={'tsk' + value.taskID} >
                                        <Card.Header style={{ background: value.statusColor }} />
                                        <Card.Body onDoubleClick={() => { this.DetailsModal(index, value.taskID) }}>
                                            <Card.Title>
                                                <div className='taskTitle'>{value.name}</div>
                                                <div style={{ float: 'right' }}>
                                                    <FontAwesomeIcon className='taskIcons' size="lg" icon={this.taskIcons[value.icon].icon} />
                                                </div>
                                            </Card.Title>
                                            <Card.Subtitle>{value.flowName}</Card.Subtitle> 
                                            <Card.Text>
                                                <small className="text-muted">{timeText}</small>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer>
                                            <OverlayTrigger placement="auto" overlay={commentPopover} delay={popoverDelay} trigger={["hover", "hover"]} placement="auto">
                                                <Button onClick={(e) => { this.CommentModal(index, value.taskID, value.comments); e.stopPropagation(); }} size="sm">
                                                    {value.comments && value.comments.length > 0 ? <FontAwesomeIcon icon={faCommentDots} /> : <FontAwesomeIcon icon={faComment} />}
                                                </Button>
                                            </OverlayTrigger>{' '} 

                                            <OverlayTrigger placement="auto" overlay={flagPopover} delay={popoverDelay} trigger={["hover", "hover"]} placement="auto">
                                                <Button onClick={(e) => { this.FlagModal(index, value.taskID, value.isFlagged); e.stopPropagation(); }} size="sm">
                                                    {value.isFlagged ? <FontAwesomeIcon icon={faFlag} style={{ color: '#F29D49' }} /> : <FontAwesomeIcon icon={farFlag} />}
                                                </Button>
                                            </OverlayTrigger>{' '}
      
                                       
                                            <Dropdown as={ButtonGroup} size='sm'>
                                                <OverlayTrigger placement="auto" overlay={progressPopover} delay={popoverDelay} trigger={["hover", "hover"]} placement="auto">
                                                    <Dropdown.Toggle variant="primary" className="dropdown-toggle" disabled={value.subTasks.length > 0}>
                                                        <FontAwesomeIcon icon={this.statusIcon(value.progressStatus)} />                                            
                                                    </Dropdown.Toggle>

                                            </OverlayTrigger>
                                                <Dropdown.Menu style={{ minWidth: "0px" }}>
                                                    <Dropdown.Item onClick={(e) => { this.setState({ modalMode: "saving" }); this.setProgressStatus(value.taskID, 0); }}><FontAwesomeIcon icon={faStatusUndefined} /> n/d</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={(e) => { this.setState({ modalMode: "saving" }); this.setProgressStatus(value.taskID, 1); }}><FontAwesomeIcon icon={faStatusEmpty} /> 0%</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { this.setState({ modalMode: "saving" }); this.setProgressStatus(value.taskID, 2); }}><FontAwesomeIcon icon={faStatusQuarter} /> 25%</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { this.setState({ modalMode: "saving" }); this.setProgressStatus(value.taskID, 3); }}><FontAwesomeIcon icon={faStatusHalf} /> 50%</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { this.setState({ modalMode: "saving" }); this.setProgressStatus(value.taskID, 4); }}><FontAwesomeIcon icon={faStatusThreeQuarter} /> 75%</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => { this.setState({ modalMode: "saving" }); this.setProgressStatus(value.taskID, 5); }}><FontAwesomeIcon icon={faStatusFull} /> 100%</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                   
                                            <OverlayTrigger placement="auto" overlay={closingPopover} delay={popoverDelay} trigger={["hover", "hover"]} placement="auto">
                                                <Button onClick={(e) => { this.CloseModal(index, value.taskID); e.stopPropagation(); }} size='sm' className='float-end'>{value.autoClose ? <FontAwesomeIcon icon={faHourglass} /> : value.subTasks.length > 0 ? <FontAwesomeIcon icon={faTasks} /> : <FontAwesomeIcon icon={faCheckSquare} />}</Button>
                                            </OverlayTrigger>
                                        </Card.Footer>
                                    </Card>
                                );
                            else
                                return"";
                        })}
               
                        </CardGroup >
                    </div>
                </div>
            );
    }
}
                              

export default withRouter(TaskList);